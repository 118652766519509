import { css } from '@emotion/react';

import * as React from 'react';

import { compose } from 'recompose';
import connectToStores from 'vendor/cnpm/fluxible.v0-4/addons/connectToStores';

import Retracked from 'js/app/retracked';
import { isAuthenticatedUser } from 'js/lib/user';

import { Grid } from '@coursera/cds-core';

import withCCPA from 'bundles/marketing-consent/components/withCCPA';
import withGDPR from 'bundles/marketing-consent/components/withGDPR';
import PageFooter from 'bundles/page-footer/components/PageFooter';
import PageHeader from 'bundles/page-header/components/PageHeader';
import type { Stores } from 'bundles/unified-career-academy/appEnv';
import CoachChatPathways from 'bundles/unified-common/components/CoachChatPathways';
import { CoachStateProvider, useCoachState } from 'bundles/unified-common/providers/CoachStateProvider';
import UnifiedAppCheckProvider from 'bundles/unified-common/providers/UnifiedAppCheckProvider';
import PageNavigation from 'bundles/unified-home-common/components/consumer/PageNavigation';

type GridProps = React.ComponentProps<typeof Grid>;

type Props = {
  requestCountryCode?: string | null;
  csrfToken?: string | null;
};

const UnifiedCareerAcademyHeaderFooterWrapper: React.FC<Props> = ({ children }) => {
  const pageNavigation = isAuthenticatedUser() ? <PageNavigation /> : null;
  const headerRef = React.useRef<HTMLSpanElement>(null); // Declare headerRef variable
  const footerRef = React.useRef<HTMLDivElement>(null);
  const { coachOpen } = useCoachState();

  // the grid will need to adjust and respond appropriately to the coach being open to give
  // space for the rest of the page content.
  const defaultGridProps: GridProps = { xs: 12 };

  const layoutGridItemCoachOpenProps: (typeof Grid)['defaultProps'] = coachOpen
    ? {
        xs: 12,
        sm: 7,
        md: 8,
        lg: 9,
        xl: 10,
        css: css`
          /* Coach chat takes up 400px width */
          max-width: calc(100% - 400px);
        `,
      }
    : {};

  return (
    <UnifiedAppCheckProvider>
      <PageHeader
        showLanguagesDropdown
        subNavigationLinks={pageNavigation}
        hideMetaNav={isAuthenticatedUser()}
        elRef={headerRef}
      />
      <Grid container>
        <Grid item {...layoutGridItemCoachOpenProps} {...defaultGridProps}>
          <main>{children}</main>
        </Grid>
        {coachOpen && (
          <Grid item>
            <CoachChatPathways headerRef={headerRef} footerRef={footerRef} />
          </Grid>
        )}
      </Grid>
      <PageFooter elRef={footerRef} />
    </UnifiedAppCheckProvider>
  );
};

const UnifiedCareerAcademyApp: React.FC<Props> = ({ children }) => {
  return (
    <div className="rc-UnifiedCareerAcademyApp">
      <CoachStateProvider>
        <UnifiedCareerAcademyHeaderFooterWrapper>{children}</UnifiedCareerAcademyHeaderFooterWrapper>
      </CoachStateProvider>
    </div>
  );
};

export default compose(
  Retracked.createTrackedContainer(() => ({
    namespace: {
      app: 'unified_career_academy',
      page: 'consumer_career_academy',
    },
  })),
  connectToStores<Props, {}, Stores>(['ApplicationStore'], ({ ApplicationStore }, props) => ({
    ...props,
    csrfToken: ApplicationStore.getState().csrfToken,
    requestCountryCode: ApplicationStore.getState().requestCountryCode,
  })),
  withGDPR,
  withCCPA
)(UnifiedCareerAcademyApp);
