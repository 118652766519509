/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect } from 'react';

import { announce, clearAnnouncer } from '@react-aria/live-announcer';

import { Typography2 } from '@coursera/cds-core';

import { CoachIconButton } from 'bundles/ai-coach-platform/components/building-blocks';
import { ChevronLeftIcon } from 'bundles/ai-coach-platform/components/icons/mui';

import _t from 'i18n!nls/ai-coach-platform';

const styles = {
  root: css`
    width: 100%;
    height: 100%;
    position: relative;

    .settings-page-header {
      display: flex;
      z-index: 1;
      align-items: center;
      height: 56px;
      border-bottom: 1px solid var(--cds-color-grey-100);

      h2 {
        margin-left: 12px;
      }
    }
  `,
  content: css`
    padding: var(--cds-spacing-200);
    height: calc(100% - 130px);
    overflow: auto;
  `,
  footer: css`
    height: 48px;
    position: sticky;
    bottom: 24px;
    padding: var(--cds-spacing-200);
  `,
};

type Props = {
  title: string;
  onBack: () => void;
  children: React.ReactNode;
  footer?: React.ReactNode;
};

const SettingsPage = (props: Props): React.ReactElement => {
  const { onBack, title, children, footer } = props;

  useEffect(() => {
    // announces current page title on mount
    clearAnnouncer('assertive');
    announce(title, 'assertive');
  }, [title]);

  return (
    <div className="coach-settings-page-container" css={styles.root}>
      <div className="settings-page-header">
        <CoachIconButton
          onClick={onBack}
          icon={<ChevronLeftIcon size={24} />}
          tooltip={_t('Go back')}
          aria-label={_t('Back to Coach settings')}
        />

        <Typography2 variant="subtitleMedium" component="h2">
          {title}
        </Typography2>
      </div>

      <div css={styles.content}>{children}</div>

      <div css={styles.footer}>{footer}</div>
    </div>
  );
};

export default SettingsPage;
