import React from 'react';

import { RadioGroup as MuiRadioGroup } from '@material-ui/core';

import clsx from 'clsx';

import { useLocalizedStringFormatter, useId } from '@coursera/cds-common';

import type { BaseFormControlProps } from '@core/forms/FormControl';
import { FormControl, formControlClasses } from '@core/forms/FormControl';
import type {
  OptionalInputLabelProps,
  RequireIndicatorProps,
} from '@core/forms/FormLabel';
import { FormLabel } from '@core/forms/FormLabel';
import { FormSupportText } from '@core/forms/FormSupportText';
import { FormValidationLabel } from '@core/forms/FormValidationLabel';
import getFormGroupCss, { classes } from '@core/forms/getFormGroupCss';
import i18nMessages from '@core/forms/i18n';
import RadioCard from '@core/RadioCard';
import { useValidationAriaLabel } from '@core/utils';
import { ariaLabelledByForMultipleLabels } from '@core/utils/a11y';
import VisuallyHidden from '@core/VisuallyHidden';

export type Props = {
  /**
   * ⚠️ Either `Radio` or `RadioCard` elements **must** be direct descendants.
   */
  children: React.ReactNode;
  /**
   * The name used to reference the value of the control.
   */
  name?: string;
  /**
   * Value of the selected radio. Use when the component is controlled.
   */
  value?: string;
  /**
   * Validation label for the radio group.
   */
  validationLabel?: string;
  /**
   * The default input element value. Use when the component is not controlled.
   */
  defaultValue?: string;
  /**
   * Callback fired when a radio is selected.
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Sets in how many columns to distribute the radios.
   * Each number supports multiple breakpoints to guarantee full responsiveness on a smaller screens.
   *
   * @default 1
   */
  columns?: 1 | 2 | 3;
} & OptionalInputLabelProps &
  RequireIndicatorProps &
  Omit<BaseFormControlProps, 'disabled' | 'fullWidth'>;

/**
 * RadioGroup is a wrapper component used to group Radio components
 * that provides an easier API, and proper keyboard accessibility to the group
 *
 * See [Props](__storybookUrl__/components-inputs-radio--default#props)
 */
const RadioGroup = (props: Props, ref: React.Ref<HTMLDivElement>) => {
  const {
    id,
    className,
    label,
    children,
    name,
    value,
    defaultValue,
    supportText,
    validationStatus,
    validationLabel,
    onChange,
    optional,
    columns,
    renderLabel,
    'aria-labelledby': ariaLabelledBy,
    'aria-label': ariaLabel,
    necessityIndicator,
    ...rest
  } = props;

  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const ariaValidationLabel = useValidationAriaLabel(
    validationLabel,
    validationStatus
  );

  const hasCardsInTheGroup = React.Children.toArray(children).some((child) => {
    return React.isValidElement(child) && child.type === RadioCard;
  });

  const baseId = useId();
  const formLabelId = `${baseId}-formLabel`;
  const supportTextId = `${baseId}-supportText`;
  const validationLabelId = `${baseId}-validationLabel`;

  const hideLabel = !label && !renderLabel;

  /* Append the support and validation text to the aria-label.
   * This ensures the text is still announced even if the label is omitted and the FormLabel component doesn't render.
   */
  const ariaLabelExtended =
    ariaLabel &&
    [
      ariaLabel,
      supportText,
      validationStatus && validationLabel ? ariaValidationLabel : '',
      optional ? '' : stringFormatter.format('required'),
    ]
      .filter((str) => str)
      .join(', ');

  const ariaLabelledbyExtended = label
    ? formLabelId //If the label is rendered, it already contains the support and validation text
    : ariaLabelledByForMultipleLabels(
        ariaLabelledBy,
        supportTextId,
        validationLabelId
      );

  return (
    <FormControl
      className={className}
      css={getFormGroupCss}
      id={id}
      optional={optional}
      supportText={supportText}
      validationStatus={validationStatus}
      {...rest}
    >
      {!hideLabel && (
        <FormLabel
          announceRequiredIndicator
          className={formControlClasses.formLabel}
          component="div"
          id={formLabelId}
          necessityIndicator={
            !necessityIndicator && optional ? 'text' : necessityIndicator
          }
          renderLabel={renderLabel}
        >
          {label}

          {supportText && <VisuallyHidden>{supportText}</VisuallyHidden>}

          {validationStatus && validationLabel && (
            <VisuallyHidden data-testid="validation-label-aria">
              {ariaValidationLabel}
            </VisuallyHidden>
          )}
        </FormLabel>
      )}

      {supportText && (
        <FormSupportText
          aria-hidden
          className={formControlClasses.formSupportText}
          id={supportTextId}
        >
          {supportText}
        </FormSupportText>
      )}
      {validationStatus && validationLabel && (
        <FormValidationLabel
          aria-hidden
          className={formControlClasses.formValidationLabel}
          data-testid="validation-label"
          id={validationLabelId}
          label={validationLabel}
        />
      )}
      <MuiRadioGroup
        ref={ref}
        aria-label={ariaLabelExtended}
        aria-labelledby={
          hideLabel && !ariaLabelledBy
            ? undefined // Don't add an empty aria-label if, the label is empty, and aria-labelledby is not provided
            : ariaLabelledbyExtended
        }
        className={clsx(classes.groupWrapper, {
          [classes.groupWrapperTwoColumns]: columns === 2,
          [classes.groupWrapperThreeColumns]: columns === 3,
          [classes.hasCardsInTheGroup]: hasCardsInTheGroup,
        })}
        defaultValue={defaultValue}
        name={name}
        value={value}
        onChange={onChange}
      >
        {children}
      </MuiRadioGroup>
    </FormControl>
  );
};

export default React.forwardRef(RadioGroup);
