import * as React from 'react';
import { useCallback } from 'react';

import { MenuList } from '@coursera/cds-core';

import { AI_TOOL, type AI_TOOLS } from 'bundles/cml/shared/constants';

import _t from 'i18n!nls/cml';

type Props = {
  onChange: (change: { aiTool: AI_TOOLS; prompt?: string }) => void;
};

const ImproveClarityMenuItem: React.FC<Props> = ({ onChange }) => {
  const handleClick = useCallback(() => {
    onChange({ aiTool: AI_TOOL.REPHRASE });
  }, [onChange]);

  return <MenuList.Item onClick={handleClick}>{_t('Improve clarity')}</MenuList.Item>;
};

export default ImproveClarityMenuItem;
