import { times } from 'lodash';
import { Text as SlateText } from 'slate';
import type { NodeEntry } from 'slate';

import { BLOCK_TYPES, MARKS } from 'bundles/cml/shared/constants';
import type { MARK_VALUES } from 'bundles/cml/shared/constants';
import type {
  BlockElement,
  CodeElement,
  HeadingElement,
  InlineElement,
  LinkElement,
  ListElement,
  ListItemElement,
  MathBlockElement,
  MathInlineElement,
  TableCellElement,
  TableElement,
  TableRowElement,
  Text,
  TextElement,
} from 'bundles/cml/shared/types/elementTypes';

const MARK_KEYS = Object.values(MARKS) as MARK_VALUES[];

type TextFilter = (text: NodeEntry<Text>) => string;

const getTextContent = ([node, path]: NodeEntry<Text>, getText: TextFilter) => {
  let text = getText([node, path]);
  if (!text) {
    return '';
  }

  MARK_KEYS.forEach((mark) => {
    if (node[mark] === true) {
      switch (mark) {
        case 'bold':
          text = `**${text}**`;
          break;

        case 'italic':
          text = `*${text}*`;
          break;

        case 'subscript':
          text = `~${text}~`;
          break;

        case 'superscript':
          text = `^${text}^`;
          break;

        case 'monospace':
          text = `\`${text}\``;
          break;

        case 'underline':
        default:
          break;
      }
    }
  });

  return text;
};

const getEncodedPath = (pathname: string) => {
  const parts = pathname.split('/');
  const encodedPathname = parts
    .map((path) =>
      encodeURIComponent(path)
        // encodes [, ], (, and ) chars in the pathname,
        // which are reserved for markdown links and breaks
        // our formatting
        .replace(/[()[\]]/g, (c) => `%${c.charCodeAt(0).toString(16)}`)
    )
    .filter((path) => path !== '')
    .join('/');

  return encodedPathname === '' ? '' : `/${encodedPathname}`;
};

const encodeHref = (href: string) => {
  try {
    const url = new URL(href);
    const pathname = getEncodedPath(url.pathname);
    return `${url.origin}${pathname}${url.search}${url.hash}`;
  } catch {
    return href;
  }
};

// returns cml text string for a node
const getInlineText = ([node, path]: NodeEntry<BlockElement | InlineElement | Text>, getText: TextFilter) => {
  if (SlateText.isText(node)) {
    const textElement = node as Text;
    return getTextContent([textElement, path], getText);
  }

  const textNodes: Array<string> = [];

  (node as { children: (InlineElement | Text)[] }).children.forEach(
    (childNode: InlineElement | Text, index: number) => {
      if (SlateText.isText(childNode)) {
        const textElement = childNode as Text;
        const textContent = getTextContent([textElement, [...path, index]], getText);

        textNodes.push(textContent);
        return;
      }

      const inlineElement = childNode as InlineElement;
      if (inlineElement.type === BLOCK_TYPES.LINK) {
        const linkElement = inlineElement as LinkElement;
        const href = encodeHref(linkElement.href);

        const text = linkElement.children
          .map((child, textIndex) => getInlineText([child as Text, [...path, index, textIndex]], getText))
          .join('');

        if (text) {
          textNodes.push(`[${text}](${href})`);
        }
        return;
      }

      if (inlineElement.type === BLOCK_TYPES.MATH_INLINE) {
        const mathElement = inlineElement as MathInlineElement;
        textNodes.push(`${mathElement.formula}`);
      }
    }
  );

  return textNodes
    .map((value) => value.replace(/^( +)/, ' ').replace(/( +)$/, ' '))
    .filter((value) => !!value)
    .join('');
};

export const slateToMarkdown = (
  entry: NodeEntry<BlockElement | InlineElement | Text>,
  getText: TextFilter = ([{ text }]) => text
): string[] => {
  const [node, path] = entry;
  if (SlateText.isText(node)) {
    const text = getInlineText(entry, getText);
    return text ? [text] : [];
  }

  switch (node.type) {
    case BLOCK_TYPES.HEADING: {
      const text = getInlineText(entry, getText);
      if (!text) {
        return [];
      }

      const { level } = node as HeadingElement;
      const headingMarkdown = ''.padEnd(parseInt(level, 10), '#');
      return [`${headingMarkdown} ${text}`];
    }

    case BLOCK_TYPES.BULLET_LIST:
    case BLOCK_TYPES.NUMBER_LIST: {
      const isOrdered = node.type === BLOCK_TYPES.NUMBER_LIST;
      let itemCount = 0;
      const listNodes = node.children
        .map((child: ListItemElement, index: number) => {
          const listItem = slateToMarkdown([child, [...path, index]], getText).join('');
          if (!listItem) {
            return '';
          }

          itemCount += 1;
          return isOrdered ? `${itemCount}. ${listItem}` : `- ${listItem}`;
        })
        .filter((item) => !!item);

      return listNodes;
    }

    case BLOCK_TYPES.LIST_ITEM:
      return node.children.map((child, index) =>
        slateToMarkdown([child as TextElement, [...path, index]], getText).join('')
      );

    case BLOCK_TYPES.IMAGE:
    case BLOCK_TYPES.ASSET:
    case BLOCK_TYPES.WIDGET: {
      return [];
    }

    case BLOCK_TYPES.TABLE: {
      const table = node as TableElement;
      const hasHeader = !table.headless;

      const tableNodes: string[] = [];
      table.children.forEach((childNode, rowIndex) => {
        const tableRowElement = childNode as TableRowElement;
        const columns = tableRowElement.children.length;

        const cells = tableRowElement.children.map((cell: TableCellElement, cellIndex: number) =>
          slateToMarkdown([cell, [...path, rowIndex, cellIndex]], getText).join('')
        );

        tableNodes.push(`|${cells.join('|')}|`);
        if (hasHeader && rowIndex === 0) {
          tableNodes.push(
            `|${times(columns)
              .map(() => '---')
              .join('|')}|`
          );
        }
      });

      return tableNodes;
    }

    case BLOCK_TYPES.TABLE_CELL: {
      const tableCell = node as TableCellElement;
      const cell = tableCell.children
        .map((child: TextElement | ListElement | MathBlockElement, index) =>
          slateToMarkdown([child, [...path, index]], getText)
        )
        .join('');
      return cell ? [cell] : [];
    }

    case BLOCK_TYPES.CODE: {
      const { language, codeText } = node as CodeElement;
      return [`\`\`\`${language}\n${codeText.trimEnd()}\n\`\`\``];
    }

    case BLOCK_TYPES.MATH_BLOCK: {
      const { formula } = node as MathBlockElement;
      return [formula];
    }

    case BLOCK_TYPES.AI_ELEMENT: {
      return node.children.flatMap((child, index) =>
        slateToMarkdown([child as BlockElement, [...path, index]], getText)
      );
    }

    case BLOCK_TYPES.TEXT:
    default: {
      const text = getInlineText(entry, getText);
      return [text];
    }
  }
};
