import { times } from 'lodash';
import type { Node, NodeEntry } from 'slate';
import { Editor, Text, Transforms } from 'slate';

import { createTableRow } from 'bundles/cml/editor/components/elements/table/tableUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';
import { Tools } from 'bundles/cml/shared/utils/customTools';

const TABLE_HEADING_MARKDOWN_REGEX = /^\| *-+ *\|(?: *-+ *\|)*$/i;
const TABLE_PARSE_REGEX = /^\|(.+)\|$/i;
const TABLE_MULTILINE_MARKDOWN_REGEX = /(^\| *.+ *\|(?: *.+ *\|)*$\n?)+/im;

const hasHeadingRow = (text?: string) => {
  return !!text?.match(TABLE_HEADING_MARKDOWN_REGEX);
};

const hasEqualNumberOfCells = (rows: string[][]) => {
  const numCells = rows[0].length;
  return rows.every((row) => row.length === numCells);
};

const parseTableRows = (lines: string[]) => {
  const rows = lines.map((line) => {
    const row = line.match(TABLE_PARSE_REGEX)?.[1] ?? '';
    return row.split('|').map((value) => value.trim());
  });

  const headingRow = rows[0];
  const tableRows = hasHeadingRow(lines[1]) ? rows.slice(2) : rows.slice(1);

  if (!tableRows.length) {
    return { headingRow, tableRows: [times(headingRow.length, () => '')] };
  }

  return { headingRow, tableRows };
};

export const normalizeTableMarkdown = (editor: Editor, tools: Set<ToolsKeys>, [node, path]: NodeEntry<Node>) => {
  if (!Text.isText(node) || !tools.has(Tools.TABLE)) {
    return false;
  }

  const { text } = node;
  const matches = text.match(TABLE_MULTILINE_MARKDOWN_REGEX);
  if (!matches) {
    return false;
  }

  const startIndex = matches.index ?? 0;
  const tableText = text.slice(startIndex, startIndex + matches[0].length);
  const lines = tableText.trimEnd().split('\n');

  const headless = !hasHeadingRow(lines[1]);

  const { headingRow, tableRows } = parseTableRows(lines);
  if (!hasEqualNumberOfCells([headingRow, ...tableRows])) {
    return false;
  }

  const numColumns = headingRow.length;
  const table = {
    type: BLOCK_TYPES.TABLE,
    headless,
    children: [
      createTableRow(numColumns, !headless, (col) => headingRow[col]),
      ...tableRows.map((tableCells) => createTableRow(numColumns, false, (col) => tableCells[col])),
    ],
  };

  Editor.withoutNormalizing(editor, () => {
    Transforms.delete(editor, {
      at: { path, offset: startIndex },
      distance: tableText.length,
      unit: 'character',
    });
    if (!Editor.string(editor, path)) {
      const rootPath = path.slice(0, -1);
      Transforms.removeNodes(editor, { at: rootPath });
      Transforms.insertNodes(editor, table, { at: rootPath });
    } else {
      Transforms.insertNodes(editor, table, { at: { path, offset: startIndex } });
    }
  });
  return true;
};
