import { createNewMessage } from 'bundles/ai-coach-platform/components/patterns/chat/utils/messageUtils';

import { AICOACH_FEEDBACK, MESSAGE_SENDER } from '../../constants';
import {
  ADD_NEW_RESPONSE_MESSAGE,
  ADD_NEW_USER_MESSAGE,
  DELETE_MESSAGES,
  DROP_MESSAGES,
  FEEDBACK_SET_LIKE,
  FEEDBACK_SET_UNLIKE,
  FEEDBACK_UNSET,
  HIDE_AVATAR,
  SET_CHAT_SESSION_ID,
  UPDATE_CHAT_HISTORY,
} from '../actions/types';
import type { MessagesActions, ResponseMessage, UpdateChatHistory, UserMessage } from '../actions/types';
import type { MessagesState } from '../types';
import { createReducer } from '../utils/createReducer';

const initialState = {
  messages: [],
  chatSessionId: null,
};
const messagesReducer = {
  [UPDATE_CHAT_HISTORY]: (state: MessagesState, { messages }: { messages: UpdateChatHistory['messages'] }) => {
    const formattedMessages = messages.map((message) => {
      return createNewMessage(message);
    });

    return {
      ...state,
      messages: [...formattedMessages],
    };
  },
  [ADD_NEW_USER_MESSAGE]: (state: MessagesState, { text, id, timestamp, action }: UserMessage) => {
    return {
      ...state,
      messages: [...state.messages, createNewMessage({ text, sender: MESSAGE_SENDER.CLIENT, id, timestamp, action })],
    };
  },
  [ADD_NEW_RESPONSE_MESSAGE]: (state: MessagesState, { text, id, userFeedback, timestamp }: ResponseMessage) => ({
    ...state,
    messages: [
      ...state.messages,
      createNewMessage({
        text,
        sender: MESSAGE_SENDER.RESPONSE,
        id,
        userFeedback,
        timestamp,
      }),
    ],
  }),

  [DROP_MESSAGES]: (state: MessagesState) => ({ ...state, messages: [] }),

  [HIDE_AVATAR]: (state: MessagesState, { index }: { index: number }) => {
    state.messages[index].showAvatar = false;
  },
  /**
   * The following function deletes a certain number of messages, where the
   * number of messages to delete is the input `count`.
   *
   * When the input `id` is available, the last message to delete is the one
   * whose customId matches `id`; otherwise, the last message to delete is the
   * last message in the list
   */
  [DELETE_MESSAGES]: (state: MessagesState, { count, id }: { count: number; id: string }) => {
    const remainingMessages = id
      ? state.messages.filter((_, index) => {
          const targetMsgIndex = state.messages.findIndex((tMsg) => tMsg.customId === id);
          return index < targetMsgIndex - count + 1 || index > targetMsgIndex;
        })
      : state.messages.slice(0, state.messages.length - count);

    return {
      ...state,
      messages: remainingMessages,
    };
  },

  [FEEDBACK_SET_LIKE]: (state: MessagesState, { id }: { id: string }) => ({
    ...state,
    messages: state.messages.map((message) => ({
      ...message,
      userFeedback: id === message.customId ? AICOACH_FEEDBACK.like : message.userFeedback,
    })),
  }),

  [FEEDBACK_SET_UNLIKE]: (state: MessagesState, { id }: { id: string }) => ({
    ...state,
    messages: state.messages.map((message) => ({
      ...message,
      userFeedback: id === message.customId ? AICOACH_FEEDBACK.dislike : message.userFeedback,
    })),
  }),

  [FEEDBACK_UNSET]: (state: MessagesState, { id }: { id: string }) => ({
    ...state,
    messages: state.messages.map((message) => ({
      ...message,
      userFeedback: id === message.customId ? AICOACH_FEEDBACK.unset : message.userFeedback,
    })),
  }),
  [SET_CHAT_SESSION_ID]: (state: MessagesState, { chatSessionId }: { chatSessionId: string | null }) => ({
    ...state,
    chatSessionId,
  }),
};

export default (state = initialState, action: MessagesActions) => createReducer(messagesReducer, state, action);
