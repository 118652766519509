import { useCallback, useMemo, useRef } from 'react';

import { throttle } from 'lodash';
import type { Editor } from 'slate';
import { ReactEditor } from 'slate-react';

import type {
  CancelCommand,
  Command,
  CompleteCommand,
  CursorCommand,
  NewlineCommand,
  NormalizeCommand,
  TokenCommand,
} from 'bundles/cml/editor/components/buttons/ai/utils/commandUtils';
import {
  disableNormalization,
  enableNormalization,
  executeCommand,
} from 'bundles/cml/editor/components/buttons/ai/utils/commandUtils';

const AUTO_SCROLL_THRESHOLD_PX = 30;
const AUTO_SCROLL_TIMEOUT_MS = 10;
const COMMAND_BATCH = 1;
const COMMAND_FREQUENCY_MS = 15;

const positionNearBottom = (scrollNode: HTMLElement | null) => {
  if (!scrollNode) {
    return false;
  }

  const { scrollHeight, scrollTop, clientHeight } = scrollNode;
  const scrollPosition = scrollHeight - scrollTop;

  return scrollPosition - clientHeight <= AUTO_SCROLL_THRESHOLD_PX;
};

export const useCommandProcessor = (editor: Editor, scrollNode: HTMLElement | null) => {
  const commandsQueue = useRef<Command[]>([]);
  const pendingAutoScroll = useRef<number | null>(null);

  const autoScroll = useCallback(() => {
    if (pendingAutoScroll.current) {
      return;
    }

    pendingAutoScroll.current = window.setTimeout(() => {
      scrollNode?.scrollBy({ top: scrollNode.scrollHeight });
      pendingAutoScroll.current = null;
    }, AUTO_SCROLL_TIMEOUT_MS);
  }, [scrollNode]);

  const processCommands = useMemo(() => {
    const processCommandQueue = () => {
      if (!commandsQueue.current.length) {
        return;
      }

      if (!ReactEditor.isFocused(editor)) {
        ReactEditor.focus(editor);
      }

      const shouldAutoScroll = positionNearBottom(scrollNode);

      const commands = commandsQueue.current.splice(0, COMMAND_BATCH);
      commands.forEach((command) => executeCommand(editor, command));

      if (shouldAutoScroll) {
        autoScroll();
      }

      processCommands();
    };

    return throttle(processCommandQueue, COMMAND_FREQUENCY_MS);
  }, [editor, scrollNode, autoScroll]);

  const insertToken = useCallback(
    (token: string) => {
      if (!token) {
        return;
      }

      const command: TokenCommand = { type: 'TOKEN', token };
      commandsQueue.current.push(command);
      processCommands();
    },
    [processCommands]
  );

  const insertSoftBreak = useCallback(() => insertToken(`\n`), [insertToken]);

  const insertNewLine = useCallback(() => {
    const command: NewlineCommand = { type: 'NEWLINE' };
    commandsQueue.current.push(command);
    processCommands();
  }, [processCommands]);

  const cancel = useCallback(() => {
    processCommands.cancel();
    const command: CancelCommand = { type: 'CANCEL' };
    commandsQueue.current = [command];
    processCommands();

    if (pendingAutoScroll.current) {
      window.clearTimeout(pendingAutoScroll.current);
      pendingAutoScroll.current = null;
      scrollNode?.scrollBy({ top: scrollNode.scrollHeight });
    }
    enableNormalization(editor);
  }, [processCommands, scrollNode, editor]);

  const normalize = useCallback(
    (enable: boolean) => {
      const command: NormalizeCommand = {
        type: 'NORMALIZE',
        normalize: enable ? enableNormalization : disableNormalization,
      };
      commandsQueue.current.push(command);
      processCommands();
    },
    [processCommands]
  );

  const flush = useCallback(() => {
    return new Promise<void>((resolve) => {
      const command: CompleteCommand = { type: 'ON_COMPLETE', callback: resolve };
      commandsQueue.current.push(command);
      processCommands();
    });
  }, [processCommands]);

  const start = useCallback(() => {
    const command: CursorCommand = { type: 'CURSOR' };
    executeCommand(editor, command);
  }, [editor]);

  return {
    insertToken,
    insertNewLine,
    insertSoftBreak,
    normalize,
    flush,
    cancel,
    start,
  };
};
