import * as React from 'react';
import { useCallback, useState } from 'react';

import { ButtonGroup, Dialog, TextField } from '@coursera/cds-core';

import _t from 'i18n!nls/cml';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (feedback?: string) => void;
};

const AIFeedbackDialog: React.FC<Props> = ({ open, onClose, onSubmit }) => {
  const [value, setValue] = useState<string>();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setValue(e.target.value),
    []
  );

  const handleSubmit = useCallback(() => {
    onSubmit(value);
  }, [value, onSubmit]);

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.HeadingGroup>{_t('Thanks for your feedback')}</Dialog.HeadingGroup>
      <Dialog.Content>
        <TextField
          multiline
          optional
          value={value}
          placeholder={_t('Tell us a bit more or suggest a better version')}
          label={_t('What went wrong')}
          supportText={_t(
            `We're always working to improve our system for better results. Your feedback helps make it more accurate and relevant.`
          )}
          onChange={handleChange}
        />
      </Dialog.Content>
      <Dialog.Actions>
        <ButtonGroup
          primaryButtonProps={{ onClick: handleSubmit, children: _t('Submit') }}
          alternateButtonProps={{ variant: 'secondary', onClick: onClose, children: _t('Cancel') }}
        />
      </Dialog.Actions>
    </Dialog>
  );
};

export default AIFeedbackDialog;
