import * as React from 'react';

import { Typography2 } from '@coursera/cds-core';
import { color } from '@coursera/coursera-ui';

import CourseActionBox from 'bundles/course-cards/components/course-card/enterprise/CourseActionBox';
import type { PropsForWithModal as PropsForCourseAndPartnerNameWithModal } from 'bundles/course-cards/components/course-card/enterprise/CourseAndPartnerName';
import { CourseAndPartnerNameWithModal } from 'bundles/course-cards/components/course-card/enterprise/CourseAndPartnerName';
import CourseCardAutoenrollmentDisclaimer from 'bundles/course-cards/components/course-card/enterprise/CourseCardAutoenrollmentDisclaimer';
import CourseProgressBox from 'bundles/course-cards/components/course-card/enterprise/CourseProgressBox';
import CourseWithProgressLinkButton from 'bundles/course-cards/components/course-card/enterprise/CourseWithProgressLinkButton';
import SessionEndInfo from 'bundles/course-cards/components/course-card/enterprise/SessionEndInfo';
import StaticLinearProgress from 'bundles/course-cards/components/course-card/enterprise/StaticLinearProgress';
import type CoursesV1 from 'bundles/naptimejs/resources/courses.v1';
import type OnDemandLearnerMaterialsV1 from 'bundles/naptimejs/resources/onDemandLearnerMaterials.v1';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import type OnDemandLearnerSessionsV1 from 'bundles/naptimejs/resources/onDemandLearnerSessions.v1';
import { getProgressByLearnerMaterial } from 'bundles/program-home/utils/CourseUtils';

import _t from 'i18n!nls/program-home';

type Props = {
  course: CoursesV1;
  cardDropdownWidget: JSX.Element;
  learnerSession?: OnDemandLearnerSessionsV1;
  learnerMaterials?: OnDemandLearnerMaterialsV1;
  isAutoenrolled?: boolean;
  isPartOfS12n?: boolean;
} & Omit<PropsForCourseAndPartnerNameWithModal, 'courseId' | 'courseName' | 'partnerIds'>;

// Don't show critical progress, current week
const CourseEnrolledPastPrivateSessionCard: React.FC<Props> = ({
  course: { id, name, homeLink, partnerIds },
  cardDropdownWidget,
  learnerMaterials,
  learnerSession,
  isAutoenrolled,
  isPartOfS12n,
  ...rest
}) => {
  const summaryCoreProgressPercentage = getProgressByLearnerMaterial(learnerMaterials);

  return (
    <div className="rc-CourseEnrolledPastPrivateSessionCard row flex-1">
      <div className="col-xs-12 col-sm-8 col-md-9 pos-static-override-hack">
        <CourseAndPartnerNameWithModal
          courseId={id}
          courseName={name}
          partnerIds={partnerIds}
          isPartOfS12n={isPartOfS12n}
          {...rest}
        />
        {cardDropdownWidget}
        {isPartOfS12n && (
          <Typography2 component="p" className="m-b-1" variant="bodyPrimary">
            {_t('Started')}
          </Typography2>
        )}
        <CourseProgressBox>
          <div className="flex-1">
            <Typography2 component="span" variant="subtitleMedium" className="text-capitalize">
              {_t('progress: #{summaryCoreProgressPercentage}%', { summaryCoreProgressPercentage })}
            </Typography2>
            <StaticLinearProgress progress={summaryCoreProgressPercentage} color={color.success} aria-label={name} />
          </div>
          {learnerSession && <SessionEndInfo endsAt={learnerSession.endsAt} isEnded={learnerSession.isEnded} />}
        </CourseProgressBox>
        {isAutoenrolled && <CourseCardAutoenrollmentDisclaimer />}
      </div>
      <CourseActionBox>
        <CourseWithProgressLinkButton
          link={homeLink}
          progress={summaryCoreProgressPercentage}
          trackingNamePrefix="enrolled_past_private"
          trackingButtonName="go_to_course"
          buttonTextOverride={_t('Go to course')}
          courseName={name}
        />
      </CourseActionBox>
    </div>
  );
};

export default CourseEnrolledPastPrivateSessionCard;
