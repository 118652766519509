import 'bundles/author-lecture/components/video-trimming/utils/clippedVideoPlayerPlugins';

export const clippedVideoPlayerOptionKey = 'isClippedVideoPlayer';

export const CLIPPED_VIDEO_PLAYER_OPTIONS = {
  controlBar: {
    children: [
      {
        name: 'clippedProgressControl',
        children: [
          {
            name: 'clippedSeekBar',
            children: ['loadProgressBar', 'clippedPlayProgressBar', 'mouseTimeDisplay'],
            barName: 'clippedPlayProgressBar',
          },
        ],
      },
      'cPlayToggle',
      'clippedCurrentTimeDisplay',
      'timeDivider',
      'durationDisplay',
      {
        name: 'subtitlesMenuButton',
        defaultSubtitleLanguage: undefined,
      },
      {
        name: 'cVolumeMenuButton',
        inline: false,
        vertical: true,
      },
      'settingsMenuButton',
      'cFullscreenToggle',
    ],
  },
};
