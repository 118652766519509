/** @jsx jsx */
import { jsx } from '@emotion/react';

import { useQuery } from '@apollo/client';

import Imgix from 'js/components/Imgix';
import { SHORT_MONTH_DAY_DISPLAY, formatDateTimeDisplay } from 'js/utils/DateTimeUtils/DateTimeUtils';

import { Typography2, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import { CourseBadge, CreditBadge } from 'bundles/enterprise-ui/components/Badges';
import { CourseAutoenrollmentModalQuery } from 'bundles/program-common/constants/ProgramCommonGraphqlQueries';
import type {
  CourseAutoenrollmentModalQuery as CourseAutoenrollmentModalQueryData,
  CourseAutoenrollmentModalQueryVariables,
  CourseAutoenrollmentModalQuery_ProgramProductMetadataV1Resource_multiGet_elements_metadataType_ProgramProductMetadataV1_courseMember as CourseMemberMetadata,
} from 'bundles/program-common/constants/__generated__/CourseAutoenrollmentModalQuery';
import type { ProductCardCourseFragmentFragment as Course } from 'bundles/program-common/queries/__generated__/ProductCoursesQuery';
import filterExistsOrDefault from 'bundles/program-common/utils/filterExistsOrDefault';
import Skeleton from 'bundles/program-home/components/cds/Skeleton';
import { CourseAutoenrollmentSessionDatesQuery } from 'bundles/program-home/utils/ProgramHomeGraphqlQueries';
import type {
  CourseAutoenrollmentSessionDatesQuery as CourseAutoenrollmentSessionDatesQueryData,
  CourseAutoenrollmentSessionDatesQueryVariables,
  CourseAutoenrollmentSessionDatesQuery_EnterpriseProgramSessionAssociationsV1Resource_byProgramAndCourses_elements_session as Session,
} from 'bundles/program-home/utils/__generated__/CourseAutoenrollmentSessionDatesQuery';
import { useCampusWordingForLearner } from 'bundles/unified-common/hooks/useCampusWordingForLearner';

const IMAGE_DIMENSION = 80;

type SelectedForCreditMap = {
  [courseId: string]: boolean;
};

type PropsFromCaller = {
  autoEnrolledCoursesWithData: Course[];
  programId: string;
  userId: number;
};

type PropsFromMetadataGraphql = {
  coursesSelectedForCreditMap?: SelectedForCreditMap;
};

type PropsFromSessionDatesGraphql = {
  sessionDates?: Array<Session>;
};

type Props = Pick<PropsFromCaller, 'autoEnrolledCoursesWithData'> &
  PropsFromMetadataGraphql &
  PropsFromSessionDatesGraphql;

const styles = {
  skeletonImage: (theme: Theme) => ({
    backgroundColor: theme.palette.gray[100],
    borderRadius: 3,
    height: IMAGE_DIMENSION,
    marginRight: theme.spacing(16),
  }),
  productDescription: (theme: Theme) => ({
    display: 'flex',
    flexFlow: 'row nowrap',
    borderBottom: `1px solid ${theme.palette.gray[300]}`,
    alignItems: 'center',
    marginTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
  }),
  pillSection: (theme: Theme) => ({
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  }),
  courseImage: (theme: Theme) => ({
    marginRight: theme.spacing(16),
    borderRadius: 3,
  }),
  sessionDates: (theme: Theme) => ({
    marginLeft: theme.spacing(16),
  }),
  courseList: {
    paddingLeft: 0,
  },
};

type EnrolledCourseDescriptionProps = {
  course: Course;
  isSelectedForCredit?: boolean;
  session?: Session;
  isCampusWording?: boolean;
};

export const EnrolledCourseDescription = ({
  course,
  isSelectedForCredit,
  session,
  isCampusWording,
}: EnrolledCourseDescriptionProps) => {
  const theme = useTheme();
  const partnerName = course.partners?.[0]?.name;

  return (
    <li css={styles.productDescription(theme)} data-e2e={`${course.id}-course-description`}>
      {course.promoPhoto ? (
        <Imgix
          src={course.promoPhoto}
          alt={course.name}
          maxWidth={IMAGE_DIMENSION}
          maxHeight={IMAGE_DIMENSION}
          css={styles.courseImage(theme)}
        />
      ) : (
        <Skeleton width={IMAGE_DIMENSION} bonusCSS={styles.skeletonImage(theme)} />
      )}
      <div>
        <Typography2 component="span" variant="subtitleMedium">
          {course.name}
        </Typography2>
        {partnerName ? (
          <Typography2 component="p" variant="bodySecondary" color="supportText">
            {partnerName}
          </Typography2>
        ) : (
          <Skeleton width="100px" />
        )}

        <div css={styles.pillSection(theme)}>
          <CourseBadge />
          {isSelectedForCredit && isCampusWording && <CreditBadge className="m-l-1s" />}
          {session && (
            <Typography2
              component="p"
              variant="subtitleMedium"
              css={styles.sessionDates(theme)}
              data-test="course-session-dates"
            >
              {session?.startsAt ? formatDateTimeDisplay(session.startsAt, SHORT_MONTH_DAY_DISPLAY) : ''}-
              {session?.endsAt ? formatDateTimeDisplay(session.endsAt, SHORT_MONTH_DAY_DISPLAY) : ''}
            </Typography2>
          )}
        </div>
      </div>
    </li>
  );
};

export const AutoenrolledCourseList = ({
  autoEnrolledCoursesWithData,
  coursesSelectedForCreditMap,
  sessionDates,
}: Props) => {
  const isCampusWording = useCampusWordingForLearner();
  return (
    <ol css={styles.courseList}>
      {autoEnrolledCoursesWithData.map((course) => (
        <EnrolledCourseDescription
          key={course.id}
          course={course}
          isSelectedForCredit={coursesSelectedForCreditMap?.[course.id]}
          session={sessionDates?.find((session) => session.courseId === course.id)}
          isCampusWording={isCampusWording}
        />
      ))}
    </ol>
  );
};

const AutoenrolledCourseListContainer = ({ autoEnrolledCoursesWithData, programId }: PropsFromCaller) => {
  const { data: courseAutoenrollmentModalQueryData } = useQuery<
    CourseAutoenrollmentModalQueryData,
    CourseAutoenrollmentModalQueryVariables
  >(CourseAutoenrollmentModalQuery, {
    variables: {
      metadataIds: autoEnrolledCoursesWithData.map((course) => `${programId}~VerifiedCertificate~${course.id}`),
    },
  });

  const programProductMetadata = filterExistsOrDefault(
    courseAutoenrollmentModalQueryData?.ProgramProductMetadataV1Resource?.multiGet?.elements
  );

  const coursesSelectedForCreditMap = programProductMetadata.reduce((mappedValues: SelectedForCreditMap, metadata) => {
    const courseMetadata = (metadata.metadataType as CourseMemberMetadata).course;
    // eslint-disable-next-line no-param-reassign
    mappedValues[courseMetadata.courseId] = courseMetadata.isSelectedForCredit;
    return mappedValues;
  }, {});

  const { data: courseAutoenrollmentSessionDatesQueryData } = useQuery<
    CourseAutoenrollmentSessionDatesQueryData,
    CourseAutoenrollmentSessionDatesQueryVariables
  >(CourseAutoenrollmentSessionDatesQuery, {
    variables: {
      courseIds: autoEnrolledCoursesWithData.map((course) => course.id),
      programId,
    },
  });

  const sessions = filterExistsOrDefault(
    courseAutoenrollmentSessionDatesQueryData?.EnterpriseProgramSessionAssociationsV1Resource?.byProgramAndCourses
      ?.elements
  );

  const sessionDates = filterExistsOrDefault(sessions.map((session) => session.session));

  return (
    <AutoenrolledCourseList
      coursesSelectedForCreditMap={coursesSelectedForCreditMap}
      autoEnrolledCoursesWithData={autoEnrolledCoursesWithData}
      sessionDates={sessionDates}
    />
  );
};

export default AutoenrolledCourseListContainer;
