import { useCallback, useMemo } from 'react';

import { ApolloClient, InMemoryCache } from '@apollo/client';

import { get as getCookie } from 'js/lib/cookie';
import { getIetfLanguageTag } from 'js/lib/language';

import AiWriginAssistant from 'bundles/cml/editor/api/AiWritingAssistant.graphql';
import type {
  AiWritingAssistantSubscription,
  AiWritingAssistantSubscriptionVariables,
} from 'bundles/cml/editor/api/__generated__/AiWritingAssistant';
import SSELink from 'bundles/page/lib/SSELink';

export type SubscriptionInput = {
  completeText: string;
  startIndex?: number;
  endIndex?: number;
  userPrompt?: string;
  promptId: string;
  temperature: number;
  onChange?: (message: { id?: string; delta: string }) => void;
  onComplete?: (result: string) => void;
  onError?: (error: any) => void;
};

declare const COURSERA_APP_NAME: string;
declare const COURSERA_APP_VERSION: string;

export const useSubscription = () => {
  const client = useMemo(() => {
    const csrf3Token = getCookie('CSRF3-Token');
    const cache = new InMemoryCache();
    return new ApolloClient({
      link: new SSELink({
        url: '/dgs/ai-authoring-application/subscriptions',
        credentials: 'same-origin',
        headers: {
          'Accept-Language': getIetfLanguageTag(),
          'R2-APP-VERSION': COURSERA_APP_VERSION,
          'X-Coursera-Application': COURSERA_APP_NAME,
          'X-Coursera-Version': COURSERA_APP_VERSION,
          'apollographql-client-name': COURSERA_APP_NAME,
          'apollographql-client-version': COURSERA_APP_VERSION,
          'X-CSRF3-Token': csrf3Token ?? '',
        },
      }),
      cache,
    });
  }, []);

  return useCallback(
    ({
      completeText,
      startIndex = 0,
      endIndex = completeText.length,
      temperature,
      userPrompt,
      promptId,
      onChange,
      onComplete,
      onError,
    }: SubscriptionInput) => {
      const input = {
        selectedText: {
          completeText,
          selectionStartIndex: startIndex,
          selectionEndIndex: endIndex,
        },
        systemPrompt: {
          promptTemplateId: promptId,
        },
        temperature,
        userPrompt,
      };

      let result = '';

      const observable = client.subscribe<AiWritingAssistantSubscription, AiWritingAssistantSubscriptionVariables>({
        query: AiWriginAssistant,
        variables: { input },
      });

      return observable.subscribe({
        next: async ({ data }) => {
          const { delta, id } = data?.AiAuthoring_WritingAssistant ?? {};
          if (delta == null) {
            return;
          }

          onChange?.({ delta, id });

          result += delta;
        },
        complete: () => onComplete?.(result),
        error: onError,
      });
    },
    [client]
  );
};
