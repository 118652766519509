import { useCallback } from 'react';

import type { Editor } from 'slate';
import type { Subscription } from 'zen-observable-ts';

import { PROMPTS_MAP } from 'bundles/cml/editor/components/buttons/ai/constants/prompts';
import { useAiWritingAssistant } from 'bundles/cml/editor/components/buttons/ai/hooks/useAiWritingAssistant';
import { getSelectedText } from 'bundles/cml/editor/utils/writingAssistantUtils';
import type { AIElement } from 'bundles/cml/shared/types/elementTypes';

export type RegenerateInput = {
  editor: Editor;
  element: AIElement;
  temperature: number;
  onComplete?: (id: string) => void;
  onError?: () => void;
};

export const useRegenerate = (aiEditor: Editor, scrollNode: HTMLElement | null) => {
  const regenerate = useAiWritingAssistant(aiEditor, scrollNode);

  return useCallback(
    ({ editor, element, temperature, onComplete, onError }: RegenerateInput): Subscription | undefined => {
      const { aiTool, prompt: userPrompt } = element;
      const { completeText, startIndex, endIndex } = getSelectedText(editor, element);
      if (!completeText || !editor.selection) {
        return undefined;
      }

      return regenerate({
        userPrompt,
        promptId: PROMPTS_MAP[aiTool],
        completeText,
        startIndex,
        endIndex,
        temperature,
        onComplete,
        onError,
      });
    },
    [regenerate]
  );
};
