import { Editor, Element, Range, Transforms } from 'slate';
import type { Node, Path } from 'slate';
// @ts-expect-error
import { isURL } from 'validator';

import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { LinkElement } from 'bundles/cml/shared/types/elementTypes';

export const DEFAULT_LINK_HREF = 'https://';

const MAILTO_REGEX = /^mailto/;

export const isValidURL = (url: string): boolean => {
  // eslint-disable-next-line camelcase
  return isURL(url, { require_protocol: true }) || MAILTO_REGEX.test(url);
};

export const addLink = (editor: Editor) => {
  const { selection } = editor;
  if (!selection) {
    return;
  }

  const text = Editor.string(editor, selection);
  const href = isValidURL(text) ? text : DEFAULT_LINK_HREF;

  const linkElement: LinkElement = {
    type: BLOCK_TYPES.LINK,
    isInline: true,
    title: '',
    href,
    editLinkDialog: true,
    children: [],
  };

  if (Range.isCollapsed(selection)) {
    Transforms.insertNodes(editor, { ...linkElement, children: [{ text: href }] });
  } else {
    Transforms.wrapNodes(editor, linkElement, { at: selection, split: true });
  }

  Transforms.collapse(editor, { edge: 'end' });
  Transforms.move(editor, { unit: 'offset', reverse: true });
};

export const unlinkText = (editor: Editor, path?: Path) => {
  Transforms.unwrapNodes(editor, {
    at: path,
    match: (n: Node) => Element.isElement(n) && n.type === BLOCK_TYPES.LINK,
  });
};
