/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useRef } from 'react';

import { MenuList, Popover, Typography2 } from '@coursera/cds-core';

import CustomPromptMenuItem from 'bundles/cml/editor/components/buttons/ai/CustomPromptMenuItem';
import ExpandTextMenuItem from 'bundles/cml/editor/components/buttons/ai/ExpandTextMenuItem';
import GenerateListMenuItem from 'bundles/cml/editor/components/buttons/ai/GenerateListMenuItem';
import GenerateTableMenuItem from 'bundles/cml/editor/components/buttons/ai/GenerateTableMenuItem';
import ImproveClarityMenuItem from 'bundles/cml/editor/components/buttons/ai/ImproveClarityMenuItem';
import ShortenTextMenuItem from 'bundles/cml/editor/components/buttons/ai/ShortenTextMenuItem';
import { type AI_TOOLS } from 'bundles/cml/shared/constants';

import _t from 'i18n!nls/cml';

type Props = {
  anchorEl?: HTMLElement;
  open: boolean;
  isFocusVisible: boolean;
  onClose: () => void;
  onChange: (change: { aiTool: AI_TOOLS; prompt?: string }) => void;
};

const styles = {
  menu: css`
    padding: var(--cds-spacing-200);
  `,
  promptItem: css`
    margin-bottom: var(--cds-spacing-100);
  `,
  hideFocusOutline: css`
    .cds-buttonBase-focusVisible::after {
      display: none !important;
    }
  `,
};

const AIButtonMenu: React.FC<Props> = ({ anchorEl, open, isFocusVisible, onClose, onChange }: Props) => {
  const hideFocusOutline = useRef(!isFocusVisible);

  return (
    <Popover dropdownProps={{ autoFocus: false }} anchorElement={anchorEl} open={open} onClose={onClose}>
      <div css={styles.menu} className="rc-CMLEditorMenu">
        <CustomPromptMenuItem onChange={onChange} css={styles.promptItem} />
        <MenuList css={hideFocusOutline.current && styles.hideFocusOutline}>
          <MenuList.Group
            // @ts-expect-error
            label={
              <Typography2 variant="actionSecondary" component="div" color="supportText">
                {_t('Suggested')}
              </Typography2>
            }
          >
            <ShortenTextMenuItem onChange={onChange} />
            <ExpandTextMenuItem onChange={onChange} />
            <ImproveClarityMenuItem onChange={onChange} />
            <GenerateListMenuItem onChange={onChange} />
            <GenerateTableMenuItem onChange={onChange} />
          </MenuList.Group>
        </MenuList>
      </div>
    </Popover>
  );
};

export default AIButtonMenu;
