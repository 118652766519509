/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { RenderElementProps } from 'slate-react';

import { AiLoadingIndicator } from 'bundles/cds-labs/components';

const styles = {
  indicator: css`
    margin-left: var(--cds-spacing-100);
  `,
};

const AICursor: React.FC<RenderElementProps> = ({ attributes, children }) => {
  return (
    <span contentEditable={false} {...attributes}>
      <span>{children}</span>
      <span contentEditable={false}>
        <AiLoadingIndicator css={styles.indicator} />
      </span>
    </span>
  );
};

export default AICursor;
