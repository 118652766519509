import type { Node, NodeEntry } from 'slate';
import { Editor, Text, Transforms } from 'slate';

import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import { Tools, type ToolsKeys } from 'bundles/cml/shared/utils/customTools';

const INLINE_MATH_REGEX = /(\$\$)(.+?)(\$\$)/i;
const BLOCK_MATH_REGEX = /(\\\\\[)(.*?)(\\\\\])/im;

export const normalizeMathMarkdown = (editor: Editor, tools: Set<ToolsKeys>, nodeEntry: NodeEntry<Node>) => {
  if (!Text.isText(nodeEntry[0]) || !tools.has(Tools.MATH)) {
    return false;
  }

  const [{ text }, path] = nodeEntry as NodeEntry<Text>;

  const match = text.match(INLINE_MATH_REGEX) || text.match(BLOCK_MATH_REGEX);
  if (!match) {
    return false;
  }

  const isInline = match[1] === '$$';
  const startOffset = match.index ?? 0;
  const endOffset = startOffset + match[0].length;

  Editor.withoutNormalizing(editor, () => {
    Transforms.insertNodes(
      editor,
      isInline
        ? {
            type: BLOCK_TYPES.MATH_INLINE,
            formula: match[0],
            isInline: true,
            isVoid: true,
            children: [{ text: '' }],
          }
        : {
            type: BLOCK_TYPES.MATH_BLOCK,
            formula: `\\[${match[2]}\\]`,
            isVoid: true,
            children: [{ text: '' }],
          },
      {
        at: { anchor: { path, offset: startOffset }, focus: { path, offset: endOffset } },
      }
    );
  });

  return true;
};
