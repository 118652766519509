import {
  DescriptionPage_CourseType as DescriptionPageCourseType,
  DescriptionPage_ProductVariant as DescriptionPageProductVariant,
  DescriptionPage_MarketingSource as MarketingSource,
} from '__generated__/graphql-types';

import getXDPPageLink from 'bundles/unified-description-page-common/utils/getXDPPageLink';

export const getSpecializationProductUrl = ({
  productSlug,
  productVariant,
  marketingSource,
  programSlug,
  localizedLanguageRegionCode,
}: {
  productSlug: string;
  productVariant: DescriptionPageProductVariant;
  marketingSource?: MarketingSource;
  programSlug?: string;
  localizedLanguageRegionCode?: string;
}) => {
  let basePath = '';

  // If program slug is included we know we want to direct to enterprise pages.
  if (programSlug && !marketingSource) {
    basePath = `/programs/${programSlug}`;
  }

  if (productVariant === DescriptionPageProductVariant.ProfessionalCertificate) {
    basePath += `/professional-certificates/${productSlug}`;
  } else {
    basePath += `/specializations/${productSlug}`;
  }

  if (marketingSource && marketingSource === MarketingSource.PaidMedia) {
    basePath = `/enroll${basePath}/paidmedia`;
  }

  return getXDPPageLink({ productPageUrlPath: basePath, subfolderLocaleCode: localizedLanguageRegionCode });
};

export const getCourseProductUrl = (productSlug: string, isProject?: boolean, programSlug?: string) => {
  let basePath = '';

  // If program slug is included we know we want to direct to enterprise pages.
  if (programSlug) {
    basePath = `/programs/${programSlug}`;
  }

  if (isProject) {
    return basePath + `/projects/${productSlug}`;
  } else {
    return basePath + `/learn/${productSlug}`;
  }
};

export const getInstructorPageUrl = (id: string, shortName?: string | null) => {
  if (shortName) {
    return `/instructor/${shortName}`;
  } else {
    return `/instructor/~${id}`;
  }
};

export const getPartnerPageUrl = (shortName: string): string => {
  if (shortName) {
    return `/partners/${shortName}`;
  }
  return '/';
};

/**
 * We house both "guided project" and "project" course types in ProjectPage.
 * We may consider creating GuidedProjectPage if we see enough deviation b/w the two course types in the future.
 * */
export const checkIsGuidedProject = (
  courseType: (typeof DescriptionPageCourseType)[keyof typeof DescriptionPageCourseType]
): boolean => courseType === DescriptionPageCourseType.RhymeProject;
