import { useCallback, useEffect, useRef, useState } from 'react';

type FocusCallback = () => void;

export const useFocusHandler = (
  element: HTMLElement | null,
  onFocus: FocusCallback | undefined,
  onBlur: FocusCallback | undefined
) => {
  const [focusedOverride, setFocusedOverride] = useState(false);
  const [focused, setFocused] = useState(false);
  const [mergedFocus, setMergedFocus] = useState(false);
  const focusedRef = useRef(focused);

  const handleFocus = useCallback(() => {
    if (!focusedRef.current) {
      setFocused(true);
      focusedRef.current = true;
    }
  }, []);

  const handleBlur = useCallback(
    (event: FocusEvent) => {
      if (!focusedRef.current) {
        return;
      }

      const target = event?.relatedTarget || document.activeElement;
      const isFocused = element?.contains(target as Node) === true;
      if (isFocused) {
        return;
      }

      setFocused(false);
      focusedRef.current = false;
    },
    [element]
  );

  useEffect(() => {
    element?.addEventListener('focusin', handleFocus);
    element?.addEventListener('focusout', handleBlur);

    return () => {
      element?.removeEventListener('focusin', handleFocus);
      element?.removeEventListener('focusout', handleBlur);
    };
  }, [element, handleFocus, handleBlur]);

  useEffect(() => {
    const value = focused || focusedOverride;
    if (mergedFocus === value) {
      return;
    }

    if (value) {
      onFocus?.();
    } else {
      onBlur?.();
    }

    setMergedFocus(value);
  }, [focused, focusedOverride, mergedFocus, onBlur, onFocus]);

  return {
    focused: mergedFocus,
    setFocusedOverride,
    setFocused: handleFocus,
  };
};
