/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import config from 'js/app/config';
import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';

import { Link, Typography2 } from '@coursera/cds-core';

import { CoachButton } from 'bundles/ai-coach-platform/components/building-blocks';
import { useSettingsNotification } from 'bundles/ai-coach-platform/components/patterns/chat/settings/useSettingsNotification';
import LearnerHelpLink from 'bundles/common/components/LearnerHelpLink';

import _t from 'i18n!nls/ai-coach-platform';

import SettingsPage from '../SettingsPage';

const styles = {
  root: css`
    height: 100%;
  `,
};

type Props = {
  onBack: () => void;
};

export const SettingsChatHistory = (props: Props): React.ReactElement => {
  const { onBack } = props;
  const { addNotification, clearNotification } = useSettingsNotification();
  const [deleteInProgress, setDeleteInProgress] = React.useState<boolean>(false);
  const [deleteDisabled, setDeleteDisabled] = React.useState<boolean>(false);

  const handleBack = () => {
    onBack();
  };

  React.useEffect(() => {
    return () => {
      clearNotification();
    };
  }, [clearNotification]);

  const handleDeleteAll = () => {
    /* TODO */
    setDeleteInProgress(true);

    setTimeout(() => {
      addNotification({
        content: _t('All chat history deletion in progress. This may take a while.'),
        severity: 'information',
      });
      setDeleteInProgress(false);
      setDeleteDisabled(true);
    }, 1000);
  };

  return (
    <div className="coach-settings-chathistory" css={styles.root}>
      <SettingsPage
        onBack={handleBack}
        title={_t('Chat history settings')}
        footer={
          <CoachButton
            aria-label={_t('Delete all chat history')}
            onClick={handleDeleteAll}
            loading={deleteInProgress}
            disabled={deleteDisabled}
          >
            {_t('Delete all')}
          </CoachButton>
        }
      >
        <Typography2 variant="subtitleMedium" component="h3">
          {_t('Chat history')}
        </Typography2>
        <Typography2 variant="bodySecondary" component="p" color="supportText">
          <FormattedHTMLMessage
            message={_t(
              `Coursera keeps an internal record of your chat history with #{coachLabel} in accordance with our
          {privacyNoticeUrl} to help us improve your learning experience. {helpArticleUrl}`,
              { coachLabel: _t('Coach') }
            )}
            privacyNoticeUrl={
              <Link href={`${config.url.base}about/privacy`} target="_blank" rel="noopener noreferrer">
                {_t('Privacy Notice')}
              </Link>
            }
            helpArticleUrl={<LearnerHelpLink articleId="000002224" />}
          />
        </Typography2>
      </SettingsPage>
    </div>
  );
};

export default SettingsChatHistory;
