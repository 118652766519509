import { useCallback } from 'react';

import type { Editor } from 'slate';

import { useSubscription } from 'bundles/cml/editor/api/useSubscription';
import { useCommandProcessor } from 'bundles/cml/editor/components/buttons/ai/hooks/useCommandProcessor';
import { MarkdownBlock } from 'bundles/cml/editor/components/buttons/ai/utils/markdownBlock';
import { useToolsContext } from 'bundles/cml/editor/context/toolsContext';
import { normalizeWithMarkdown } from 'bundles/cml/editor/normalize/normalize';

type Input = {
  completeText: string;
  startIndex?: number;
  endIndex?: number;
  temperature: number;
  userPrompt?: string;
  promptId: string;
  onComplete?: (id: string) => void;
  onError?: () => void;
};

const NEWLINE_CHAR = '\n';
const MAYBE_NEWLINE_CHAR = '\\';

export const useAiWritingAssistant = (editor: Editor, scrollNode: HTMLElement | null) => {
  const { tools } = useToolsContext();
  const subscription = useSubscription();
  const commandProcessor = useCommandProcessor(editor, scrollNode);

  return useCallback(
    ({ completeText, startIndex, endIndex, userPrompt, promptId, temperature, onComplete, onError }: Input) => {
      const normalizeFallback = editor.normalizeNode.bind(editor);
      editor.normalizeNode = normalizeWithMarkdown(editor, new Set(tools), normalizeFallback);

      let result = '';
      let currentBlock = new MarkdownBlock();

      let prevMessage = '';
      let id = '';

      const onMessage = (message: { id?: string; delta: string }) => {
        // handles the case where the newline char could be split across multiple messages
        if (message.delta.endsWith(MAYBE_NEWLINE_CHAR)) {
          prevMessage += message.delta;
          return;
        }

        if (message.id) {
          id = message.id;
        }

        let normalizedMessage = message.delta;
        if (prevMessage) {
          normalizedMessage = prevMessage + message.delta;
          prevMessage = '';
        }

        normalizedMessage = normalizedMessage.replace(/\\n/g, NEWLINE_CHAR);

        const messages = normalizedMessage.split(NEWLINE_CHAR);
        messages.forEach((value, index) => {
          if (index > 0 && !result.endsWith(NEWLINE_CHAR)) {
            if (currentBlock.isMultiLine) {
              currentBlock.insertSoftBreak();
              commandProcessor.insertSoftBreak();
            } else {
              currentBlock = new MarkdownBlock();
              commandProcessor.insertNewLine();
            }

            result += NEWLINE_CHAR;
          }

          const prevMultiLineBlock = currentBlock.isMultiLine;
          const prevMarkdownBlock = currentBlock.hasMarkdown;

          currentBlock.updateValue(value);
          result += value;

          if (currentBlock.hasMarkdown) {
            commandProcessor.normalize(false);
          }

          commandProcessor.insertToken(value);

          if (!prevMultiLineBlock && currentBlock.isMultiLine) {
            commandProcessor.normalize(false);
          } else if (prevMultiLineBlock && !currentBlock.isMultiLine) {
            commandProcessor.normalize(true);
            currentBlock = new MarkdownBlock();
          } else if (prevMarkdownBlock && !currentBlock.hasMarkdown) {
            commandProcessor.normalize(true);
            currentBlock = new MarkdownBlock();
          }
        });
      };

      const handleCleanup = () => {
        editor.normalizeNode = normalizeFallback;
        currentBlock = new MarkdownBlock();
      };

      const handleComplete = () => {
        if (prevMessage) {
          commandProcessor.insertToken(prevMessage);
        }

        commandProcessor.normalize(true);
        commandProcessor.flush().then(() => {
          handleCleanup();
          onComplete?.(id);
        });
      };

      const handleError = () => {
        commandProcessor.cancel();
        handleCleanup();
        onError?.();
      };

      commandProcessor.start();
      const graphqlSubscription = subscription({
        completeText,
        startIndex,
        endIndex,
        userPrompt,
        promptId,
        temperature,
        onChange: onMessage,
        onComplete: handleComplete,
        onError: handleError,
      });

      return {
        unsubscribe: () => {
          graphqlSubscription?.unsubscribe();
          commandProcessor.cancel();
          commandProcessor.normalize(true);
          commandProcessor.flush().then(handleCleanup);
        },
        closed: graphqlSubscription?.closed,
      };
    },
    [editor, tools, subscription, commandProcessor]
  );
};
