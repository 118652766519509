import GrowthDiscoveryEpicClient from 'bundles/epic/clients/GrowthDiscovery';

const AI_CAMPAIGN_CERTIFICATE_SLUGS = [
  'google-data-analytics',
  'google-digital-marketing-ecommerce',
  'google-it-support',
  'google-project-management',
  'google-ux-design',
  'google-cybersecurity'
];

const AI_SKILLS_SLUG_LIST = [
  'microsoft-power-bi-data-analyst',
  'microsoft-cybersecurity-analyst',
  'ibm-data-engineer',
  'ibm-data-science',
  'facebook-social-media-marketing',
  'facebook-marketing-analytics',
  'ibm-full-stack-cloud-developer',
  'ibm-data-analyst',
  'microsoft-public-relations',
  'ux-design',
  'microsoft-it-support-specialist',
  'google-cloud-cybersecurity-certificate',
  'google-cloud-data-analytics-certificate',
  'meta-data-analyst',
  'ibm-ai-product-manager',
  'microsoft-cloud-support-associate',
  'microsoft-business-analyst',
  'microsoft-project-management',
  'amazon-junior-software-developer'
] as const;

export const isPartOfRecentlyUpdatedBadgeExperiment = () => GrowthDiscoveryEpicClient.get('showRecentlyUpdatedBadge');

export const shouldShowRecentlyUpdatedGenAiBadge = (slug: string, preview?: boolean) => {
  return (
    AI_CAMPAIGN_CERTIFICATE_SLUGS.includes(slug) &&
    (preview ? GrowthDiscoveryEpicClient.preview('showGoogleRefreshCampaign') : GrowthDiscoveryEpicClient.get('showGoogleRefreshCampaign'))
  );
};

export const shouldShowAiSkillsBadge = (slug: string) => {
  return !!(AI_SKILLS_SLUG_LIST.includes(slug) &&
    GrowthDiscoveryEpicClient.get('showGoogleRefreshCampaign'));
}
