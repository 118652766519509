import * as React from 'react';
import { createContext, useEffect, useState } from 'react';

import type { ProfileInfoField } from 'bundles/ai-coach-platform/components/patterns/chat/settings/types';
import { captureMessageInSentry } from 'bundles/ai-coach-platform/utils/sentry';

type ProfileInfoFields = Record<
  ProfileInfoField,
  {
    enabled: boolean;
  }
>;

type CoachSettings = {
  /** the settings data saved on the BE for each setting */
  data?: {
    profileInfo?: {
      enabled: boolean;
      fields?: ProfileInfoFields;
    };
    memory?: {
      enabled: boolean;
      items: string[];
    };
    chatHistory?: {
      /* TBD */
    };
  };

  /** whether settings are being loaded */
  loading: boolean;

  /** update settings data */
  updateData: (data: CoachSettings['data']) => void;
};

const CoachSettingsContext = createContext<CoachSettings>({
  data: {},
  loading: true,
  updateData: (data) => {},
});

const SettingsContextProvider = ({
  children,
  settingsEnabled,
}: {
  children: React.ReactNode;
  settingsEnabled: boolean;
}) => {
  const [settingsData, setSettingsData] = useState<CoachSettings['data']>({});
  const [loading, setLoading] = useState(true);

  // Fetch settings from the backend API and update the state
  const fetchSettings = () => {
    try {
      // TODO: make actual gql call here and update settings
      setSettingsData({
        profileInfo: {
          enabled: true,
          fields: {
            educationHistory: {
              enabled: true,
            },
            workHistory: { enabled: true },
            location: { enabled: true },
          },
        },
        memory: { enabled: false, items: ['1', '2', '3', '4'] },
      });

      // temporary fake loader
      setTimeout(() => setLoading(false), 2000);
    } catch (error) {
      captureMessageInSentry({ error, source: 'SettingsContextProvider' });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (settingsEnabled) {
      fetchSettings();
    }
  }, [settingsEnabled]);

  const updateData = (data: CoachSettings['data']) => {
    setSettingsData(data);
  };

  return (
    <CoachSettingsContext.Provider value={{ data: settingsData, loading, updateData }}>
      {children}
    </CoachSettingsContext.Provider>
  );
};

export { CoachSettingsContext, SettingsContextProvider };
