/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useContext, useRef, useState } from 'react';

import { useSlateStatic } from 'slate-react';

import { MenuList } from '@coursera/cds-core';
import { MoreHorizontalFilledIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { toggleHeader } from 'bundles/cml/editor/components/elements/table/tableUtils';
import { FocusContext } from 'bundles/cml/editor/context/focusContext';
import { useStyleContext } from 'bundles/cml/editor/context/styleContext';
import FloatingMenu from 'bundles/cml/shared/components/menu/FloatingMenu';
import type { TableElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

type Props = {
  table: TableElement;
  onDelete: () => void;
};

const styles = {
  menu: css`
    padding: var(--cds-spacing-200);
  `,
};

const MoreTableOptionsButton: React.FC<Props> = ({ table, onDelete }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { setFocused } = useContext(FocusContext);
  const staticEditor = useSlateStatic();
  const { pageless } = useStyleContext();

  const ref = useRef<HTMLButtonElement>(null);

  const handleToggleHeader = useCallback(() => {
    toggleHeader(staticEditor, table);
  }, [staticEditor, table]);

  const handleOpenMenu = useCallback(() => {
    setFocused(true);
    setMenuOpen(true);
  }, [setFocused, setMenuOpen]);

  const handleCloseMenu = useCallback(() => {
    setMenuOpen(false);
    setTimeout(() => setFocused(false), 250);
  }, [setFocused, setMenuOpen]);

  return (
    <React.Fragment>
      <Button
        role="menuitem"
        ref={ref}
        title="More Options"
        active={menuOpen}
        onClick={handleOpenMenu}
        type={TOOLBAR_BUTTON_TYPES.menu}
      >
        <MoreHorizontalFilledIcon size="small" />
      </Button>
      {menuOpen && (
        <FloatingMenu anchorEl={ref.current} pageless={pageless} placement="bottom-start" onClose={handleCloseMenu}>
          <MenuList css={styles.menu}>
            <MenuList.Item onClick={handleToggleHeader}>
              {table.headless ? _t('Add header') : _t('Remove header')}
            </MenuList.Item>
            <MenuList.Item onClick={onDelete}>{_t('Remove table')}</MenuList.Item>
            <MenuList.Item onClick={handleCloseMenu}>{_t('Close')}</MenuList.Item>
          </MenuList>
        </FloatingMenu>
      )}
    </React.Fragment>
  );
};

export default MoreTableOptionsButton;
