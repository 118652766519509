/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { type ShiftOptions, type VirtualElement, limitShift } from '@floating-ui/react-dom';
import { ReactEditor, type RenderElementProps, useSlateStatic } from 'slate-react';
import type { Subscription } from 'zen-observable-ts';

import CMLEditor from 'bundles/cml/editor/components/CMLEditor';
import { PROMPTS_MAP } from 'bundles/cml/editor/components/buttons/ai/constants/prompts';
import { useAiWritingAssistant } from 'bundles/cml/editor/components/buttons/ai/hooks/useAiWritingAssistant';
import AIToolbar from 'bundles/cml/editor/components/elements/ai/AIToolbar';
import { useSlateEditor } from 'bundles/cml/editor/components/hooks/useSlateEditor';
import { TYPOGRAPHY_TOOLS } from 'bundles/cml/editor/components/toolbars/constants';
import ToolsContextProvider from 'bundles/cml/editor/context/ToolsContextProvider';
import { useFocusedContext } from 'bundles/cml/editor/context/focusContext';
import { useNotificationContext } from 'bundles/cml/editor/context/notificationContext';
import { useStyleContext } from 'bundles/cml/editor/context/styleContext';
import { useToolsContext } from 'bundles/cml/editor/context/toolsContext';
import { getSelectedRange, getSelectedText, updateAIElement } from 'bundles/cml/editor/utils/writingAssistantUtils';
import FloatingMenu from 'bundles/cml/shared/components/menu/FloatingMenu';
import { AI_TOOL } from 'bundles/cml/shared/constants';
import type { AIElement as AIElementType } from 'bundles/cml/shared/types/elementTypes';
import { Tools, type ToolsKeys } from 'bundles/cml/shared/utils/customTools';

import _t from 'i18n!nls/cml';

const styles = {
  floatingMenu: css`
    border: none;
    background: transparent;

    .rc-CMLEditor {
      border-radius: var(--cds-border-radius-50);
    }
  `,
  pageless: css`
    width: 782px;
    margin-bottom: var(--cds-spacing-150);
  `,
  editor: css`
    width: 450px;

    [data-testid='sticky-toolbar'] {
      bottom: 60px;
      border-top: none;
    }

    .data-cml-editor-padding-container {
      padding: var(--cds-spacing-200);
    }
  `,
  hidden: css`
    visibility: hidden;
  `,
};

const TOOLS: ToolsKeys[] = [
  ...TYPOGRAPHY_TOOLS,
  Tools.BOLD,
  Tools.ITALIC,
  Tools.UNDERLINE,
  Tools.MONOSPACE,
  Tools.MATH,
  Tools.LINK,
  Tools.UNLINK,
  Tools.UNORDERED_LIST,
  Tools.ORDERED_LIST,
  Tools.TABLE,
  Tools.SUBSCRIPT,
  Tools.SUPERSCRIPT,
  Tools.CODE,
];

const createVirtualElement = (parentEl: HTMLDivElement | null, range: Range | null): VirtualElement | null => {
  if (!range || !parentEl) {
    return null;
  }

  return {
    getBoundingClientRect: () => {
      const rect = range.getBoundingClientRect();
      const parentRect = parentEl.getBoundingClientRect();

      const mergedRect = {
        y: rect.y,
        top: rect.y,
        bottom: rect.bottom,
        height: rect.height,
        x: parentRect.x,
        left: parentRect.left,
        right: parentRect.right,
        width: parentRect.width,
      };

      return mergedRect;
    },
    contextElement: parentEl,
  };
};

const SHIFT_OPTIONS: ShiftOptions = {
  mainAxis: false,
  crossAxis: true,
  limiter: limitShift({ mainAxis: false, crossAxis: true }),
};

const DOM_RANGE_TIMEOUT_MS = 100;
const TOOLBAR_HEIGHT = 61;
const INLINE_OFFSET = 12;

const AIElement: React.FC<RenderElementProps> = ({ attributes, children, element }: RenderElementProps) => {
  const aiElement = element as AIElementType;
  const staticEditor = useSlateStatic();
  const [cml, setCml] = useState('<co-content><text></text></co-content>');
  const [hidden, setHidden] = useState(false);
  const [parentRef, setParentRef] = useState<HTMLDivElement | null>(null);
  const [virtualEl, setVirtualEl] = useState<VirtualElement | null>(null);
  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);

  const { setNotification } = useNotificationContext();
  const { tools, options } = useToolsContext();
  const customTools = useMemo(() => tools.filter((tool) => TOOLS.includes(tool)), [tools]);
  const aiEditor = useSlateEditor(setNotification, customTools, false);
  const aiStaticEditor = useRef(aiEditor);

  const aiWritingAssistant = useAiWritingAssistant(aiEditor, scrollRef);

  const { pageless } = useStyleContext();
  const subscriptionRef = useRef<Subscription | undefined>(undefined);

  useEffect(() => {
    if (!pageless) {
      return () => undefined;
    }

    const timeout = setTimeout(() => {
      const range = getSelectedRange(staticEditor, aiElement);
      try {
        const domRange = range ? ReactEditor.toDOMRange(staticEditor, range) : null;
        setVirtualEl(createVirtualElement(parentRef, domRange));
      } catch {
        // eslint-disable-next-line no-console
        console.error('[CMLEditor] Unable to get range from selection');
      }
    }, DOM_RANGE_TIMEOUT_MS);

    return () => clearTimeout(timeout);
  }, [staticEditor, aiElement, parentRef, pageless]);

  const [subscription, setSubscription] = useState<Subscription | undefined>(undefined);
  const { setFocused } = useFocusedContext();

  const handleStop = useCallback(() => {
    subscription?.unsubscribe();
    updateAIElement(staticEditor, aiElement, { id: undefined, generating: false });
  }, [staticEditor, aiElement, subscription]);

  const { generating, aiTool, anchorEl, prompt: userPrompt } = aiElement;

  const handleSubscriptionChange = useCallback((newSubscription: Subscription | undefined) => {
    setSubscription(newSubscription);
    subscriptionRef.current = newSubscription;
  }, []);

  useEffect(() => {
    if (subscription || !scrollRef) {
      return;
    }

    const { completeText, startIndex, endIndex } = getSelectedText(staticEditor, aiElement);
    if (!completeText || !staticEditor.selection) {
      return;
    }

    const onComplete = (id: string) => {
      updateAIElement(staticEditor, aiElement, { id, generating: false });
    };

    const onError = () => {
      updateAIElement(staticEditor, aiElement, { generating: false });
      setNotification({
        type: 'error',
        message: _t('An error occurred while generating content. Please try again later.'),
      });
    };

    const result = aiWritingAssistant({
      completeText,
      temperature: aiElement.temperature,
      startIndex,
      endIndex,
      userPrompt,
      promptId: PROMPTS_MAP[aiTool],
      onComplete,
      onError,
    });

    handleSubscriptionChange(result);
  }, [
    subscription,
    scrollRef,
    staticEditor,
    aiEditor,
    aiElement,
    aiWritingAssistant,
    userPrompt,
    aiTool,
    handleSubscriptionChange,
    setNotification,
  ]);

  const handleFocus = useCallback(() => setFocused(true), [setFocused]);
  const handleBlur = useCallback(() => setFocused(false), [setFocused]);

  useEffect(() => {
    return () => {
      handleBlur();
      subscriptionRef.current?.unsubscribe();
    };
  }, [handleBlur]);

  const placement = pageless ? 'bottom' : 'bottom-start';
  const offset = pageless ? undefined : INLINE_OFFSET;
  const placeholder = generating ? '' : undefined;

  return (
    <div {...attributes}>
      <div ref={setParentRef}>{children}</div>
      <div contentEditable={false}>
        <FloatingMenu
          anchorEl={anchorEl ?? virtualEl}
          strategy="fixed"
          pageless={pageless}
          placement={placement}
          onHide={setHidden}
          enableEscaped={pageless}
          shiftOptions={SHIFT_OPTIONS}
          offset={offset}
          css={[styles.floatingMenu, hidden && styles.hidden]}
        >
          <React.Fragment>
            <ToolsContextProvider tools={customTools} options={options}>
              <CMLEditor
                ref={setScrollRef}
                editor={aiEditor}
                cmlValue={cml}
                minHeight={300}
                maxHeight={300}
                onChange={setCml}
                readOnly={generating}
                footer={{
                  node: (
                    <AIToolbar
                      aiEditor={aiStaticEditor.current}
                      editor={staticEditor}
                      element={aiElement}
                      scrollNode={scrollRef}
                      onSubscriptionChange={handleSubscriptionChange}
                      onStop={handleStop}
                    />
                  ),
                  height: TOOLBAR_HEIGHT,
                }}
                placeholder={placeholder}
                borderColor="var(--cds-color-interactive-primary-hover)"
                resizable
                onFocus={handleFocus}
                onBlur={handleBlur}
                css={[styles.editor, pageless && styles.pageless]}
              />
            </ToolsContextProvider>
          </React.Fragment>
        </FloatingMenu>
      </div>
    </div>
  );
};

export default AIElement;
