import type { Editor, Node, NodeEntry } from 'slate';
import { Text, Transforms } from 'slate';

import { hasAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

const EXCLUDED_TYPES = [BLOCK_TYPES.BULLET_LIST, BLOCK_TYPES.NUMBER_LIST, BLOCK_TYPES.TABLE];

const NEWLINE_REGEX = /\n\n/i;
const NEWLINE_START_REGEX = /^\n\n/i;

export const normalizeLineBreakMarkdown = (editor: Editor, tools: Set<ToolsKeys>, nodeEntry: NodeEntry<Node>) => {
  if (!Text.isText(nodeEntry[0]) || hasAncestorOfType(editor, EXCLUDED_TYPES, nodeEntry[1])) {
    return false;
  }

  const textEntry = nodeEntry as NodeEntry<Text>;
  const [{ text }, path] = textEntry;
  const startMatch = text.match(NEWLINE_START_REGEX);
  const match = text.match(NEWLINE_REGEX);
  if (!startMatch && !match) {
    return false;
  }

  const offset = startMatch?.index ?? match?.index;
  if (offset == null) {
    return false;
  }

  Transforms.delete(editor, { at: { path, offset }, distance: 2, unit: 'character' });
  Transforms.splitNodes(editor, { at: { path, offset } });

  const newLineOffset = startMatch ? 0 : 1;
  const newLinePath = [path[0] + newLineOffset];
  Transforms.insertNodes(editor, { type: BLOCK_TYPES.TEXT, children: [{ text: '' }] }, { at: newLinePath });

  return true;
};
