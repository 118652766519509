import type { AI_TOOLS } from 'bundles/cml/shared/constants';
import { AI_TOOL } from 'bundles/cml/shared/constants';

// template id mappings for https://tools.coursera.org/epic/experiment/RizMwm-mEe-EQQ6vBDbbLw
export const PROMPTS_MAP: Record<AI_TOOLS, string> = {
  [AI_TOOL.SUMMARIZE]: 'SHORTEN',
  [AI_TOOL.REPHRASE]: 'IMPROVE_CLARITY',
  [AI_TOOL.CONTINUE]: 'EXPAND',
  [AI_TOOL.LIST]: 'BULLETIZE',
  [AI_TOOL.TABLE]: 'CREATE_TABLE',
  [AI_TOOL.PROMPT]: 'USER_PROMPT',
};
