/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback } from 'react';

import type { Editor } from 'slate';
import type { Subscription } from 'zen-observable-ts';

import { ActionOverflow, Button } from '@coursera/cds-core';
import { StopIcon } from '@coursera/cds-icons';

import { useRegenerate } from 'bundles/cml/editor/components/buttons/ai/hooks/useRegenerate';
import AIFeedbackControls from 'bundles/cml/editor/components/elements/ai/AIFeedbackControls';
import {
  cancelSuggestion,
  insertBelowSelection,
  regenerateSuggestion,
  replaceSelection,
} from 'bundles/cml/editor/utils/writingAssistantUtils';
import type { AIElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

const styles = {
  root: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--cds-spacing-150) var(--cds-spacing-200);
    column-gap: var(--cds-spacing-200);
    position: sticky;
    bottom: 0;
    z-index: 1;
    background-color: var(--cds-color-white-0);
    border-top: solid 1px var(--cds-color-grey-50);
  `,
  controls: css`
    display: inline-flex;
    align-items: center;
    column-gap: var(--cds-spacing-200);
  `,
  generating: css`
    justify-content: flex-end;
  `,
  actionOverflow: css`
    margin-left: var(--cds-spacing-50);

    svg {
      transform: rotate(90deg);
    }
  `,
};

type Props = {
  editor: Editor;
  aiEditor: Editor;
  element: AIElement;
  scrollNode: HTMLElement | null;

  onSubscriptionChange: (subscription: Subscription | undefined) => void;
  onStop: () => void;
};

const AIToolbar: React.FC<Props> = ({ editor, aiEditor, element, scrollNode, onSubscriptionChange, onStop }) => {
  const regenerate = useRegenerate(aiEditor, scrollNode);

  const handleCancel = useCallback(() => {
    cancelSuggestion({ editor, aiEditor, element });
  }, [editor, aiEditor, element]);

  const handleReplace = useCallback(() => {
    replaceSelection({ editor, aiEditor, element });
  }, [editor, aiEditor, element]);

  const handleInsertBelow = useCallback(() => {
    insertBelowSelection({ editor, aiEditor, element });
  }, [editor, aiEditor, element]);

  const handleRegenerate = useCallback(() => {
    const subscription = regenerateSuggestion({ editor, aiEditor, element, regenerate });
    onSubscriptionChange(subscription);
  }, [editor, aiEditor, element, regenerate, onSubscriptionChange]);

  const handleMouseDown = (event: React.MouseEvent) => event.preventDefault();

  const { id, generating } = element;

  return (
    <div contentEditable={false} css={styles.root}>
      {generating && (
        <Button
          variant="ghost"
          size="small"
          icon={<StopIcon size="small" />}
          iconPosition="before"
          onClick={onStop}
          onMouseDownCapture={handleMouseDown}
        >
          {_t('Stop')}
        </Button>
      )}
      {!generating && (
        <React.Fragment>
          <AIFeedbackControls id={id} />
          <div>
            <div css={styles.controls}>
              <Button variant="ghost" size="small" onClick={handleCancel} onMouseDownCapture={handleMouseDown}>
                {_t('Cancel')}
              </Button>
              <Button variant="ghost" size="small" onClick={handleRegenerate} onMouseDownCapture={handleMouseDown}>
                {_t('Regenerate')}
              </Button>
              <Button variant="primary" size="small" onClick={handleReplace} onMouseDownCapture={handleMouseDown}>
                {_t('Replace')}
              </Button>
            </div>
            <ActionOverflow size="small" css={styles.actionOverflow}>
              <ActionOverflow.Item onClick={handleInsertBelow}>{_t('Insert below')}</ActionOverflow.Item>
            </ActionOverflow>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default AIToolbar;
