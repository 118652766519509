import type { ProfileInfoField } from 'bundles/ai-coach-platform/components/patterns/chat/settings/types';

import _t from 'i18n!nls/ai-coach-platform';

export const getProfileFieldName = (value: ProfileInfoField) => {
  switch (value) {
    case 'educationHistory':
      return _t('Education');

    case 'location':
      return _t('Location');

    case 'workHistory':
      return _t('Work history');

    default:
      return '';
  }
};
