import * as React from 'react';
import { useRef, useState } from 'react';

import type { BaseEditor } from 'slate';

import { isEmbedPdfInReadingEnabled } from 'bundles/authoring/featureFlags';
import CMLEditor from 'bundles/cml/editor/components/CMLEditor';
import { useCustomTools } from 'bundles/cml/editor/components/hooks/useCustomTools';
import { useSlateEditor } from 'bundles/cml/editor/components/hooks/useSlateEditor';
import AssetContextProvider from 'bundles/cml/editor/context/AssetContextProvider';
import CodeBlockContextProvider from 'bundles/cml/editor/context/CodeBlockContextProvider';
import FillableBlanksContextProvider from 'bundles/cml/editor/context/FillableBlanksContextProvider';
import FocusContextProvider from 'bundles/cml/editor/context/FocusContextProvider';
import ImageContextProvider from 'bundles/cml/editor/context/ImageContextProvider';
import NotificationContextProvider from 'bundles/cml/editor/context/NotificationContextProvider';
import PersonalizationTagContextProvider from 'bundles/cml/editor/context/PersonalizationTagContextProvider';
import ToolsContextProvider from 'bundles/cml/editor/context/ToolsContextProvider';
import WidgetContextProvider from 'bundles/cml/editor/context/WidgetContextProvider';
import { withEventingContext } from 'bundles/cml/editor/context/eventingContext';
import type { Props as EditorProps } from 'bundles/cml/editor/types/cmlEditorProps';
import type { NotificationMessage } from 'bundles/cml/editor/types/notification';
import { useAiWritingAssistantSupported } from 'bundles/cml/editor/utils/writingAssistantUtils';

export type Props = Omit<EditorProps, 'initialCML' | 'onContentChange'> & {
  cmlValue: string;
  onChange: (value: string) => void;
  onMarkdownChange?: (value: string) => void;
  enableEmbeddedPdf?: boolean;
  /**
   * Advanced/dangerous: Override this method to prevent normalizing the editor.
   *
   * See [Slate documentation](https://docs.slatejs.org/api/nodes/editor#shouldnormalize-options-greater-than-boolean) for more information.
   */
  editorShouldNormalize?: BaseEditor['shouldNormalize'];
};

const CMLEditorProvider: React.FC<Props> = (props) => {
  const {
    uploadOptions,
    codeBlockOptions,
    onPersonalizationTagClick,
    isLearnerUpload = false,
    usePagelessDesign: pageless = false,
    enableMonospace: monospace = true,
    children,
    fillableBlankTags = [],
    enableWidgets = false,
    enableMarkdown = false,
    enableEmbeddedPdf = isEmbedPdfInReadingEnabled(),
    enableAiWritingAssistant,
    readOnly,
    customTools,
    editorShouldNormalize,
    ...editorProps
  } = props;

  const writingAssistantSupported = useAiWritingAssistantSupported();
  const [notification, setNotification] = useState<NotificationMessage | undefined>(undefined);
  const { tools, options } = useCustomTools({
    monospace,
    pageless,
    isLearnerUpload,
    customTools,
    enableWidgets,
    enableAiWritingAssistant: enableAiWritingAssistant || writingAssistantSupported,
  });
  const editor = useSlateEditor(setNotification, tools, enableMarkdown);
  if (editorShouldNormalize) {
    editor.shouldNormalize = editorShouldNormalize;
  }
  const staticEditor = useRef(editor);
  const resizable = !readOnly && !pageless;
  const [focused, setFocused] = useState(false);

  return (
    <React.Fragment>
      <FocusContextProvider focused={focused} setFocused={setFocused}>
        <AssetContextProvider
          enableEmbeddedPdf={enableEmbeddedPdf}
          isLearnerUpload={isLearnerUpload}
          pageless={pageless}
          uploadOptions={uploadOptions}
        >
          <ImageContextProvider>
            <CodeBlockContextProvider codeBlockOptions={codeBlockOptions} editor={staticEditor.current}>
              <PersonalizationTagContextProvider onPersonalizationTagClick={onPersonalizationTagClick}>
                <NotificationContextProvider notification={notification} setNotification={setNotification}>
                  <FillableBlanksContextProvider fillableBlankTags={fillableBlankTags}>
                    <ToolsContextProvider tools={tools} options={options}>
                      <WidgetContextProvider enableWidgets={enableWidgets}>
                        <CMLEditor
                          editor={editor}
                          usePagelessDesign={pageless}
                          readOnly={readOnly}
                          resizable={resizable}
                          {...editorProps}
                        />
                      </WidgetContextProvider>
                    </ToolsContextProvider>
                  </FillableBlanksContextProvider>
                </NotificationContextProvider>
              </PersonalizationTagContextProvider>
            </CodeBlockContextProvider>
          </ImageContextProvider>
        </AssetContextProvider>
      </FocusContextProvider>
      {children}
    </React.Fragment>
  );
};

export default React.memo(withEventingContext(CMLEditorProvider));
