import * as React from 'react';
import { useMemo, useState } from 'react';

import WidgetModal from 'bundles/cml/editor/components/WidgetModal';
import { WidgetContext } from 'bundles/cml/editor/context/widgetContext';
import type { WidgetModalOptions } from 'bundles/cml/editor/types/widgetTypes';
import { createWidgetMenuOptions } from 'bundles/cml/editor/utils/widgetApiUtils';
import { createWidgetManager } from 'bundles/cml/shared/utils/WidgetManager';

export type Props = {
  enableWidgets: boolean;
};

const WidgetContextProvider: React.FC<Props> = ({ enableWidgets, children }) => {
  const [widgetModalOptions, setWidgetModalOptions] = useState<WidgetModalOptions | undefined>();

  const widgetContext = useMemo(
    () => ({
      widgetManager: createWidgetManager(),
      setWidgetModalOptions,
      widgetMenuOptions: createWidgetMenuOptions(),
    }),
    []
  );

  return (
    <WidgetContext.Provider value={widgetContext}>
      {children}
      {enableWidgets && (
        <WidgetModal widgetOptions={widgetModalOptions} onClose={() => setWidgetModalOptions(undefined)} />
      )}
    </WidgetContext.Provider>
  );
};

export default WidgetContextProvider;
