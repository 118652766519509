/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useContext, useState } from 'react';

import type { InlineNotificationProps } from '@coursera/cds-core';
import { InlineNotification } from '@coursera/cds-core';

export type NotificationMessage = {
  content: string;
  severity: InlineNotificationProps['severity'];
  isTransient?: boolean;
};

export const TRANSIENT_DURATION_MS = 5000;

type NotificationContextType = {
  addNotification: (message: NotificationMessage) => void;
  clearNotification: () => void;
};
const NotificationContext = React.createContext<NotificationContextType>({
  addNotification: () => undefined,
  clearNotification: () => undefined,
});

const styles = {
  notifications: css`
    position: absolute;
    max-width: 600px;
    z-index: 10000;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 96px;
  `,
};

/** Hook to add an inline notification to any setting page */
export const useSettingsNotification = () => useContext(NotificationContext);

export const SettingsNotificationProvider: React.FC = ({ children }) => {
  const [notification, setNotification] = useState<NotificationMessage | null>(null);

  const clearNotification = useCallback(() => {
    setNotification(null);
  }, []);

  const addNotification = useCallback(
    (notif: NotificationMessage) => {
      setNotification(notif);

      const clearTransientNotification = () => {
        setTimeout(() => {
          clearNotification();
        }, TRANSIENT_DURATION_MS);
      };

      if (notif.isTransient) {
        clearTransientNotification();
      }
    },
    [clearNotification]
  );

  return (
    <NotificationContext.Provider value={{ addNotification, clearNotification }}>
      <div css={styles.notifications} aria-live="polite">
        {notification ? (
          <InlineNotification
            severity={notification.severity}
            onDismiss={notification.isTransient ? undefined : clearNotification}
          >
            {notification.content}
          </InlineNotification>
        ) : null}
      </div>
      {children}
    </NotificationContext.Provider>
  );
};
