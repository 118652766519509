/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useState } from 'react';

import {
  AiAuthoring_WritingAssistantUserSentiment as Sentiment,
  type AiAuthoring_WritingAssistantUserSentiment as SentimentType,
} from '__generated__/graphql-types';

import { IconButton } from '@coursera/cds-core';
import { ThumbsDownFilledIcon, ThumbsDownIcon, ThumbsUpFilledIcon, ThumbsUpIcon } from '@coursera/cds-icons';

import { useSentimentMutation } from 'bundles/cml/editor/api/useSentimtentMutation';
import AIFeedbackDialog from 'bundles/cml/editor/components/elements/ai/AIFeedbackDialog';
import { useNotificationContext } from 'bundles/cml/editor/context/notificationContext';

import _t from 'i18n!nls/cml';

const styles = {
  feedback: css`
    display: inline-flex;
    align-items: center;
  `,
};

type FeedbackInput = {
  sentiment: SentimentType;
  comment?: string;
};

type Props = {
  id?: string;
};

const AIFeedbackControls: React.FC<Props> = ({ id }) => {
  const [open, setOpen] = useState(false);
  const [sentiment, setSentiment] = useState<SentimentType>();
  const { setNotification } = useNotificationContext();
  const sentimentMutation = useSentimentMutation();

  const toggleSentiment = useCallback(
    (value: SentimentType) => {
      if (sentiment === value) {
        setSentiment(undefined);
        setNotification(undefined);
        return true;
      }

      return false;
    },
    [sentiment, setNotification]
  );

  const handleSubmitFeedback = useCallback(
    async (input: FeedbackInput) => {
      if (!id) {
        return;
      }

      setSentiment(input.sentiment);
      setNotification({
        message: _t('Thanks for your feedback!'),
        type: 'success',
      });

      const success = await sentimentMutation({ ...input, id });
      if (!success) {
        setSentiment(undefined);
        setNotification({
          message: _t('There was a problem submitting your feedback. Please try again.'),
          type: 'error',
        });
      }
    },
    [id, setNotification, sentimentMutation]
  );

  const handleCancel = useCallback(() => {
    setOpen(false);
  }, []);

  const handleNegativeFeedback = useCallback(
    (comment?: string) => {
      setOpen(false);
      handleSubmitFeedback({ sentiment: Sentiment.Negative, comment });
    },
    [handleSubmitFeedback]
  );

  const handleLike = useCallback(() => {
    if (toggleSentiment(Sentiment.Positive)) {
      return;
    }

    handleSubmitFeedback({ sentiment: Sentiment.Positive });
  }, [toggleSentiment, handleSubmitFeedback]);

  const handleDislike = useCallback(() => {
    if (toggleSentiment(Sentiment.Negative)) {
      return;
    }

    setOpen(true);
  }, [toggleSentiment]);

  return (
    <div css={styles.feedback}>
      <IconButton
        icon={sentiment === Sentiment.Positive ? <ThumbsUpFilledIcon /> : <ThumbsUpIcon />}
        size="small"
        variant="ghost"
        aria-selected={sentiment === Sentiment.Positive}
        disabled={!id}
        aria-label={_t('Positive feedback')}
        tooltip={_t('Like')}
        onClick={handleLike}
      />
      <IconButton
        icon={sentiment === Sentiment.Negative ? <ThumbsDownFilledIcon /> : <ThumbsDownIcon />}
        size="small"
        variant="ghost"
        aria-selected={sentiment === Sentiment.Negative}
        disabled={!id}
        aria-label={_t('Negative feedback')}
        tooltip={_t('Dislike')}
        onClick={handleDislike}
      />
      <AIFeedbackDialog open={open} onClose={handleCancel} onSubmit={handleNegativeFeedback} />
    </div>
  );
};

export default AIFeedbackControls;
