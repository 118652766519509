/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useContext, useState } from 'react';

import { Transforms } from 'slate';
import { ReactEditor, useReadOnly, useSelected, useSlateStatic } from 'slate-react';
import type { RenderElementProps } from 'slate-react';

import DeleteConfirmationDialog from 'bundles/cml/editor/components/dialogs/DeleteConfirmDialog';
import CMLCodeEditorToolbar from 'bundles/cml/editor/components/elements/code/CMLCodeEditorToolbar';
import CodeMenu from 'bundles/cml/editor/components/elements/code/CodeMenu';
import { useCodeBlockContext } from 'bundles/cml/editor/context/codeBlockContext';
import { useFocusedContext } from 'bundles/cml/editor/context/focusContext';
import { StyleContext } from 'bundles/cml/editor/context/styleContext';
import CodeRenderer from 'bundles/cml/shared/components/code/Code';
import FloatingMenu from 'bundles/cml/shared/components/menu/FloatingMenu';
import type { CodeElement } from 'bundles/cml/shared/types/elementTypes';
import type CodeBlockV2 from 'bundles/code-evaluator/components/CodeBlockV2';

import _t from 'i18n!nls/cml';

const styles = {
  root: css`
    margin-bottom: 20px;

    .rc-CodeBlock {
      margin-bottom: 0;
      border: none !important;
    }
  `,
  hover: css`
    &:hover {
      border-color: transparent;
      outline: 2px solid var(--cds-color-interactive-primary);
    }
  `,
  selected: css`
    border-color: transparent;
    outline: 2px solid var(--cds-color-callouts-tertiary);
  `,
  footer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--cds-color-neutral-stroke-primary-weak);
  `,
};

const Code: React.FC<RenderElementProps> = ({ element, children, attributes }) => {
  const [codeblock, setCodeblock] = useState<CodeBlockV2 | null>(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [ecbModalConfig, toggleEcbModalConfig] = useState<JSX.Element | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { pageless } = useContext(StyleContext);

  const { setFocused } = useFocusedContext();

  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [buttonMenuRef, setButtonMenuRef] = useState<HTMLButtonElement | null>(null);

  const staticEditor = useSlateStatic();

  const code = element as CodeElement;

  const codeBlockId = `codeblock-${code.id}`;

  const selected = useSelected();
  const readonly = useReadOnly();
  const { codeBlockOptions, codeBlockIndices } = useCodeBlockContext();

  const handleEvaluatorChange = useCallback(
    (evaluatorId: string, codeText: string) => {
      codeblock?.monacoEditor?.setValue(codeText);
      Transforms.setNodes(
        staticEditor,
        { ...code, codeText, evaluatorId },
        { at: ReactEditor.findPath(staticEditor, code) }
      );
    },
    [codeblock, staticEditor, code]
  );

  const handleOpenMenu = useCallback(() => {
    if (!readonly) {
      setFocused(true);
      setMenuOpen(true);
    }
  }, [setFocused, readonly]);

  const handleCloseMenu = useCallback(() => {
    setMenuOpen(false);
    setFocused(false);
  }, [setFocused]);

  const handleChange = useCallback(
    (codeText: string) => {
      Transforms.setNodes(staticEditor, { ...code, codeText }, { at: ReactEditor.findPath(staticEditor, code) });
    },
    [code, staticEditor]
  );

  const codeBlockLabel = _t('#{language} code block #{label}', {
    label: code.name || codeBlockIndices?.[code.id],
    language: code.language,
  });

  return (
    <CodeRenderer
      ref={setCodeblock}
      element={element}
      attributes={attributes}
      onChange={handleChange}
      codeBlockId={codeBlockId}
      codeLanguage={code.language}
      ariaLabel={codeBlockLabel}
      displayName={code.name || _t('Untitled')}
      readOnly={readonly}
      hideCodeEvaluator
      css={[styles.root, selected && styles.selected, !readonly && styles.hover]}
    >
      {children}
      <div ref={setRef} css={styles.footer}>
        <CMLCodeEditorToolbar
          ref={setButtonMenuRef}
          code={code}
          codeBlockOptions={codeBlockOptions}
          codeBlockLabel={codeBlockLabel}
          onEvaluatorChange={handleEvaluatorChange}
          toggleEcbConfigModal={toggleEcbModalConfig}
          onMenuOpen={handleOpenMenu}
        />
      </div>
      {menuOpen && (
        <FloatingMenu anchorEl={ref} pageless={pageless} onClose={handleCloseMenu}>
          <CodeMenu anchorEl={buttonMenuRef} element={code} onClose={handleCloseMenu} />
        </FloatingMenu>
      )}
      {deleteConfirm && <DeleteConfirmationDialog editor={staticEditor} setConfirm={setDeleteConfirm} />}
      {ecbModalConfig}
    </CodeRenderer>
  );
};

export default Code;
