/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useContext, useState } from 'react';

import { Typography2 } from '@coursera/cds-core';

import { CoachIconButton } from 'bundles/ai-coach-platform/components/building-blocks';
import { CloseIcon } from 'bundles/ai-coach-platform/components/icons/mui';
import { CoachSettingsContext } from 'bundles/ai-coach-platform/components/patterns/chat/settings/SettingsContextProvider';

import _t from 'i18n!nls/ai-coach-platform';

import SettingsList from './SettingsList';
import SettingsChatHistory from './pages/SettingsChatHistory';
import SettingsProfileInfo from './pages/SettingsProfileInfo';
import { SettingsView } from './types';
import { SettingsNotificationProvider } from './useSettingsNotification';

type Props = {
  onClose: () => void;
};

const styles = {
  root: css`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 11;
    background: var(--cds-color-white-0);
    border-radius: var(--cds-border-radius-200);
  `,
  homeHeader: css`
    display: flex;
    height: 56px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--cds-color-grey-100);
    padding: 0 var(--cds-spacing-200);
    z-index: 1;
  `,
  homeContent: css`
    padding: var(--cds-spacing-200);
    height: calc(100vh - 120px);
    overflow: auto;
  `,
};

/**
 * Top-level controller for Coach Settings UI
 */
export const SettingsHome = (props: Props): React.ReactElement => {
  const { onClose } = props;

  const [currentView, setCurrentView] = useState<SettingsView>(SettingsView.HOME);
  const { data, loading: dataLoading } = useContext(CoachSettingsContext);

  const handleBack = () => {
    setCurrentView(SettingsView.HOME);
  };

  return (
    <SettingsNotificationProvider>
      <div aria-label={_t('Coach settings container')} className="coach-settings-container" css={styles.root}>
        {currentView === 'home' ? (
          <div className="coach-settings-home">
            <div className="home-settings-header" css={styles.homeHeader}>
              <Typography2 variant="subtitleMedium" component="h2">
                {_t('Coach settings')}
              </Typography2>
              <CoachIconButton
                icon={<CloseIcon />}
                tooltip={_t('Close')}
                aria-label={_t('Close settings')}
                onClick={onClose}
              />
            </div>

            <div css={styles.homeContent}>
              <SettingsList loading={dataLoading}>
                <SettingsList.SettingGroup title={_t('General')}>
                  <SettingsList.ListItemButton onClick={() => setCurrentView(SettingsView.CHAT_HISTORY)}>
                    {_t('Chat history')}
                  </SettingsList.ListItemButton>
                </SettingsList.SettingGroup>
                <SettingsList.SettingGroup title={_t('Personalization')}>
                  <SettingsList.ListItemButton
                    onClick={() => setCurrentView(SettingsView.PROFILE_INFO)}
                    suffix={data?.profileInfo?.enabled ? _t('On') : _t('Off')}
                  >
                    {_t('Profile information')}
                  </SettingsList.ListItemButton>
                </SettingsList.SettingGroup>
              </SettingsList>
            </div>
          </div>
        ) : null}

        {currentView === SettingsView.PROFILE_INFO ? <SettingsProfileInfo onBack={handleBack} /> : null}

        {currentView === SettingsView.CHAT_HISTORY ? <SettingsChatHistory onBack={handleBack} /> : null}
      </div>
    </SettingsNotificationProvider>
  );
};

export default SettingsHome;
