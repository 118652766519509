/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { isRightToLeft } from 'js/lib/language';

import { ButtonBase, Divider, Skeleton, Typography2, useId } from '@coursera/cds-core';
import type { ButtonBaseProps } from '@coursera/cds-core';

import { ChevronRightIcon } from 'bundles/ai-coach-platform/components/icons/mui';

import _t from 'i18n!nls/ai-coach-platform';

type SettingsListContextType = {
  loading?: boolean;
};

const SettingsListContext = React.createContext<SettingsListContextType>({
  loading: false,
});

type SettingsListProps = {
  loading?: boolean;
  children: React.ReactNode;
} & React.ComponentPropsWithoutRef<'div'>;

const styles = {
  settingGroup: css`
    margin: var(--cds-spacing-150) 0;

    .group-divider {
      margin: var(--cds-spacing-200) 0;
    }
  `,
  listItemButton: (isRTL: boolean) => css`
    /* CSS overrides */
    --cds-color-interactive-stroke-primary-focus: var(--cds-color-emphasis-primary-background-xxstrong);

    margin-top: var(--cds-spacing-150);
    margin-left: calc(-1 * var(--cds-spacing-100));

    button {
      display: flex;
      width: 100%;
      height: 40px;
      gap: var(--cds-spacing-150);
      align-items: center;
      padding: var(--cds-spacing-150) var(--cds-spacing-100);

      &:hover,
      &:focus {
        background-color: var(--cds-color-emphasis-primary-background-xweak);
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.7;
      }
    }

    .list-button-label {
      flex: 1;
      text-align: start;
    }

    .list-button-suffix {
      color: var(--cds-color-grey-700);
    }

    .list-button-icon {
      display: flex;
      transform: ${isRTL ? 'rotate(180deg)' : 'none'};
    }
  `,
};

type ListButtonProps = {
  children: React.ReactNode;
  icon?: React.ReactNode;
  suffix?: React.ReactNode;
} & ButtonBaseProps;

const ListItemButton = (props: ListButtonProps): React.ReactElement => {
  const { children, disabled = false, onClick, suffix, icon = <ChevronRightIcon size={24} /> } = props;

  const { loading } = React.useContext(SettingsListContext);

  return (
    <div role="listitem" css={styles.listItemButton(isRightToLeft(_t.getLocale()))}>
      <ButtonBase disabled={disabled || loading} type="button" className="settings-list-button" onClick={onClick}>
        <Typography2 className="list-button-label" variant="bodyPrimary" component="div">
          {children}
        </Typography2>

        {suffix ? (
          <Typography2 className="list-button-suffix" variant="bodyPrimary" component="div">
            {loading ? <Skeleton width={24} height={24} /> : suffix}
          </Typography2>
        ) : null}

        <div className="list-button-icon" aria-hidden>
          {icon}
        </div>
      </ButtonBase>
    </div>
  );
};

type SettingGroupProps = {
  title: string;
  children: React.ReactNode;
  isLast?: boolean;
};

const SettingGroup = (props: SettingGroupProps): React.ReactElement => {
  const { title, children, isLast } = props;

  const groupId = useId();

  return (
    <div css={styles.settingGroup} role="group" aria-labelledby={groupId}>
      <div id={groupId}>
        <Typography2 variant="subtitleMedium" component="p">
          {title}
        </Typography2>
      </div>

      {children}
      {!isLast ? <Divider className="group-divider" /> : null}
    </div>
  );
};

/**
 * Container component for the list of Coach settings
 */
const SettingsList = (props: SettingsListProps): React.ReactElement => {
  const { children, loading = false, ...rest } = props;

  return (
    <SettingsListContext.Provider value={{ loading }}>
      <div role="list" aria-label={_t('Coach settings')} className="settings-list-container" {...rest}>
        {children}
      </div>
    </SettingsListContext.Provider>
  );
};

SettingsList.ListItemButton = ListItemButton;
SettingsList.SettingGroup = SettingGroup;

export default SettingsList;
