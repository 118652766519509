import type { Node, NodeEntry } from 'slate';
import { Editor, Text, Transforms } from 'slate';

import { MARKS_TO_TOOLS } from 'bundles/cml/editor/normalize/constants';
import type { MARK_VALUES } from 'bundles/cml/shared/constants';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

const BOLD_MARKDOWN_REGEX = /(\*\*)(.+?)(\*\*)/;
const ITALIC_MARKDOWN_REGEX = /(?:[^\*]|^)(\*)([^\*]+?)(\*)(?:[^\*]|$)/;
const SUBSCRIPT_MARKDOWN_REGEX = /(~)(.+?)(~)/;
const SUPERSCRIPT_MARKDOWN_REGEX = /(\^)(.+?)(\^)/;
const MONOSPACE_MARKDOWN_REGEX = /(?:[^`]|^)(`)([^`]+?)(`)(?:[^`]|$)/;

const MARKDOWN_TO_MARK: Record<string, MARK_VALUES> = {
  '**': 'bold',
  '*': 'italic',
  '~': 'subscript',
  '^': 'superscript',
  '`': 'monospace',
};

export const normalizeMarksMarkdown = (editor: Editor, tools: Set<ToolsKeys>, nodeEntry: NodeEntry<Node>) => {
  if (!Text.isText(nodeEntry[0])) {
    return false;
  }

  const [{ text }, path] = nodeEntry as NodeEntry<Text>;

  const match =
    text.match(BOLD_MARKDOWN_REGEX) ||
    text.match(ITALIC_MARKDOWN_REGEX) ||
    text.match(SUBSCRIPT_MARKDOWN_REGEX) ||
    text.match(SUPERSCRIPT_MARKDOWN_REGEX) ||
    text.match(MONOSPACE_MARKDOWN_REGEX);
  if (!match) {
    return false;
  }

  const markdownMark = match[1];
  const mark = MARKDOWN_TO_MARK[markdownMark];
  if (!mark) {
    return false;
  }

  const tool = MARKS_TO_TOOLS[mark];
  if (!tools.has(tool)) {
    return false;
  }

  const startOffset = text.indexOf(match[1] + match[2] + match[3]);
  const endOffset = startOffset + match[2].length;
  const distance = markdownMark.length;

  Editor.withoutNormalizing(editor, () => {
    Transforms.delete(editor, { at: { path, offset: startOffset }, distance });
    Transforms.delete(editor, { at: { path, offset: endOffset }, distance });
    Transforms.setNodes(
      editor,
      { [mark]: true },
      {
        at: { anchor: { path, offset: startOffset }, focus: { path, offset: endOffset } },
        split: true,
        match: (el) => Text.isText(el),
      }
    );
  });

  return true;
};
