import type {
  EnrollmentChoice_CourseraPlusInfo as CourseraPlusInfo,
  EnrollmentChoice_CourseraTierLiteInfo as CourseraTierLiteInfo,
  EnrollmentBlockingReason,
  EnrollmentChoice,
  EnrollmentChoicePayload,
  EnrollmentChoiceReasonMetadata,
  EnrollmentChoice_EnrollmentChoiceReasonMetadata as EnrollmentChoiceReasonMetadataInner,
  EnrollmentChoice_NotEligibleToPreEnrollMetadata as NotEligibleToPreEnrollMetadata,
  EnrollmentChoice_PreEnrolledMetadata as PreEnrolledMetadata,
  EnrollmentChoice_PreEnrollmentChoice as PreEnrollmentChoice,
  EnrollmentChoice_PrepaidProduct as PrepaidProductOriginal,
  S12nEnrollmentBlockingData,
  EnrollmentChoice_S12nPreEnrollmentInfo as S12nPreEnrollmentInfo,
  EnrollmentChoice_S12nPrepaidInfo as S12nPrepaidInfo,
  EnrollmentChoice_S12nSubscriptionInfo as S12nSubscriptionInfo,
  EnrollmentChoice_SubscriptionProduct as SubscriptionProductOriginal,
  Timestamp,
} from '__generated__/graphql-types';
import {
  EnrollmentChoiceReasonCode,
  EnrollmentChoice_EnrollmentChoiceType as EnrollmentChoiceType,
} from '__generated__/graphql-types';
import { without } from 'lodash';
import uniq from 'lodash/uniq';

import logger from 'js/app/loggerSingleton';
import helpCenter from 'js/lib/courseraHelpcenter';

import { getIsCourseraLiteLandingPageEnabled } from 'bundles/coursera-plus/contentful/constants';
import {
  TIERS_PRICE_TEST_EXCLUDED_PARTNERS,
  TIERS_PRICE_TEST_EXCLUDED_PARTNERS_IN_INDIA,
  getIsPartnerExcludedFromPricingTest,
  getIsPartnerExcludedFromTiersFromStandaloneCoursesTest,
  getIsStandaloneCourseEligibleForTiersEnroll,
  isLiteSideBySideEnrollModalShown,
  isSubscriptionTiersDesignV3Enabled,
  isSubscriptionTiersDesignV3Variant1,
  isSubscriptionTiersEnabled,
  isSubscriptionTiersOptedOut,
  isTiersFromStandaloneCoursesEnabled,
  previewIsIndiaTiersPriceEpicEnabled,
  previewPricingTestVariant,
} from 'bundles/coursera-plus/utils/subscriptionTiersUtils';
import type { EnrollmentChoiceTypesValues } from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import { doesChoiceTypeHaveSubmitHandler } from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import type { PrepaidProduct, SubscriptionProduct } from 'bundles/enroll/types/ProductInfoTypes';
import { previewHideAuditFromCourseInS12n } from 'bundles/enroll/utils/hideAuditUtils';
import paymentsExperiments from 'bundles/epic/clients/payments';
import type CoursesV1 from 'bundles/naptimejs/resources/courses.v1';
import PartnersV1 from 'bundles/naptimejs/resources/partners.v1';
import { COURSERA_PLUS, COURSERA_PLUS_SUBSCRIPTION } from 'bundles/payments/common/ProductType';

import _t from 'i18n!nls/enroll';

const INTL_RESTRICTION_POLICY_URL = 'https://learner.coursera.help/hc/articles/208280116-International-restrictions';
const HELP_CENTER_URL = helpCenter.getNewHelpCenterHome(false);

type EnrollmentChoiceReasonCodeType = (typeof EnrollmentChoiceReasonCode)[keyof typeof EnrollmentChoiceReasonCode];

type EnrollmentChoicePayloadCustomized = EnrollmentChoicePayload & {
  partnerIds?: string[];
  hasPromotion?: boolean;
  course?: CoursesV1;
};

type S12nEnrollmentData = {
  s12nId: string;
  enrollmentChoices?: EnrollmentChoice[];
  enrollmentBlockingReason?: EnrollmentBlockingReason;
};

class EnrollmentChoices {
  enrollmentChoices: EnrollmentChoice[];

  enrollmentBlockingReason?: EnrollmentBlockingReason;

  enrollmentChoiceReasonCode?: EnrollmentChoiceReasonCodeType;

  enrollmentChoiceReasonMetadata?: EnrollmentChoiceReasonMetadata;

  s12nEnrollmentBlockingData?: Array<S12nEnrollmentBlockingData | null>;

  specializationId?: string;

  _partners?: PartnersV1[];

  hasPromotion?: boolean;

  _course?: CoursesV1;

  constructor({
    enrollmentChoices,
    enrollmentChoiceReasonCode,
    enrollmentBlockingReason,
    s12nEnrollmentBlockingData,
    specializationId,
    partnerIds,
    course,
    hasPromotion,
  }: EnrollmentChoicePayloadCustomized) {
    this.enrollmentChoices = enrollmentChoices ?? [];
    this.enrollmentChoiceReasonCode = enrollmentChoiceReasonCode ?? undefined;
    this.enrollmentBlockingReason = enrollmentBlockingReason ?? undefined;
    this.s12nEnrollmentBlockingData = s12nEnrollmentBlockingData ?? undefined;
    this.specializationId = specializationId ?? undefined;
    this._partners = partnerIds?.map((id) => new PartnersV1({ id })) ?? undefined;
    this._course = course;
    this.hasPromotion = hasPromotion;
  }

  // This is used for pre-enrollment mix-and-match case
  // It groups enrollment choices and enrollment choice reason data by s12n
  get _allS12nEnrollmentData(): S12nEnrollmentData[] {
    const allS12nEnrollmentData: S12nEnrollmentData[] = [];

    // Add all s12n subscription, s12n prepaid, s12n pre-enrollment, and Coursera Plus choices first
    this.enrollmentChoices?.forEach((enrollmentChoice) => {
      const enrollmentChoiceType = enrollmentChoice.enrollmentChoiceType;
      let s12nId: string | undefined;
      if (
        enrollmentChoiceType === EnrollmentChoiceType.EnrollThroughS12NSubscription ||
        enrollmentChoiceType === EnrollmentChoiceType.EnrollThroughS12NSubscriptionTrial
      ) {
        s12nId = (enrollmentChoice?.enrollmentChoiceInfo as S12nSubscriptionInfo)?.s12nId;
      } else if (enrollmentChoiceType === EnrollmentChoiceType.EnrollThroughS12NPrepaid) {
        s12nId = (enrollmentChoice?.enrollmentChoiceInfo as S12nPrepaidInfo)?.s12nId;
      } else if (enrollmentChoiceType === EnrollmentChoiceType.PreEnrollmentEligible) {
        s12nId = (enrollmentChoice?.preEnrollmentChoiceInfo as S12nPreEnrollmentInfo)?.s12nId;
      } else if (enrollmentChoiceType === EnrollmentChoiceType.EnrollThroughCourseraPlus) {
        s12nId = (enrollmentChoice?.enrollmentChoiceInfo as CourseraPlusInfo)?.s12nEnrollmentChoiceInfo?.s12nId;
      }

      if (s12nId) {
        const index = allS12nEnrollmentData.findIndex(
          (s12nEnrollmentData) =>
            (s12nEnrollmentData as S12nSubscriptionInfo | S12nPrepaidInfo | S12nPreEnrollmentInfo).s12nId === s12nId
        );
        if (index > -1) {
          allS12nEnrollmentData[index].enrollmentChoices?.push(enrollmentChoice);
        } else {
          allS12nEnrollmentData.push({
            s12nId,
            enrollmentChoices: [enrollmentChoice],
          });
        }
      }
    });

    // Add all enrollment choice reason metadata
    this.s12nEnrollmentBlockingData?.forEach((s12nBlockingData) => {
      if (!s12nBlockingData) {
        return;
      }

      const { specializationId, enrollmentBlockingReason } = s12nBlockingData;

      if (specializationId && enrollmentBlockingReason) {
        const index = allS12nEnrollmentData.findIndex(
          (s12nEnrollmentData) => s12nEnrollmentData.s12nId === specializationId
        );
        if (index > -1) {
          allS12nEnrollmentData[index].enrollmentBlockingReason = enrollmentBlockingReason;
        } else {
          allS12nEnrollmentData.push({
            s12nId: specializationId,
            enrollmentBlockingReason,
          });
        }
      }
    });

    return allS12nEnrollmentData;
  }

  get _allS12nSubscriptionEnrollmentChoiceInfo(): S12nSubscriptionInfo[] {
    const previewTiersV3VariantInput = {
      partners: this._partners,
      hasSpecialization: Boolean(this.specializationId),
      isStandaloneCourseEligibleForTiersEnroll: this.isStandaloneCourseEligibleForTiersEnroll,
    };
    if (
      this.canEnrollThroughS12nSubscription ||
      this.canEnrollThroughS12nSubscriptionFreeTrial ||
      (isSubscriptionTiersEnabled(previewTiersV3VariantInput) && this.canSubscribeToCourseraPlus)
    ) {
      return this._s12nSubscriptionEnrollmentChoices
        .map(({ enrollmentChoiceInfo }) => enrollmentChoiceInfo as S12nSubscriptionInfo)
        .filter(Boolean);
    }
    return [];
  }

  get _allCourseraPlusSubscriptionEnrollmentChoiceInfo(): CourseraPlusInfo[] {
    if (this.canSubscribeToCourseraPlus) {
      return this._courseraPlusSubscriptionEnrollmentChoices
        .map(({ enrollmentChoiceInfo }) => enrollmentChoiceInfo as CourseraPlusInfo)
        .filter(Boolean);
    }

    return [];
  }

  get _courseraPlusSubscriptionEnrollmentChoices(): EnrollmentChoice[] {
    return this.enrollmentChoices.filter(
      (choice) =>
        choice.enrollmentChoiceType === EnrollmentChoiceType.SubscribeToCourseraPlus &&
        // We're specifically checking for COURSERA_PLUS_SUBSCRIPTION since COURSERA_PLUS is for prepaid
        (choice.enrollmentChoiceInfo as CourseraPlusInfo)?.courseraPlusPermutationToDisplay?.productId?.productType ===
          COURSERA_PLUS_SUBSCRIPTION
    );
  }

  get _courseraPlusPrepaidEnrollmentChoices(): EnrollmentChoice[] {
    return this.enrollmentChoices.filter(
      (choice) =>
        choice.enrollmentChoiceType === EnrollmentChoiceType.SubscribeToCourseraPlus &&
        // COURSERA_PLUS productType is for prepaid, COURSERA_PLUS_SUBSCRIPTION productType is for subscription
        (choice.enrollmentChoiceInfo as CourseraPlusInfo)?.courseraPlusPermutationToDisplay?.productId?.productType ===
          COURSERA_PLUS
    );
  }

  get _preEnrollmentEnrollmentChoices(): EnrollmentChoice[] {
    return this.enrollmentChoices.filter(
      (choice) => choice.enrollmentChoiceType === EnrollmentChoiceType.PreEnrollmentEligible
    );
  }

  get _s12nPrepaidEnrollmentChoices(): EnrollmentChoice[] {
    return this.enrollmentChoices.filter(
      (choice) => choice.enrollmentChoiceType === EnrollmentChoiceType.EnrollThroughS12NPrepaid
    );
  }

  get _s12nSubscriptionEnrollmentChoices(): EnrollmentChoice[] {
    return this.enrollmentChoices.filter(
      (choice) =>
        choice.enrollmentChoiceType === EnrollmentChoiceType.EnrollThroughS12NSubscription ||
        choice.enrollmentChoiceType === EnrollmentChoiceType.EnrollThroughS12NSubscriptionTrial
    );
  }

  get _shouldHideAuditAndFreeEnroll(): boolean {
    const previewTiersV3VariantInput = {
      partners: this._partners,
      hasSpecialization: Boolean(this.specializationId),
      isStandaloneCourseEligibleForTiersEnroll: this.isStandaloneCourseEligibleForTiersEnroll,
    };
    let listOfExcludedPartners = TIERS_PRICE_TEST_EXCLUDED_PARTNERS;
    if (previewIsIndiaTiersPriceEpicEnabled()) {
      listOfExcludedPartners = TIERS_PRICE_TEST_EXCLUDED_PARTNERS_IN_INDIA;
    }

    // standalone courses only show on the enabled variant
    // isSubscriptionTiersDesignV3Enabled is necessary to check partner exclusion
    const isSingleCourseWithTiersEnrollModalEnabled =
      this.canSubscribeToCourseraPlus &&
      this.isStandaloneCourseEligibleForTiersEnroll &&
      isSubscriptionTiersDesignV3Enabled(previewTiersV3VariantInput) &&
      isTiersFromStandaloneCoursesEnabled();

    const isSingleCourseInTiersV3OrLitePage =
      this.canPurchaseSingleCourse &&
      (isSubscriptionTiersDesignV3Enabled(previewTiersV3VariantInput) || getIsCourseraLiteLandingPageEnabled());

    const isPricingTestVariant3WithPartnerExcluded =
      previewPricingTestVariant(previewTiersV3VariantInput) === '3' &&
      (getIsPartnerExcludedFromPricingTest(listOfExcludedPartners, this._partners) ||
        (isTiersFromStandaloneCoursesEnabled() &&
          getIsPartnerExcludedFromTiersFromStandaloneCoursesTest(this._partners)));

    // If the user can enroll through Plus, and thus doesn't need audit / free enroll, hide it.
    // If tiers enroll modal is enabled on single course, hide audit
    if (this.canEnrollThroughCourseraPlus || isSingleCourseWithTiersEnrollModalEnabled) {
      return true;
    }

    if (isSubscriptionTiersOptedOut() && this.canEnrollThroughS12nSubscriptionFreeTrial) {
      return previewHideAuditFromCourseInS12n(this._partners);
    }

    // If the user is in Subscription Tiers and the content is part of Plus, then with the following exceptions, hide audit and free enroll:
    //    - Standalone courses in Tiers Design v3
    //    - Standalone courses in Lite Landing page test
    //    - all content for partners excluded from pricing test
    return (
      isSubscriptionTiersEnabled(previewTiersV3VariantInput) &&
      !isSingleCourseInTiersV3OrLitePage &&
      !isPricingTestVariant3WithPartnerExcluded &&
      this.canSubscribeToCourseraPlus
    );
  }

  _canEnrollThroughChoice = (enrollmentChoiceType: EnrollmentChoiceType): boolean => {
    return this.enrollmentChoices.some(
      (enrollmentChoice) => enrollmentChoice.enrollmentChoiceType === enrollmentChoiceType
    );
  };

  _getEnrollmentBlockingReasonMetadata(
    reasonCode: EnrollmentChoiceReasonCodeType,
    s12nId?: string
  ): EnrollmentChoiceReasonMetadataInner | undefined {
    return (
      (this.s12nEnrollmentBlockingData?.find(
        (s12nEnrollmentBlockingData) =>
          s12nEnrollmentBlockingData?.specializationId === s12nId &&
          s12nEnrollmentBlockingData?.enrollmentBlockingReason?.enrollmentChoiceReasonCode === reasonCode
      )?.enrollmentBlockingReason?.enrollmentChoiceReasonMetadata?.enrollmentChoiceReasonMetadata ||
        this.enrollmentBlockingReason?.enrollmentChoiceReasonMetadata?.enrollmentChoiceReasonMetadata ||
        this.enrollmentChoiceReasonMetadata?.enrollmentChoiceReasonMetadata) ??
      undefined
    );
  }

  _getNotEligibleToPreEnrollReasonMetadata(s12nId?: string): NotEligibleToPreEnrollMetadata {
    const notEligibleToPreEnrollMetadata = this._getEnrollmentBlockingReasonMetadata(
      EnrollmentChoiceReasonCode.NotEligibleToPreEnroll,
      s12nId
    ) as NotEligibleToPreEnrollMetadata;
    return notEligibleToPreEnrollMetadata;
  }

  _getS12nPreEnrollmentEnrollmentData(s12nId?: string): S12nPreEnrollmentInfo | undefined {
    return (
      this._preEnrollmentEnrollmentChoices.find(
        (preEnrollmentChoice) => preEnrollmentChoice?.preEnrollmentChoiceInfo?.s12nId === s12nId
      )?.preEnrollmentChoiceInfo ?? undefined
    );
  }

  _hasReasonCode = (reasonCode: EnrollmentChoiceReasonCodeType): boolean => {
    return Boolean(
      this.enrollmentChoiceReasonCode === reasonCode ||
        this.enrollmentBlockingReason?.enrollmentChoiceReasonCode === reasonCode ||
        this.s12nEnrollmentBlockingData?.some(
          (s12nEnrollmentBlockingData) =>
            s12nEnrollmentBlockingData?.enrollmentBlockingReason?.enrollmentChoiceReasonCode === reasonCode
        )
    );
  };

  // Checks if an s12n is pre enroll eligible or pre enrolled
  _canPreEnrollOrIsPreEnrolledS12n = (s12nEnrollmentData: S12nEnrollmentData): boolean => {
    const { enrollmentBlockingReason, enrollmentChoices } = s12nEnrollmentData;
    const isPreEnrolled =
      enrollmentBlockingReason?.enrollmentChoiceReasonCode === EnrollmentChoiceReasonCode.PreEnrolled;

    const canPreEnroll = enrollmentChoices
      ?.map(({ enrollmentChoiceType }) => enrollmentChoiceType)
      .includes(EnrollmentChoiceType.PreEnrollmentEligible);
    return isPreEnrolled || canPreEnroll || false;
  };

  // Checks if an s12n is pre enroll enabled, which includes pre enroll eligible, pre enrolled and not eligible for pre enrollment
  _isS12nPreEnrollEnabled = (s12nEnrollmentData: S12nEnrollmentData): boolean => {
    const { enrollmentBlockingReason } = s12nEnrollmentData;
    const notEligibleToPreEnroll =
      enrollmentBlockingReason?.enrollmentChoiceReasonCode === EnrollmentChoiceReasonCode.NotEligibleToPreEnroll;
    return this._canPreEnrollOrIsPreEnrolledS12n(s12nEnrollmentData) || notEligibleToPreEnroll;
  };

  _timestampToDate = (timestamp?: Timestamp): Date | undefined => {
    if (!timestamp) {
      return undefined;
    }
    const seconds = Number(timestamp.seconds);
    return new Date(seconds * 1000);
  };

  _toAvailablePrepaidProducts = ({ productProperties, productId }: PrepaidProductOriginal): PrepaidProduct => ({
    productProperties: {
      ownershipDays: productProperties.ownershipDays,
      refundDays: productProperties.refundDays,
    },
    productItemId: productId.productItemId,
    productType: productId.productType,
  });

  _toAvailableSubscriptions = ({ productProperties, productId }: SubscriptionProductOriginal): SubscriptionProduct => ({
    productProperties: {
      status: 'ENABLED',
      billingCycle: productProperties.billingCycle ?? '',
      ...(productProperties.numberOfCycles ? { numberOfCycles: productProperties.numberOfCycles } : {}),
      numberOfTrialDays: productProperties.numberOfTrialDays,
    },
    productItemId: productId.productItemId,
    productType: productId.productType,
  });

  get choiceTypes(): EnrollmentChoiceTypesValues[] {
    return this.enrollmentChoices
      .map(({ enrollmentChoiceType }) => enrollmentChoiceType)
      .filter((choiceType) => {
        if (
          (choiceType === EnrollmentChoiceType.AuditCourse && !this.canAuditCourse) ||
          (choiceType === EnrollmentChoiceType.EnrollCourse && !this.hasFreeEnrollOptionIntoCourse)
        ) {
          return false;
        } else if (doesChoiceTypeHaveSubmitHandler(choiceType)) {
          return true;
        } else {
          logger.error(`Unrecognized enrollment choice type ${choiceType}`);
          return false;
        }
      });
  }

  get hasChoice(): boolean {
    return this.enrollmentChoices.length > 0;
  }

  get humanReadableReasonCode(): string {
    switch (this.enrollmentChoiceReasonCode) {
      case EnrollmentChoiceReasonCode.Enrolled:
        return _t('You are already enrolled');
      case EnrollmentChoiceReasonCode.PurchasedSingleCourse:
        // eslint-disable-next-line no-restricted-syntax
        return _t('You already purchased the course');
      case EnrollmentChoiceReasonCode.CapstoneAccessLocked:
        return _t(
          `You must first complete previous courses in the Specialization
          to be able to take the Capstone project`
        );
      case EnrollmentChoiceReasonCode.SpecializationBulkPaid:
        return _t('You already purchased the Specialization');
      case EnrollmentChoiceReasonCode.SpecializationSubscribed:
        return _t('You are already subscribed to the Specialization');
      case EnrollmentChoiceReasonCode.RegionBlocked:
        return _t(
          `We apologize for the inconvenience. This offering is currently unavailable in your region.
          You can find more information about the policy at #{policyUrl}`,
          { policyUrl: INTL_RESTRICTION_POLICY_URL }
        );
      case EnrollmentChoiceReasonCode.NoAvailableSession:
        return _t('There are no sessions available right now.');
      case EnrollmentChoiceReasonCode.SpecializationUpgradeRequired:
        return _t(
          `You are currently enrolled in an old version of the specialization.
          Upgrade the specialization to continue with your purchase.`
        );
      default:
        return _t(
          `We could not find any enrollment option for you at this time.
          For more information, please visit our help center (#{helpCenterUrl})`,
          { helpCenterUrl: HELP_CENTER_URL }
        );
    }
  }

  get isEligibleForVCFinaid(): boolean {
    const isIneligibleReasonCode = (
      [
        EnrollmentChoiceReasonCode.CapstoneAccessLocked,
        EnrollmentChoiceReasonCode.PurchasedSingleCourse,
        EnrollmentChoiceReasonCode.SpecializationBulkPaid,
        EnrollmentChoiceReasonCode.RegionBlocked,
      ] as string[]
    ).includes(this.enrollmentChoiceReasonCode ?? '');

    if (isIneligibleReasonCode) {
      return false;
    }

    return (
      this.canPurchaseSingleCourse ||
      this.canSubscribeToS12n ||
      this.canEnrollThroughS12nPrepaid ||
      this.canSubscribeToCourseraPlus ||
      this.canSubscribeToCourseraLite
    );
  }

  // More generalized derived field that counts s12n subscription, s12n prepaid, s12n pre enrollment and s12n pre enrolled
  // to see if this course is mix-and-match (part of multiple s12ns)
  get isMixAndMatch() {
    return this.enrollmentS12nIds.length > 1;
  }

  get canAuditCourse(): boolean {
    if (this._shouldHideAuditAndFreeEnroll) {
      return false;
    }

    return this._canEnrollThroughChoice(EnrollmentChoiceType.AuditCourse);
  }

  get canBulkPaySpecialization(): boolean {
    return [
      EnrollmentChoiceType.BulkpayFullSpecialization,
      EnrollmentChoiceType.BulkpayRemainingSpecializationCourses,
    ].some(this._canEnrollThroughChoice);
  }

  get canEnrollCourseWithFullDiscount(): boolean {
    return this._canEnrollThroughChoice(EnrollmentChoiceType.EnrollCourseWithFullDiscount);
  }

  get canEnrollThroughCourseraPlus(): boolean {
    return this._canEnrollThroughChoice(EnrollmentChoiceType.EnrollThroughCourseraPlus);
  }

  get canEnrollThroughGroup(): boolean {
    return this._canEnrollThroughChoice(EnrollmentChoiceType.EnrollThroughGroup);
  }

  get canEnrollThroughProgram(): boolean {
    return this._canEnrollThroughChoice(EnrollmentChoiceType.EnrollThroughProgram);
  }

  get canEnrollThroughProgramInvitation(): boolean {
    return this._canEnrollThroughChoice(EnrollmentChoiceType.EnrollThroughProgramInvitation);
  }

  get canEnrollThroughS12nPrepaid(): boolean {
    return this._canEnrollThroughChoice(EnrollmentChoiceType.EnrollThroughS12NPrepaid);
  }

  get canEnrollThroughS12nSubscription(): boolean {
    if (this.shouldDisableS12nSubscription) {
      return false;
    }

    return this._canEnrollThroughChoice(EnrollmentChoiceType.EnrollThroughS12NSubscription);
  }

  get canEnrollThroughS12nSubscriptionFreeTrial(): boolean {
    if (this.shouldDisableS12nSubscription) {
      return false;
    }

    return this._canEnrollThroughChoice(EnrollmentChoiceType.EnrollThroughS12NSubscriptionTrial);
  }

  get canEnrollWithFreeTrial(): boolean {
    return (
      this.canSubscribeToCourseraLiteFreeTrial ||
      this.canSubscribeToCourseraPlusFreeTrial ||
      this.canEnrollThroughS12nSubscriptionFreeTrial
    );
  }

  get hasOnlyPreEnrollEnabledS12ns(): boolean {
    return this._allS12nEnrollmentData.every(this._isS12nPreEnrollEnabled);
  }

  get canOnlyPreEnrollOrIsPreEnrolled(): boolean {
    return this._allS12nEnrollmentData.every(this._canPreEnrollOrIsPreEnrolledS12n);
  }

  get canPreEnroll(): boolean {
    return this._canEnrollThroughChoice(EnrollmentChoiceType.PreEnrollmentEligible);
  }

  get canPurchaseSingleCourse(): boolean {
    return this._canEnrollThroughChoice(EnrollmentChoiceType.PurchaseSingleCourse);
  }

  get isStandaloneCourseEligibleForTiersEnroll(): boolean {
    // TODO (mawa): Clean up getIsStandaloneCourseEligibleForTiersEnroll
    // util function in favor of this EAC method instead
    return !!getIsStandaloneCourseEligibleForTiersEnroll({
      hasSpecialization: !!this.specializationId,
      isClosedCourse: !!this._course?.isClosedCourse,
      enrollmentAvailableChoices: this,
    });
  }

  get canPurchaseThroughCourseraPlus(): boolean {
    return this._courseraPlusPrepaidEnrollmentChoices.length > 0;
  }

  get canSubscribeToCourseraLite(): boolean {
    return this._canEnrollThroughChoice(EnrollmentChoiceType.PurchaseCourseraTierLite);
  }

  get canSubscribeToCourseraLiteFreeTrial(): boolean {
    const courseraLiteChoice = this.enrollmentChoices.find(
      (choice) => choice.enrollmentChoiceType === EnrollmentChoiceType.PurchaseCourseraTierLite
    );

    if (!courseraLiteChoice) {
      return false;
    }

    const enrollmentChoiceInfo = courseraLiteChoice.enrollmentChoiceInfo as CourseraTierLiteInfo;

    return !enrollmentChoiceInfo.hasExhaustedFreeTrial;
  }

  get canSubscribeToCourseraPlus(): boolean {
    return this.enrollmentChoices.some(
      (choice) =>
        choice.enrollmentChoiceType === EnrollmentChoiceType.SubscribeToCourseraPlus &&
        (choice.enrollmentChoiceInfo as CourseraPlusInfo)?.courseraPlusPermutationToDisplay?.productId?.productType ===
          COURSERA_PLUS_SUBSCRIPTION
    );
  }

  get canSubscribeToCourseraPlusFreeTrial(): boolean {
    const previewTiersV3VariantInput = {
      partners: this._partners,
      hasSpecialization: Boolean(this.specializationId),
      isStandaloneCourseEligibleForTiersEnroll: this.isStandaloneCourseEligibleForTiersEnroll,
    };

    const isStandaloneWithTiersEnrollModalEnabled =
      this.isStandaloneCourseEligibleForTiersEnroll &&
      isSubscriptionTiersDesignV3Enabled(previewTiersV3VariantInput) &&
      isTiersFromStandaloneCoursesEnabled();

    // Plus free trial is not shown if s12n subscription or course purchase is available
    if (
      !this.shouldDisableS12nSubscription ||
      (this.canPurchaseSingleCourse && !isStandaloneWithTiersEnrollModalEnabled)
    ) {
      return false;
    }

    const courseraPlusChoice = this.enrollmentChoices.find(
      (choice) =>
        choice.enrollmentChoiceType === EnrollmentChoiceType.SubscribeToCourseraPlus &&
        (choice.enrollmentChoiceInfo as CourseraPlusInfo)?.courseraPlusPermutationToDisplay?.productId?.productType ===
          COURSERA_PLUS_SUBSCRIPTION
    );

    if (!courseraPlusChoice) {
      return false;
    }

    const enrollmentChoiceInfo = courseraPlusChoice.enrollmentChoiceInfo as CourseraPlusInfo;

    return !enrollmentChoiceInfo.hasExhaustedFreeTrial;
  }

  get canSubscribeToS12n(): boolean {
    return this.canEnrollThroughS12nSubscription || this.canEnrollThroughS12nSubscriptionFreeTrial;
  }

  get hasFreeEnrollOptionIntoCourse(): boolean {
    if (this._shouldHideAuditAndFreeEnroll) {
      return false;
    }

    return this._canEnrollThroughChoice(EnrollmentChoiceType.EnrollCourse);
  }

  // If the Coursera Lite side by side enroll modal or Tiers Design v3 not variant 1 is shown,
  // we disregard the s12n subscription option and only allow C+ enrollment
  get shouldDisableS12nSubscription(): boolean {
    const previewTiersV3VariantInput = {
      partners: this._partners,
      hasSpecialization: Boolean(this.specializationId),
      isStandaloneCourseEligibleForTiersEnroll: this.isStandaloneCourseEligibleForTiersEnroll,
    };
    return (
      this.canSubscribeToCourseraPlus &&
      !this.hasPromotion &&
      (isLiteSideBySideEnrollModalShown() ||
        (isSubscriptionTiersDesignV3Enabled(previewTiersV3VariantInput) && !isSubscriptionTiersDesignV3Variant1()))
    );
  }

  get didPurchase(): boolean {
    return [
      EnrollmentChoiceReasonCode.PurchasedSingleCourse,
      EnrollmentChoiceReasonCode.SpecializationBulkPaid,
      EnrollmentChoiceReasonCode.SpecializationSubscribed,
    ].some(this._hasReasonCode);
  }

  get hasEarnedS12nCertificate(): boolean {
    return this._hasReasonCode(EnrollmentChoiceReasonCode.EarnedS12NCertificate);
  }

  get isCapstoneAccessLocked() {
    return this._hasReasonCode(EnrollmentChoiceReasonCode.CapstoneAccessLocked);
  }

  get isEnrolled() {
    return this._hasReasonCode(EnrollmentChoiceReasonCode.Enrolled);
  }

  // This checks if the current product or for mix-and-match if there are any s12n which is not eligible to pre enroll
  get isNotEligibleToPreEnroll() {
    return this._hasReasonCode(EnrollmentChoiceReasonCode.NotEligibleToPreEnroll);
  }

  // This checks if the current product or for mix-and-match if there are any s12n which is pre-enrolled
  get isPreEnrolled() {
    return this._hasReasonCode(EnrollmentChoiceReasonCode.PreEnrolled);
  }

  get isSpecializationEnrolledThroughCourseraPlus() {
    return this._hasReasonCode(EnrollmentChoiceReasonCode.SpecializationEnrolledThroughCourseraPlus);
  }

  get isSpecializationSubscribed() {
    return this._hasReasonCode(EnrollmentChoiceReasonCode.SpecializationSubscribed);
  }

  get isSpecializationUpgradeRequired() {
    return this._hasReasonCode(EnrollmentChoiceReasonCode.SpecializationUpgradeRequired);
  }

  get availableS12nSubscriptions(): SubscriptionProduct[] | undefined {
    const enrollmentChoiceInfo = this._allS12nSubscriptionEnrollmentChoiceInfo[0];

    if (enrollmentChoiceInfo) {
      return enrollmentChoiceInfo.availableSubscriptionProducts.map(this._toAvailableSubscriptions);
    }

    return undefined;
  }

  get courseraPlusPrepaidEnrollmentS12nIds(): string[] {
    if (this.canPurchaseThroughCourseraPlus) {
      return this._courseraPlusPrepaidEnrollmentChoices
        .map(
          (enrollmentChoice) =>
            (enrollmentChoice.enrollmentChoiceInfo as CourseraPlusInfo)?.s12nEnrollmentChoiceInfo?.s12nId ?? ''
        )
        .filter(Boolean);
    }
    return [];
  }

  get enrollmentS12nIds(): string[] {
    const regularEnrollmentS12nIds = uniq([...this.prepaidEnrollmentS12nIds, ...this.subscriptionEnrollmentS12nIds]);

    // When pre-enrollment is disabled for the learner, we should only show s12ns that aren't pre-enrollment eligible
    // and all the pre-enrolled s12ns (in case somehow they were pre-enrolled into it).
    return paymentsExperiments.preview('disablePreEnrollment')
      ? uniq([...without(regularEnrollmentS12nIds, ...this.preEnrollEligibleS12nIds), ...this.preEnrolledS12nIds])
      : uniq([...regularEnrollmentS12nIds, ...this.preEnrollEligibleS12nIds, ...this.preEnrolledS12nIds]);
  }

  get preEnrolledS12nIds(): string[] {
    return this._allS12nEnrollmentData
      .filter(
        ({ enrollmentBlockingReason }) =>
          enrollmentBlockingReason?.enrollmentChoiceReasonCode === EnrollmentChoiceReasonCode.PreEnrolled
      )
      .map(({ s12nId }) => s12nId);
  }

  get preEnrollEligibleS12nIds(): string[] {
    if (this.canPreEnroll) {
      return this._preEnrollmentEnrollmentChoices
        .map((enrollmentChoice) => (enrollmentChoice?.preEnrollmentChoiceInfo as PreEnrollmentChoice)?.s12nId)
        .filter(Boolean);
    }
    return [];
  }

  get prepaidEnrollmentS12nIds(): string[] {
    if (this.canEnrollThroughS12nPrepaid) {
      return this._s12nPrepaidEnrollmentChoices
        .map((enrollmentChoice) => (enrollmentChoice.enrollmentChoiceInfo as S12nPrepaidInfo)?.s12nId)
        .filter(Boolean);
    }
    return [];
  }

  get subscriptionEnrollmentS12nIds(): string[] {
    const idsFromS12nChoiceInfo = this._allS12nSubscriptionEnrollmentChoiceInfo
      .map((enrollmentChoiceInfo) => enrollmentChoiceInfo?.s12nId)
      .filter(Boolean);

    const idsFromCourseraPlusChoiceInfo = this._allCourseraPlusSubscriptionEnrollmentChoiceInfo
      .map((enrollmentChoiceInfo) => enrollmentChoiceInfo.s12nEnrollmentChoiceInfo?.s12nId || '')
      .filter(Boolean);

    return uniq([...idsFromS12nChoiceInfo, ...idsFromCourseraPlusChoiceInfo]);
  }

  get selectedS12nIdOverrideToEnrollThroughCourseraPlus() {
    if (!this.isMixAndMatch) {
      return undefined;
    }

    let s12nId: string | undefined;
    const enrollThroughCourseraPlusChoices = this.enrollmentChoices.filter(
      (choice) => choice.enrollmentChoiceType === EnrollmentChoiceType.EnrollThroughCourseraPlus
    );

    // If only one s12n is eligible to enroll through Plus, we should
    // automatically enroll the user without showing them the s12n select modal.
    // If multiple s12ns are eligible, the user needs to select a s12n
    if (enrollThroughCourseraPlusChoices?.length === 1) {
      s12nId = (enrollThroughCourseraPlusChoices?.[0]?.enrollmentChoiceInfo as CourseraPlusInfo)
        .s12nEnrollmentChoiceInfo?.s12nId;
    } else {
      s12nId = undefined;
    }

    return s12nId;
  }

  get courseraPlusAndS12nEnrollmentChoicesForMixAndMatch() {
    if (!this.isMixAndMatch) {
      return { canEnrollThroughS12nChoices: [], canEnrollThroughCourseraPlusChoices: [] };
    }

    const canEnrollThroughS12nChoices: EnrollmentChoice[] = [];
    const canEnrollThroughCourseraPlusChoices: EnrollmentChoice[] = [];

    this._allS12nEnrollmentData.forEach((enrollmentData) => {
      const canEnrollThroughCourseraPlusChoice = enrollmentData?.enrollmentChoices?.find(
        (choice) => choice.enrollmentChoiceType === EnrollmentChoiceType.EnrollThroughCourseraPlus
      );
      const canEnrollThroughS12nChoice = enrollmentData?.enrollmentChoices?.find(
        (choice) =>
          choice.enrollmentChoiceType === EnrollmentChoiceType.EnrollThroughS12NSubscription ||
          choice.enrollmentChoiceType === EnrollmentChoiceType.EnrollThroughS12NSubscriptionTrial ||
          choice.enrollmentChoiceType === EnrollmentChoiceType.EnrollThroughS12NPrepaid
      );

      if (canEnrollThroughCourseraPlusChoice) {
        canEnrollThroughCourseraPlusChoices.push(canEnrollThroughCourseraPlusChoice);
      } else if (canEnrollThroughS12nChoice && !canEnrollThroughCourseraPlusChoice) {
        // track number of subscriptions that cannot be enrolled in through Coursera Plus
        canEnrollThroughS12nChoices.push(canEnrollThroughS12nChoice);
      }
    });

    return { canEnrollThroughS12nChoices, canEnrollThroughCourseraPlusChoices };
  }

  get isMixMatchWithS12nsThatCannotEnrollThroughPlus() {
    const { canEnrollThroughS12nChoices = [], canEnrollThroughCourseraPlusChoices = [] } =
      this.courseraPlusAndS12nEnrollmentChoicesForMixAndMatch;

    // In addition to checking for s12ns not eligible to enroll through Coursera Plus,
    // we are also making sure there are more 1 Coursera Plus choices
    // because if it's exactly 1, we automatically enroll the learner in that s12n.
    return canEnrollThroughS12nChoices.length > 0 && canEnrollThroughCourseraPlusChoices.length > 1;
  }

  canEnrollThroughPlusWithSelectedS12n = (selectedS12nId: string): boolean => {
    const { canEnrollThroughCourseraPlusChoices = [] } = this.courseraPlusAndS12nEnrollmentChoicesForMixAndMatch;

    return canEnrollThroughCourseraPlusChoices?.some(
      (choice) =>
        (choice?.enrollmentChoiceInfo as CourseraPlusInfo)?.s12nEnrollmentChoiceInfo?.s12nId === selectedS12nId
    );
  };

  canPreEnrollOrIsPreEnrolledS12n(selectedS12nId: string): boolean {
    const s12nEnrollmentData = this._allS12nEnrollmentData?.find(({ s12nId }) => s12nId === selectedS12nId);
    return s12nEnrollmentData ? this._canPreEnrollOrIsPreEnrolledS12n(s12nEnrollmentData) : false;
  }

  isPreEnrollEnabledS12n(selectedS12nId: string): boolean {
    const s12nEnrollmentData = this._allS12nEnrollmentData?.find(({ s12nId }) => s12nId === selectedS12nId);
    return s12nEnrollmentData ? this._isS12nPreEnrollEnabled(s12nEnrollmentData) : false;
  }

  getCanEnrollThroughS12nSubscription = (enrollmentChoice: { enrollmentChoiceType: string }): boolean => {
    if (this.shouldDisableS12nSubscription) {
      return false;
    }

    return enrollmentChoice.enrollmentChoiceType === EnrollmentChoiceType.EnrollThroughS12NSubscription;
  };

  getCanEnrollThroughS12nSubscriptionFreeTrial = (enrollmentChoice: { enrollmentChoiceType: string }): boolean => {
    if (this.shouldDisableS12nSubscription) {
      return false;
    }

    return enrollmentChoice.enrollmentChoiceType === EnrollmentChoiceType.EnrollThroughS12NSubscriptionTrial;
  };

  getCourseraPlusPrepaidProducts(s12nId?: string): PrepaidProduct[] | undefined {
    const enrollmentChoice = s12nId
      ? this._courseraPlusPrepaidEnrollmentChoices.find(
          ({ enrollmentChoiceInfo }) =>
            (enrollmentChoiceInfo as CourseraPlusInfo)?.s12nEnrollmentChoiceInfo?.s12nId === s12nId
        )
      : this._courseraPlusPrepaidEnrollmentChoices[0];

    if (!enrollmentChoice) {
      return undefined;
    }

    const enrollmentChoiceInfo = enrollmentChoice?.enrollmentChoiceInfo as CourseraPlusInfo;

    const s12nProducts = enrollmentChoiceInfo?.s12nEnrollmentChoiceInfo
      ? (enrollmentChoiceInfo?.s12nEnrollmentChoiceInfo?.productInfo as PrepaidProductOriginal[])?.flatMap(
          this._toAvailablePrepaidProducts
        )
      : [];

    const courseraPlusProducts = enrollmentChoiceInfo?.courseraPlusPermutationToDisplay
      ? [
          this._toAvailablePrepaidProducts(
            enrollmentChoiceInfo?.courseraPlusPermutationToDisplay as PrepaidProductOriginal
          ),
        ]
      : [];

    return [...(s12nProducts || []), ...(courseraPlusProducts || [])];
  }

  getPlannedLaunchDateForS12n(s12nId?: string): Date | undefined {
    return this._timestampToDate(
      this._getS12nPreEnrollmentEnrollmentData(s12nId)?.plannedLaunchTimestamp ||
        this.getPreEnrollmentReasonMetadata(s12nId)?.plannedLaunchTimestamp ||
        this._getNotEligibleToPreEnrollReasonMetadata(s12nId)?.plannedLaunchTimestamp
    );
  }

  getPreEnrollmentReasonMetadata(s12nId?: string): PreEnrolledMetadata | undefined {
    if (this.isPreEnrolled) {
      const preEnrollmentMetadata = this._getEnrollmentBlockingReasonMetadata(
        EnrollmentChoiceReasonCode.PreEnrolled,
        s12nId
      ) as PreEnrolledMetadata;
      return preEnrollmentMetadata;
    }
    return undefined;
  }

  getS12nPrepaidProducts(s12nId?: string): PrepaidProduct[] | undefined {
    const enrollmentChoice = s12nId
      ? this._s12nPrepaidEnrollmentChoices.find(
          ({ enrollmentChoiceInfo }) => (enrollmentChoiceInfo as S12nPrepaidInfo)?.s12nId === s12nId
        )
      : this._s12nPrepaidEnrollmentChoices[0];

    if (!enrollmentChoice) {
      return undefined;
    }

    return (enrollmentChoice.enrollmentChoiceInfo as S12nPrepaidInfo)?.availablePrepaidProducts.flatMap(
      this._toAvailablePrepaidProducts
    );
  }

  getS12nSubscriptionEnrollmentData(s12nId: string): EnrollmentChoice | undefined {
    return this._s12nSubscriptionEnrollmentChoices.find(
      ({ enrollmentChoiceInfo }) => (enrollmentChoiceInfo as S12nSubscriptionInfo)?.s12nId === s12nId
    );
  }

  getS12nSubscriptionProducts(s12nId: string): SubscriptionProduct[] | undefined {
    const enrollmentChoice = this.getS12nSubscriptionEnrollmentData(s12nId);

    return (enrollmentChoice?.enrollmentChoiceInfo as S12nSubscriptionInfo)?.availableSubscriptionProducts?.map(
      this._toAvailableSubscriptions
    );
  }
}

export default EnrollmentChoices;
