import { useCallback } from 'react';

import { useMutation } from '@apollo/client';
import type { AiAuthoring_WritingAssistantUserSentiment as Sentiment } from '__generated__/graphql-types';

import AiWritingAssistantSentiment from 'bundles/cml/editor/api/AiWritingAssistantSentimentMutation.graphql';
import type {
  AiWritingAssistantSentimentMutation,
  AiWritingAssistantSentimentMutationVariables,
} from 'bundles/cml/editor/api/__generated__/AiWritingAssistantSentimentMutation';

export const useSentimentMutation = () => {
  const [mutation] = useMutation<AiWritingAssistantSentimentMutation, AiWritingAssistantSentimentMutationVariables>(
    AiWritingAssistantSentiment,
    {
      context: {
        clientName: 'gatewayGql',
      },
    }
  );

  return useCallback(
    async (input: { id: string; sentiment: Sentiment; comment?: string }) => {
      try {
        const result = await mutation({ variables: { input } });
        return (
          result.data?.AiAuthoring_SubmitWritingAssistantFeedback.__typename ===
          'AiAuthoring_SubmitWritingAssistantFeedbackSuccess'
        );
      } catch {
        return false;
      }
    },
    [mutation]
  );
};
