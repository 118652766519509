import * as React from 'react';
import { useEffect, useState } from 'react';

import type { DescriptionPage_ProductVariant as DescriptionPageProductVariant } from '__generated__/graphql-types';
import classNames from 'classnames';

import type { InjectedRouter } from 'js/lib/connectToRouter';
import redirect from 'js/lib/coursera.redirect';
import store from 'js/lib/coursera.store';
import keysToConstants from 'js/lib/keysToConstants';
import { useRetracked } from 'js/lib/retracked';
import user from 'js/lib/user';

import type { AdsManagerAction, ProductType } from '@coursera/event-pulse-types';
import { useTracker } from '@coursera/event-pulse/react';

import { HAS_PREVIOUSLY_LOGGED_IN } from 'bundles/authentication/constants';
import useIsInLegalConsolidationFlowAndHasTouQueryParam from 'bundles/authentication/shared/hooks/useIsInLegalConsolidationFlowAndHasTouQueryParam';
import EnrollModal from 'bundles/enroll/components/EnrollModal';
import PreloadEnrollModal from 'bundles/enroll/components/PreloadEnrollModal';
import GetS12nCertificateModal from 'bundles/enroll/components/common/GetS12nCertificateModal';
import type { EnrollmentChoices } from 'bundles/enroll/components/xdp/withEnrollment';
import { ENROLL } from 'bundles/enroll/utils/enrollActionParams';
import paymentsExperiments from 'bundles/epic/clients/payments';
import paymentsbackendExperiments from 'bundles/epic/clients/payments-backend';
import adsTracker from 'bundles/event-tracking/lib';
import { getProductCategory } from 'bundles/event-tracking/utils';
import type CoursesV1 from 'bundles/naptimejs/resources/courses.v1';
import type PromotionEligibilitiesV1 from 'bundles/naptimejs/resources/promotionEligibilities.v1';
import type S12nDerivativesV1 from 'bundles/naptimejs/resources/s12nDerivatives.v1';
import {
  isCombineFreeTrialAndCheckoutEligible,
  isCombineFreeTrialAndCheckoutEnabled,
} from 'bundles/payments-common/utils/combineFreeTrialAndCheckoutUtils';
import OpenAidApplicationModal, {
  MODAL_TYPES as OPEN_AID_MODAL_TYPES,
} from 'bundles/payments/components/finaid-2021/OpenAidApplicationModal';
import type { FinancialAidApplicationsWithUserAndProducts_FinancialAidApplicationsV2Resource_byUserAndProductIds_elements as FinancialAidApplication } from 'bundles/payments/components/finaid-2021/enhancers/__generated__/FinancialAidApplicationsWithUserAndProducts';
import { generateCharmPricingInUsTestImpression } from 'bundles/payments/utils/generateCharmPricingInUsTestImpression';
import TopLevelModal from 'bundles/phoenix/components/TopLevelModal';
import { getCourseHomeLinkBySlug } from 'bundles/program-common/utils/courseAndS12nUtils';
import type { CourseType } from 'bundles/program-common/utils/courseTypeMetadataUtils';
import { ProfessionalCertificateS12n } from 'bundles/s12n-common/constants/s12nProductVariants';
import type { S12nProductVariant } from 'bundles/s12n-common/constants/s12nProductVariants';

const MODAL_TYPES = keysToConstants(['ENROLL', 'GET_CERTIFICATE', 'OPEN_AID']);

type OpenFinAidInfo = Partial<FinancialAidApplication> & {
  courseName: string;
  courseId: string;
};

export type PropsFromCaller = {
  courseId: string;
  course?: CoursesV1;
  isEnrolled: boolean;
  isSpecialization?: boolean;
  isEligibleForPlusMonthlyToAnnualUpgrade: boolean;
  s12nId?: string;
  name?: string;
  slug?: string;
  courseType?: CourseType;
  s12nProductVariant?: DescriptionPageProductVariant | S12nProductVariant;
  hideComponent?: boolean;
  additionalQueryParams?: { [key: string]: string };
  shouldCheckActionParams?: boolean;
  shouldCheckProductIdParam?: boolean;
  enrollmentAvailableChoices?: EnrollmentChoices;
  s12nDerivative?: S12nDerivativesV1;
  promotionEligibilities?: PromotionEligibilitiesV1;
  router: InjectedRouter;
  trackingName?: string;
  rootClassName?: string;
  alternateEnrollFlow?: () => boolean; // return `true` to skip normal enroll flow
  ctaBottom?: string | JSX.Element;
  showOpenAidModal?: boolean;
  openFinAidInfo?: OpenFinAidInfo;
  productVariant?: string;
  subtitleElem?: React.ReactNode;
  onClick?: () => void;
  buttonTrackingName?: string;
  partnerIds?: Array<string>;
};

type ExtraProps = {
  onClick: () => void;
  onMultipleLearningProgramsLinkClick?: () => void;
};

function withEnrollModal<Props extends PropsFromCaller>(
  WrappedComponent: React.ComponentType<Props & ExtraProps>
): React.ComponentType<Props> {
  const WithEnrollModal: React.FunctionComponent<Props> = (props) => {
    const {
      isEnrolled,
      slug,
      router,
      additionalQueryParams,
      shouldCheckActionParams,
      shouldCheckProductIdParam,
      enrollmentAvailableChoices,
      courseType,
      s12nProductVariant,
      name,
      courseId,
      course,
      s12nId,
      trackingName,
      isSpecialization,
      alternateEnrollFlow,
      showOpenAidModal,
      productVariant,
      openFinAidInfo,
      hideComponent,
      rootClassName,
      ctaBottom,
      partnerIds,
    } = props;

    const retrackedTrack = useRetracked();
    const track = useTracker();
    const [activeModal, setActiveModal] = useState<keyof typeof MODAL_TYPES | null>(null);
    const [fromMultipleLearningProgramsLink, setFromMultipleLearningProgramsLink] = useState<boolean | undefined>(
      undefined
    );
    const [buttonLoading, setButtonLoading] = useState(false);
    const isLegalConsolidationFlowAndHasTouQueryParam = useIsInLegalConsolidationFlowAndHasTouQueryParam();

    const toggleModalType = (modalType: keyof typeof MODAL_TYPES) => {
      setActiveModal(activeModal === modalType ? null : modalType);
    };

    const toggleOpenAidModal = () => {
      toggleModalType(MODAL_TYPES.OPEN_AID);
    };

    const toggleEnrollModal = () => {
      toggleModalType(MODAL_TYPES.ENROLL);
    };

    const toggleGetS12nCertificateModal = () => {
      toggleModalType(MODAL_TYPES.GET_CERTIFICATE);
    };

    const getGTMTrackingData = () => {
      const productCategory = getProductCategory({
        courseType,
        s12nProductVariant: isSpecialization ? s12nProductVariant : undefined,
      });
      const data = {
        userId: user.get().id,
        productCategory,
        productName: name,
        productSlug: router.params?.slug || router.params?.productSlug,
        productId: isSpecialization ? s12nId : courseId,
      };
      return data;
    };
    const getProductType = () => {
      let productType: ProductType | undefined;

      if (isSpecialization) {
        productType = 's12n';
      } else if (courseType === 'GuidedProject') {
        productType = 'guided_project';
      } else if (courseType === 'Project') {
        productType = 'project';
      } else {
        productType = 'course';
      }

      return productType;
    };

    const trackGTMForEventingV3 = (adsManagerAction: AdsManagerAction, productId?: string) => {
      if (!productId) {
        return;
      }

      track('ads_tracker_google_tag_manager', {
        adsManagerAction,
        product: {
          id: productId,
          type: getProductType(),
        },
      });
    };

    const trackEnroll = () => {
      const data = getGTMTrackingData();

      trackGTMForEventingV3('enroll', data.productId);
      adsTracker.trackEnroll(data);
    };

    const trackEnrollButtonClick = () => {
      const data = getGTMTrackingData();

      trackGTMForEventingV3('enroll_button_click', data.productId);
      adsTracker.trackEnrollButtonClick(data);
    };

    const showEnrollmentChoice = () => {
      // Three possibilities here:
      //  1. alternateEnrollFlow doesn't exist: the call "returns" undefined and we continue with showEnrollmentChoice
      //  2. alternateEnrollFlow exists and returns false: alternateEnrollFlow is called and we continue with
      //     showEnrollmentChoice
      //  3. alternateEnrollFlow exists and returns true: alternateEnrollFlow is called and we SKIP
      //     showEnrollmentChoice (alternateEnrollFlow replaces the normal enroll flow)
      // Example use-case for #3 would be to scroll the page to the Enrollment Options section INSTEAD OF showing the
      // Enroll modal.
      if (showOpenAidModal) {
        toggleOpenAidModal();
        return;
      }

      if (alternateEnrollFlow?.()) {
        return;
      }

      toggleEnrollModal();
    };

    const handleNormalEnrollmentFlow = () => {
      const isAnAuthenticatedUser = user.isAuthenticatedUser();
      const hasEarnedS12nCert = enrollmentAvailableChoices?.hasEarnedS12nCertificate;
      const preEnrollEnabledButNotEligible = isSpecialization
        ? enrollmentAvailableChoices?.isNotEligibleToPreEnroll
        : enrollmentAvailableChoices?.isNotEligibleToPreEnroll &&
          enrollmentAvailableChoices?.hasOnlyPreEnrollEnabledS12ns;

      if (!isAnAuthenticatedUser) {
        const hasPreviouslyLoggedIn = store.get(HAS_PREVIOUSLY_LOGGED_IN);

        router.replace({
          pathname: router.location.pathname,
          query: {
            ...router.location.query,
            ...additionalQueryParams,
            authMode: hasPreviouslyLoggedIn ? 'login' : 'signup',
            action: ENROLL,
            ...(shouldCheckProductIdParam ? { productId: isSpecialization ? s12nId : courseId } : {}),
          },
        });

        if (hasPreviouslyLoggedIn) {
          retrackedTrack({
            trackingName: HAS_PREVIOUSLY_LOGGED_IN,
            action: 'show',
            trackingData: {},
          });
        }
      } else if (isEnrolled && isSpecialization && course && !course.isPreEnroll) {
        redirect.setLocation(getCourseHomeLinkBySlug(course.slug));
      } else if (isEnrolled && typeof slug === 'string') {
        redirect.setLocation(getCourseHomeLinkBySlug(slug));
      } else if (hasEarnedS12nCert) {
        toggleGetS12nCertificateModal();
      } else if (preEnrollEnabledButNotEligible) {
        // If this is the XDP for something that is pre enroll enabled but not eligible to pre-enroll, we shouldn't open
        // any modals and should thus do nothing. This can be removed once pre-enrollment is fully rolled out
      } else {
        trackEnroll();
        // Generate impression for [Q1 2024] Charm Pricing for s12ns and courses in US
        // Link to epic: https://tools.coursera.org/epic/experiment/_4x3VeCCEe6K0goRikKsMw
        const productType = getProductType();
        if (
          (productType === 'course' || productType === 's12n') &&
          !enrollmentAvailableChoices?.canEnrollThroughCourseraPlus &&
          !enrollmentAvailableChoices?.canSubscribeToCourseraLite
        ) {
          generateCharmPricingInUsTestImpression(partnerIds);
        }

        if (
          isSpecialization &&
          isCombineFreeTrialAndCheckoutEligible(enrollmentAvailableChoices) &&
          isCombineFreeTrialAndCheckoutEnabled()
        ) {
          // Remove action param to prevent redirect loop from logged out flow
          const { action, ...queryParamsWithoutActionParam } = router.location.query;
          if (action === ENROLL) {
            router.replace({
              pathname: router.location.pathname,
              query: queryParamsWithoutActionParam,
            });
          }

          setButtonLoading(true);
        }

        showEnrollmentChoice(); // null to reset enrollmentChoice override
      }
    };

    const _onClick = () => {
      const isAnAuthenticatedUser = user.isAuthenticatedUser();
      const trackingData = {
        s12nId,
        courseId,
        path: window.location.pathname,
        isAuthenticatedUser: isAnAuthenticatedUser,
      };

      if (trackingName) {
        retrackedTrack({
          trackingName,
          action: 'click',
          trackingData,
        });
      }

      handleNormalEnrollmentFlow();
    };

    const onClick = () => {
      // We want to track all enroll button clicks, even for logged out users
      trackEnrollButtonClick();

      // For all other clicks, we should make sure that the openS12nSelectModal is set to false
      setFromMultipleLearningProgramsLink(false);
      _onClick();
    };

    const onMultipleLearningProgramsLinkClick = () => {
      // This is specifically used for pre enrollment when the multiple learning programs link
      // is clicked that we open up specifically the s12n select modal. The link only
      // shows up for mix-and-match cases and should always open up the s12n select modal
      setFromMultipleLearningProgramsLink(true);
      _onClick();
    };

    useEffect(() => {
      const { action, productId } = router.location.query;

      const hasValidProductIdParam =
        shouldCheckProductIdParam &&
        ((isSpecialization && s12nId === productId) || (!isSpecialization && courseId === productId));
      const hasValidQueryParams = (hasValidProductIdParam || shouldCheckActionParams) && action === ENROLL;

      if (hasValidQueryParams && !isEnrolled && !isLegalConsolidationFlowAndHasTouQueryParam) {
        onClick();
      }

      if (enrollmentAvailableChoices?.canEnrollThroughS12nPrepaid || enrollmentAvailableChoices?.canSubscribeToS12n) {
        // Generate impression for non-recurring India payment experiment across all variants for s12n enrollments
        // Link to epic: https://tools.coursera.org/epic/experiment/3znqMC0kEeyiaStpRz0_9g
        paymentsbackendExperiments.get('paymentSchemeAvailable');
      }

      if (enrollmentAvailableChoices?.canPreEnroll || enrollmentAvailableChoices?.isPreEnrolled) {
        // Generate impression for pre-enrollment V2 experiment
        // Link to epic: https://tools.coursera.org/epic/experiment/b2P1rzuOEe2tJw5DoCIqiQ
        paymentsExperiments.get('disablePreEnrollment');
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLegalConsolidationFlowAndHasTouQueryParam]);

    const getOpenAidAppModalType = () => {
      if (productVariant === ProfessionalCertificateS12n) {
        return OPEN_AID_MODAL_TYPES.PROFESSIONAL_CERTIFICATE;
      } else if (isSpecialization && s12nId) {
        return OPEN_AID_MODAL_TYPES.SPECIALIZATION;
      }
      return OPEN_AID_MODAL_TYPES.COURSE;
    };

    const enrollModalProps =
      isSpecialization && s12nId
        ? { s12nId, courseId, partnerIds, onSdp: true }
        : { s12nId, courseId, partnerIds, fromMultipleLearningProgramsLink };

    const renderModal = () => {
      switch (activeModal) {
        case MODAL_TYPES.ENROLL:
          return (
            <TopLevelModal>
              <EnrollModal {...enrollModalProps} router={router} onClose={toggleEnrollModal} />
            </TopLevelModal>
          );
        case MODAL_TYPES.GET_CERTIFICATE:
          if (s12nId) {
            return <GetS12nCertificateModal s12nId={s12nId} onClose={toggleGetS12nCertificateModal} />;
          } else {
            throw new Error('Expect MODAL_TYPES.GET_CERTIFICATE to have a valid s12nId');
          }
        case MODAL_TYPES.OPEN_AID:
          return (
            <OpenAidApplicationModal
              type={getOpenAidAppModalType()}
              courseName={openFinAidInfo?.courseName}
              approvalDate={openFinAidInfo?.approvalDate}
              cartId={openFinAidInfo?.cartId}
              applicationState={openFinAidInfo?.state}
              handleClose={toggleOpenAidModal}
              s12nId={s12nId}
              handleCancelAndEroll={toggleEnrollModal}
            />
          );
        default:
          return null;
      }
    };

    if (hideComponent) {
      return null;
    }

    return (
      <div className={classNames('with-enroll-modal', rootClassName)}>
        {renderModal()}
        {paymentsExperiments.get('preloadEnrollModalEnabled') ? <PreloadEnrollModal {...enrollModalProps} /> : null}
        <WrappedComponent
          {...props}
          buttonLoading={buttonLoading}
          ctaBottom={ctaBottom}
          onClick={onClick}
          onMultipleLearningProgramsLinkClick={onMultipleLearningProgramsLinkClick}
        />
      </div>
    );
  };

  return WithEnrollModal;
}

export default withEnrollModal;
