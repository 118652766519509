import { isEqual } from 'lodash';
import { Editor, Element, Path, Range, Text, Transforms } from 'slate';
import type { NodeEntry, Point } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';
import type { Subscription } from 'zen-observable-ts';

import { isAiWritingAssistantEnabled } from 'bundles/authoring/featureFlags';
import type { RegenerateInput } from 'bundles/cml/editor/components/buttons/ai/hooks/useRegenerate';
import { slateToMarkdown } from 'bundles/cml/editor/utils/slateToMarkdown';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { AIElement, BlockElement } from 'bundles/cml/shared/types/elementTypes';
import { useTeachContext } from 'bundles/teach-course/contexts/useTeachContext';

const MAX_TEMPERATURE = 1.0;
const TEMPERATURE_DELTA = 0.1;

export const useAiWritingAssistantSupported = () => {
  const context = useTeachContext();
  return !!context.course && isAiWritingAssistantEnabled();
};

export const isAIButtonDisabled = (editor: Editor): boolean => {
  const text = editor.selection ? Editor.string(editor, editor.selection) : '';
  if (!text) {
    return true;
  }

  return false;
};

export const hasAIElement = (editor: Editor): boolean => {
  return editor.children.some((el) => Element.isElement(el) && el.type === BLOCK_TYPES.AI_ELEMENT);
};

export const getSelectedRange = (editor: Editor, element: AIElement) => {
  const elementPath = ReactEditor.findPath(editor, element);
  const nodes = Array.from(
    Editor.nodes(editor, { at: elementPath, match: (n) => Text.isText(n) && n.selected === true })
  ) as NodeEntry<Text>[];

  if (nodes.length === 0) {
    return null;
  }

  const startNode = nodes[0];
  const endNode = nodes[nodes.length - 1];

  return { anchor: { path: startNode[1], offset: 0 }, focus: { path: endNode[1], offset: endNode[0].text.length } };
};

const getText = ([{ text }, path]: NodeEntry<Text>, start: Point, end: Point): string => {
  if (Path.isBefore(path, start.path) || Path.isAfter(path, end.path)) {
    return '';
  }

  const isStartPath = isEqual(path, start.path) && start.offset > 0;
  const isEndPath = isEqual(path, end.path) && end.offset > 0;

  if (isStartPath || isEndPath) {
    const startIndex = isStartPath ? start.offset : 0;
    const endIndex = isEndPath ? end.offset : undefined;
    return text.slice(startIndex, endIndex);
  }

  return text;
};

export const getSelectedText = (editor: Editor, element: AIElement) => {
  const range = getSelectedRange(editor, element);
  if (!range) {
    return {};
  }

  const nodes = Array.from(
    Editor.nodes<BlockElement>(editor, {
      at: range,
      match: (node, path) => Element.isElement(node) && path.length === 2,
    })
  );

  const markdownNodes = nodes
    .flatMap((node) => slateToMarkdown(node, (entry) => getText(entry, range.anchor, range.focus)))
    .flat();
  const selectedText = markdownNodes.join('\n');

  const completeText = editor.children
    .flatMap((node, index: number) => {
      return slateToMarkdown([node as BlockElement, [index]]);
    })
    .join('\n');

  const startIndex = completeText.indexOf(selectedText);
  const endIndex = selectedText.length + startIndex;

  if (startIndex === -1) {
    return { completeText: selectedText };
  }

  return { completeText, startIndex, endIndex };
};

export const removeSelectedText = (editor: Editor, element: AIElement) => {
  HistoryEditor.withoutSaving(editor, () => {
    const range = getSelectedRange(editor, element);
    if (range) {
      Transforms.select(editor, range);
      Editor.removeMark(editor, 'selected');
      Transforms.collapse(editor, { edge: 'end' });
    }

    const path = ReactEditor.findPath(editor, element);
    Transforms.unwrapNodes(editor, { at: path });
  });
};

type Input = {
  aiEditor: Editor;
  editor: Editor;
  element: AIElement;
};

export const cancelSuggestion = ({ editor, element }: Input) => {
  removeSelectedText(editor, element);
  ReactEditor.focus(editor);
};

export const replaceSelection = ({ editor, aiEditor, element }: Input) => {
  const range = getSelectedRange(editor, element);
  if (!range) {
    return;
  }

  const elementPath = ReactEditor.findPath(editor, element);
  const elementRange = { anchor: Editor.start(editor, elementPath), focus: Editor.end(editor, elementPath) };

  const shouldReplaceNode = Range.equals(elementRange, range);

  const rangeRef = Editor.rangeRef(editor, range);
  removeSelectedText(editor, element);

  const newRange = rangeRef.unref();

  const children = aiEditor.children as BlockElement[];
  const firstNode = children[0];

  if (shouldReplaceNode) {
    Transforms.removeNodes(editor, { at: newRange || undefined, mode: 'highest' });
    Transforms.insertNodes(editor, children, { at: elementPath });
  } else if (firstNode && firstNode.type === BLOCK_TYPES.TEXT) {
    Transforms.insertNodes(editor, firstNode.children, { at: newRange || undefined, select: true });
    Transforms.collapse(editor, { edge: 'end' });
    Transforms.insertNodes(editor, children.slice(1));
  } else {
    Transforms.insertNodes(editor, children, { at: newRange || undefined });
  }

  ReactEditor.focus(editor);
};

export const insertBelowSelection = ({ editor, aiEditor, element }: Input) => {
  const range = getSelectedRange(editor, element);
  if (!range) {
    return;
  }

  const rangeRef = Editor.rangeRef(editor, range);
  removeSelectedText(editor, element);

  const newRange = rangeRef.unref();
  Transforms.insertNodes(editor, aiEditor.children, { at: newRange?.focus });
  ReactEditor.focus(editor);
};

export const updateAIElement = (editor: Editor, element: AIElement, data: Partial<AIElement>) => {
  const elementPath = ReactEditor.findPath(editor, element);
  HistoryEditor.withoutSaving(editor, () => {
    Transforms.setNodes(editor, data, { at: elementPath, match: (el, path) => path.length === elementPath.length });
  });
};

export const regenerateSuggestion = ({
  editor,
  aiEditor,
  element,
  regenerate,
}: Input & { regenerate: (input: RegenerateInput) => Subscription | undefined }) => {
  Editor.withoutNormalizing(aiEditor, () => {
    Transforms.removeNodes(aiEditor, {
      at: {
        anchor: Editor.start(aiEditor, [0]),
        focus: Editor.end(aiEditor, [aiEditor.children.length - 1]),
      },
      mode: 'highest',
      voids: true,
    });
    if (aiEditor.children.length === 0) {
      Transforms.insertNodes(aiEditor, { type: BLOCK_TYPES.TEXT, children: [{ text: '' }] }, { at: [0] });
    }
  });

  const temperature = Math.min(element.temperature + TEMPERATURE_DELTA, MAX_TEMPERATURE);
  updateAIElement(editor, element, { generating: true, temperature });

  const onComplete = (id?: string) => {
    updateAIElement(editor, element, { id, generating: false });
  };

  const subscription = regenerate({ editor, element, temperature, onComplete, onError: onComplete });
  if (!subscription) {
    updateAIElement(editor, element, { id: undefined, generating: false });
  }

  return subscription;
};
