import videojs from 'video.js';

class ClippedCurrentTimeDisplay extends videojs.getComponent('CurrentTimeDisplay') {
  startTime = 0;

  constructor(player, options) {
    super(player, options);
    this.el_.classList.add('customComponent-ClippedCurrentTimeDisplay');
  }

  setStartTime(value) {
    this.startTime = value;
  }

  updateContent() {
    // Allows for smooth scrubbing, when player can't keep up.
    const time = this.player_.scrubbing() ? this.player_.getCache().currentTime : this.player_.currentTime();

    this.updateTextNode_(time - this.startTime);
  }
}

class ClippedProgressControl extends videojs.getComponent('ProgressControl') {
  constructor(player, options) {
    super(player, options);
    this.el_.classList.add('customComponent-ClippedProgressControl');
  }

  getChild(name) {
    return super.getChild(name === 'seekBar' ? 'clippedSeekBar' : name);
  }
}

class ClippedPlayProgressBar extends videojs.getComponent('PlayProgressBar') {
  constructor(player, options) {
    super(player, options);
    this.el_.classList.add('customComponent-ClippedPlayProgressBar');
  }

  startTime = 0;

  setStartTime(value) {
    this.startTime = value;
  }

  update(seekBarRect, seekBarPoint) {
    // If there is an existing rAF ID, cancel it so we don't over-queue.
    if (this.rafId_) {
      this.cancelAnimationFrame(this.rafId_);
    }

    this.rafId_ = this.requestAnimationFrame(() => {
      const time =
        (this.player_.scrubbing() ? this.player_.getCache().currentTime : this.player_.currentTime()) - this.startTime;
      const content = videojs.time.formatTime(time, this.player_.duration());
      const timeTooltip = this.getChild('timeTooltip');

      if (timeTooltip) {
        timeTooltip.update(seekBarRect, seekBarPoint, content);
      }
    });
  }
}

class ClippedSeekBar extends videojs.getComponent('SeekBar') {
  constructor(player, options) {
    super(player, options);
    this.el_.classList.add('customComponent-ClippedSeekBar');
  }

  startTime = 0;

  setStartTime(value) {
    this.startTime = value;
  }

  getCurrentTime_() {
    return super.getCurrentTime_() - this.startTime;
  }

  stepForward() {
    return super.stepForward() - this.startTime;
  }

  stepBack() {
    return super.stepBack() - this.startTime;
  }

  handleMouseMove(event) {
    let newTime = this.calculateDistance(event) * this.player_.duration() + this.startTime;

    // Don't let video end while scrubbing.
    if (newTime === this.player_.duration()) {
      newTime -= 0.1;
    }

    // Set new time (tell player to seek to new time)
    this.player_.currentTime(newTime);
  }
}

const getAttributes = (tag) => {
  const obj = {};

  // known boolean attributes
  // we can check for matching boolean properties, but older browsers
  // won't know about HTML5 boolean attributes that we still read from
  const knownBooleans = ',autoplay,controls,playsinline,loop,muted,default,defaultMuted,';

  if (tag && tag.attributes && tag.attributes.length > 0) {
    const attrs = tag.attributes;

    for (let i = attrs.length - 1; i >= 0; i -= 1) {
      const attrName = attrs[i].name;
      let attrVal = attrs[i].value;

      // check for known booleans
      // the matching element property will return a value for typeof
      if (typeof tag[attrName] === 'boolean' || knownBooleans.indexOf(',' + attrName + ',') !== -1) {
        // the value of an included boolean attribute is typically an empty
        // string ('') which would equal false if we just check for a false value.
        // we also don't want support bad code like autoplay='false'
        attrVal = attrVal !== null;
      }

      obj[attrName] = attrVal;
    }
  }

  return obj;
};

class LocalFilePlayer extends videojs.getComponent('Player') {
  constructor(player, options) {
    super(player, options);
    this.el_.classList.add('customComponent-LocalFilePlayer');
  }

  updateSourceCaches_(srcObj) {
    let src = srcObj;
    let type = '';

    if (typeof src !== 'string') {
      src = srcObj.src;
      type = srcObj.type;
    }

    // NOTE: the following code was preventing local files from playing

    // if we are a blob url, don't update the source cache
    // blob urls can arise when playback is done via Media Source Extension (MSE)
    // such as m3u8 sources with @videojs/http-streaming (VHS)
    // if (/^blob:/.test(src)) {
    //   return;
    // }

    // make sure all the caches are set to default values
    // to prevent null checking
    this.cache_.source = this.cache_.source || {};
    this.cache_.sources = this.cache_.sources || [];

    // update `currentSource` cache always
    this.cache_.source = Object.assign({}, srcObj, { src, type });

    const matchingSources = this.cache_.sources.filter((s) => {
      return s.src && s.src === src;
    });

    const sourceElSources = [];
    const sourceEls = this.$$('source');
    const matchingSourceEls = [];

    for (let i = 0; i < sourceEls.length; i += 1) {
      const sourceObj = getAttributes(sourceEls[i]);

      sourceElSources.push(sourceObj);

      if (sourceObj.src && sourceObj.src === src) {
        matchingSourceEls.push(sourceObj.src);
      }
    }

    // if we have matching source els but not matching sources
    // the current source cache is not up to date
    if (matchingSourceEls.length && !matchingSources.length) {
      this.cache_.sources = sourceElSources;
      // if we don't have matching source or source els set the
      // sources cache to the `currentSource` cache
    } else if (!matchingSources.length) {
      this.cache_.sources = [this.cache_.source];
    }

    // update the tech `src` cache
    this.cache_.src = src;
  }
}

videojs.registerComponent('clippedProgressControl', ClippedProgressControl);
videojs.registerComponent('clippedSeekBar', ClippedSeekBar);
videojs.registerComponent('clippedCurrentTimeDisplay', ClippedCurrentTimeDisplay);
videojs.registerComponent('clippedPlayProgressBar', ClippedPlayProgressBar);
videojs.registerComponent('player', LocalFilePlayer);
