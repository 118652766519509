import { InMemoryCache } from '@apollo/client';
import type {
  AiCoach_Action as AiCoachAction,
  AiCoach_RespondToUserMessageInput as AiCoachRespondToUserMessageInput,
  AiCoach_RespondToUserMessageResult as AiCoachRespondToUserMessageResult,
} from '__generated__/graphql-types';
import { ApolloClient } from 'apollo-client';

import { getIetfLanguageTag } from 'js/lib/language';

import aiCoachRespondToUserMessageMutation from 'bundles/ai-coach-platform/queries/aiCoachRespondToUserMessageMutation.graphql';

import _t from 'i18n!nls/ai-coach-platform';

import store from '.';
import { persistentChatSessionId } from '../utils/sessionUtils';
import * as actions from './actions';
import type { UpdateChatHistory } from './actions/types';
import type { Feedback } from './types';

export function updateChatHistory(messages: UpdateChatHistory['messages']) {
  const action = actions.updateChatHistory(messages);
  store.dispatch(action);
}

export function addUserMessage({
  text,
  id,
  timestamp,
  action,
}: {
  text: string;
  id?: string;
  timestamp?: number;
  action?: AiCoachAction;
}) {
  store.dispatch(actions.addUserMessage(text, id, timestamp, action));
}

export function addResponseMessage({
  text,
  id,
  userFeedback,
  timestamp,
}: {
  text: string;
  id?: string;
  userFeedback?: Feedback | null;
  timestamp?: number;
}) {
  store.dispatch(actions.addResponseMessage(text, id, userFeedback, timestamp));
}

export function enableMsgLoader(enabledState: boolean) {
  store.dispatch(actions.enableMsgLoader(enabledState));
}

export function toggleWidget() {
  store.dispatch(actions.toggleChat());
}

export function openCoachWidget() {
  store.dispatch(actions.openCoachWidget());
}

export function closeCoachWidget() {
  store.dispatch(actions.closeCoachWidget());
}

export function toggleInputDisabled() {
  store.dispatch(actions.toggleInputDisabled());
}

export function dropMessages() {
  store.dispatch(actions.dropMessages());
}

export function deleteMessages(count: number, id?: string) {
  store.dispatch(actions.deleteMessages(count, id));
}

export function setUserInputText(inputText: string) {
  store.dispatch(actions.setUserInput(inputText));
}

export function clearUserInputText() {
  store.dispatch(actions.setUserInput(''));
}

export function setChatSessionId(chatSessionId?: string) {
  store.dispatch(actions.setChatSessionId(chatSessionId));
}

export function setChatSessionStorageKey(chatSessionStorageKey: string) {
  store.dispatch(actions.setChatSessionStorageKey(chatSessionStorageKey));
}

export function clearChatSession() {
  const { sessionState } = store.getState();
  const { chatSessionStorageKey: chatSessionStorageKeyState } = sessionState;
  persistentChatSessionId.clear(chatSessionStorageKeyState?.chatSessionStorageKey);
  store.dispatch(actions.clearChatSession());
}

// implement a functional approach to calling apollo mutation and dispatching the result
export async function sendMessage(variables: { input: AiCoachRespondToUserMessageInput }) {
  const { sessionState } = store.getState();
  const { chatSessionId: chatSessionIdState, chatSessionStorageKey: chatSessionStorageKeyState } = sessionState;
  const cache = new InMemoryCache();
  const client = new ApolloClient({
    uri: `/graphql-gateway?opname=AiCoach_RespondToUserMessage`,
    cache,
    headers: {
      'Accept-Language': getIetfLanguageTag(),
    },
  });

  addUserMessage({
    text: variables.input.message,
    id: undefined,
    timestamp: undefined,
    action: variables.input.config.coachActionId as AiCoachAction,
  });
  enableMsgLoader(true);

  const queryInput: AiCoachRespondToUserMessageInput = {
    message: variables.input.message,
    config: variables.input.config,
    courseraContext: variables.input.courseraContext,
    chatSessionId: variables.input.chatSessionId,
    additionalContext: variables.input.additionalContext,
  };

  return client
    .mutate<{
      AiCoach_RespondToUserMessage: AiCoachRespondToUserMessageResult | null | undefined;
    }>({
      mutation: aiCoachRespondToUserMessageMutation,
      variables: {
        input: queryInput,
      },
      context: { clientName: 'gatewayGql' },
    })
    .then(({ data }) => {
      const { chatSessionId, messageByAiCoach } = data?.AiCoach_RespondToUserMessage || {};

      enableMsgLoader(false);

      if (!chatSessionIdState) {
        setChatSessionId(chatSessionId);
      }

      const currentChatSessionId = persistentChatSessionId.get(chatSessionStorageKeyState?.chatSessionStorageKey);
      if (!currentChatSessionId && chatSessionId) {
        persistentChatSessionId.set(chatSessionStorageKeyState?.chatSessionStorageKey, chatSessionId);
      }

      if (messageByAiCoach?.message) {
        store.dispatch(
          actions.addResponseMessage(
            messageByAiCoach.message,
            messageByAiCoach.messageId,
            undefined,
            new Date().valueOf()
          )
        );
      }
      return data;
    })
    .catch(() => {
      enableMsgLoader(false);

      store.dispatch(
        actions.addResponseMessage(
          _t('Sorry, there was an error sending your message.'),
          'error1',
          undefined,
          new Date().valueOf()
        )
      );
    });
}
