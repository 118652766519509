import type { Node, NodeEntry } from 'slate';
import { Editor, Text, Transforms } from 'slate';

import { randomUUID } from 'js/lib/uuid';

import codeLanguages from 'bundles/cml/legacy/constants/codeLanguages';
import type { LanguageType } from 'bundles/cml/legacy/constants/codeLanguages';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { CodeElement } from 'bundles/cml/shared/types/elementTypes';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';
import { Tools } from 'bundles/cml/shared/utils/customTools';

const CODE_MARKDOWN_REGEX = /^(```|`)([a-z]+)\n((.*\n)+?)(```|`)$/m;

const parseLanguageType = (language: string): LanguageType => {
  const result = codeLanguages.find(({ regex }) => language.match(regex));
  return result?.value ?? 'plain_text';
};

export const normalizeCodeMarkdown = (editor: Editor, tools: Set<ToolsKeys>, nodeEntry: NodeEntry<Node>) => {
  if (!Text.isText(nodeEntry[0])) {
    return false;
  }

  if (!tools.has(Tools.CODE)) {
    return false;
  }

  const [node, path] = nodeEntry as NodeEntry<Text>;
  const { text } = node;

  const match = text.match(CODE_MARKDOWN_REGEX);
  if (!match) {
    return false;
  }

  const language = parseLanguageType(match[2]);
  const codeText = match[3];

  const start = match.index ?? 0;
  const matchText = match[0];

  const codeBlock: CodeElement = {
    id: randomUUID(),
    type: BLOCK_TYPES.CODE,
    isVoid: true,
    language,
    codeText,
    children: [{ text: '' }],
  };

  Editor.withoutNormalizing(editor, () => {
    Transforms.delete(editor, {
      at: { path, offset: start },
      distance: matchText.length + 1,
      unit: 'character',
    });

    const value = Editor.string(editor, path);
    if (!value) {
      const rootPath = path.slice(0, -1);
      Transforms.removeNodes(editor, { at: rootPath });
      Transforms.insertNodes(editor, codeBlock, { at: rootPath });
    } else {
      Transforms.insertNodes(editor, codeBlock, { at: { path, offset: start } });
    }
  });
  return true;
};
