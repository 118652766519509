/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useState } from 'react';

import { CourseraTierSubscriptions_BillingCycle as BillingCycleType } from '__generated__/graphql-types';
import { branch, compose } from 'recompose';

import redirect from 'js/lib/coursera.redirect';
import fullStory from 'js/lib/fullStoryUtils';

import type { Theme } from '@coursera/cds-core';
import { Dialog, useTheme } from '@coursera/cds-core';

import StepOneUpgradeModalContent from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/StepOneUpgradeModalContent';
import StepThreeUpgradeModalContent from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/StepThreeUpgradeModalContent';
import StepTwoUpgradeModalContent from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/StepTwoUpgradeModalContent';
import UpgradeFailureStateModal from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/UpgradeFailureStateModal';
import {
  getIdealUpgradeModalsCopy,
  getIdealUpgradeStepThreeCopy,
} from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/upgradeCopies';
import type { IdealUpgradeGetModalCopyInputType } from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/upgradeCopies';
import type { SubscriptionTiersProductPrice } from 'bundles/coursera-plus/contentfulData/LandingPageDataProvider';
import { getExploreLink } from 'bundles/coursera-plus/utils/generalUtils';
import {
  getRedirectUrlAfterUpgrade,
  removeRedirectUrlAfterUpgrade,
  setHasSeenSubscriptionTiersUpgradeMessage,
} from 'bundles/coursera-plus/utils/subscriptionTiersUtils';
import withCourseraPlusProductEligibilityCheck from 'bundles/coursera-plus/utils/withCourseraPlusProductEligibilityCheck';
import type { EligibilityCheckProps } from 'bundles/coursera-plus/utils/withCourseraPlusProductEligibilityCheck';
import { useExistingCourseraLiteSubscription } from 'bundles/coursera-plus/utils/withExistingCourseraLiteSubscription';
import courseraPlusExperiments from 'bundles/epic/clients/courseraPlus';
import type ProductPricesV4 from 'bundles/naptimejs/resources/productPrices.v4';
import type SubscriptionsV1 from 'bundles/naptimejs/resources/subscriptions.v1';
import { useWallets } from 'bundles/payments/data/useWallets';
import { useCart } from 'bundles/refund/utils/hooks';

const useStyles = (theme: Theme) => {
  return {
    dialog: css`
      position: relative;

      .cds-Dialog-dialog {
        max-width: 700px !important;
      }
    `,
    dialogWith800pxWidth: css`
      position: relative;

      ${theme.breakpoints.up('sm')} {
        .cds-Dialog-dialog {
          width: 740px;
          max-width: 800px !important;
          max-height: 720px !important;
        }
      }

      @media (max-width: 815px) {
        .cds-Dialog-dialog {
          width: 100% !important;
        }
      }
    `,
  };
};

type PropsFromCaller = {
  selectedStep?: 1 | 2 | 3 | 'upgrade-failure';
  onClose: () => void;
  // courseId prop is only used as input for the withCourseraPlusProductEligibilityCheck HOC
  courseId?: string;
  s12nId?: string;
  courseraPlusProductPrice?: SubscriptionTiersProductPrice;
  isStandaloneCourseraPlusUpgrade?: boolean;
  toTierBillingCycle?: BillingCycleType;
};

export type PropsToComponent = PropsFromCaller & EligibilityCheckProps;

export const IdealUpgradeEnrollModal: React.FC<PropsToComponent> = ({
  onClose,
  selectedStep = 1,
  s12nId,
  courseId,
  courseraPlusProductPrice,
  isCourseraPlusEligibleProduct,
  isStandaloneCourseraPlusUpgrade,
  toTierBillingCycle = BillingCycleType.Monthly,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const { braintreeWallets, paypalWallets } = useWallets(undefined, true);
  const [isPrimaryCtaLoading, setIsPrimaryCtaLoading] = useState(false);
  const [step, setStep] = useState<1 | 2 | 3 | 'upgrade-failure'>(selectedStep);
  const { existingCourseraLiteSubscription } = useExistingCourseraLiteSubscription();
  const { cart } = useCart({ cartId: existingCourseraLiteSubscription?.cartId });

  if (selectedStep === 2) {
    if (!existingCourseraLiteSubscription) {
      throw new Error('No existing Lite subscription');
    }

    if (!courseraPlusProductPrice) {
      throw new Error('Invalid price information');
    }
  }

  useEffect(() => {
    if (courseraPlusExperiments.get('idealUpgradeFullStoryEnabled')) {
      fullStory.init();
      fullStory.set({
        hasSeenIdealUpgradeModal_bool: true, // eslint-disable-line camelcase
      });
    }
  }, []);

  const handleUpgradeSuccessful = () => {
    setTimeout(() => {
      setStep(3);
    }, 1000);
  };

  const handleCloseModal = () => {
    if (step === 3) {
      // Deactivate the other upgrade notification message so learner doesn't have to see it again
      setHasSeenSubscriptionTiersUpgradeMessage();

      // If there is no redirect url, it means upgrade happended
      // on the modal (not via checkout), so we need to refresh the page
      if (!getRedirectUrlAfterUpgrade()) {
        setIsPrimaryCtaLoading(true);

        if (isStandaloneCourseraPlusUpgrade) {
          return redirect.setLocation(getExploreLink());
        }
        return redirect.refresh();
      } else {
        removeRedirectUrlAfterUpgrade();
      }
    }

    onClose();
  };

  const stepThreeCopy = getIdealUpgradeStepThreeCopy({
    isStandaloneCourseraPlusUpgrade: Boolean(isStandaloneCourseraPlusUpgrade),
  });
  const stepThreeContentModalContent = (
    <StepThreeUpgradeModalContent
      copy={stepThreeCopy}
      courseId={courseId}
      s12nId={s12nId}
      handleCloseModal={handleCloseModal}
      isPrimaryCtaLoading={isPrimaryCtaLoading}
    />
  );

  // This early return of step 3 modal is specifically for the case where the learner
  // upgrades to Plus from the checkout page. In this case, courseraPlusProductPrice is undefined
  // so we need to render the confirmation modal before we can attempt to access courseraPlusProductPrice
  // The alternative would have been to create a new modal for step 3, but I decided to go with this
  if (selectedStep === 3 && isCourseraPlusEligibleProduct) {
    return (
      <Dialog
        aria-describedby="dialog-content"
        open
        variant="standard"
        width="medium"
        onClose={handleCloseModal}
        css={styles.dialog}
        data-testId="ideal-upgrade-modal"
      >
        {stepThreeContentModalContent}
      </Dialog>
    );
  }

  const copyInput: IdealUpgradeGetModalCopyInputType = {
    existingCourseraLiteSubscription: existingCourseraLiteSubscription as SubscriptionsV1,
    paypalWallets,
    braintreeWallets,
    courseraPlusProductPrice: courseraPlusProductPrice as ProductPricesV4,
    toTierBillingCycle,
    isStandaloneCourseraPlusUpgrade: isStandaloneCourseraPlusUpgrade || false,
  };

  if (!existingCourseraLiteSubscription || !courseraPlusProductPrice) {
    return null;
  }

  const { stepOneCopy, stepTwoCopy } = getIdealUpgradeModalsCopy(copyInput);
  return (
    <Dialog
      aria-describedby="dialog-content"
      open
      variant="standard"
      width="medium"
      onClose={handleCloseModal}
      css={step === 2 ? styles.dialogWith800pxWidth : styles.dialog}
      data-testId="ideal-upgrade-modal"
    >
      {step === 1 && (
        <StepOneUpgradeModalContent
          copy={stepOneCopy}
          courseId={courseId}
          s12nId={s12nId}
          goToNextStep={() => setStep(2)}
        />
      )}
      {step === 2 && (
        <StepTwoUpgradeModalContent
          copy={stepTwoCopy}
          courseId={courseId}
          s12nId={s12nId}
          goToPreviousStep={() => setStep(1)}
          handleUpgradeSuccessful={handleUpgradeSuccessful}
          showErrorStateModal={() => setStep('upgrade-failure')}
          fromTierSubscriptionId={existingCourseraLiteSubscription.id}
          isStandaloneCourseraPlusUpgrade={Boolean(isStandaloneCourseraPlusUpgrade)}
          toTierBillingCycle={toTierBillingCycle}
          countryIsoCode={cart?.countryIsoCode}
          currencyCode={cart?.currencyCode}
        />
      )}
      {step === 3 && stepThreeContentModalContent}
      {step === 'upgrade-failure' && <UpgradeFailureStateModal courseId={courseId} s12nId={s12nId} />}
    </Dialog>
  );
};

export default compose<PropsToComponent, PropsFromCaller>(
  branch(({ selectedStep }: PropsFromCaller) => selectedStep === 3, withCourseraPlusProductEligibilityCheck())
)(IdealUpgradeEnrollModal);
