/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import isEqual from 'lodash/isEqual';

import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';

import { Checkbox, CheckboxGroup, Link, Typography2 } from '@coursera/cds-core';

import { CoachButton } from 'bundles/ai-coach-platform/components/building-blocks';
import { CoachSettingsContext } from 'bundles/ai-coach-platform/components/patterns/chat/settings/SettingsContextProvider';
import SettingsSwitch from 'bundles/ai-coach-platform/components/patterns/chat/settings/SettingsSwitch';
import type { ProfileInfoField } from 'bundles/ai-coach-platform/components/patterns/chat/settings/types';
import { useSettingsNotification } from 'bundles/ai-coach-platform/components/patterns/chat/settings/useSettingsNotification';
import { getProfileFieldName } from 'bundles/ai-coach-platform/components/patterns/chat/settings/utils';

import _t from 'i18n!nls/ai-coach-platform';

import SettingsPage from '../SettingsPage';

const styles = {
  root: css`
    height: 100%;
  `,
  profileFields: css`
    .cds-checkboxAndRadio-labelContent {
      color: var(--cds-color-neutral-primary);
    }
  `,
  notification: css`
    position: absolute;
    bottom: 96px;
    max-width: 500px;
    z-index: 99999;
    margin: 0 auto;
    left: 0;
    right: 0;
  `,
};

type Props = {
  onBack: () => void;
};

export const SettingsProfileInfo = (props: Props): React.ReactElement => {
  const { onBack } = props;
  const { updateData, data } = useContext(CoachSettingsContext);
  const { addNotification, clearNotification } = useSettingsNotification();

  const [selectedProfileFields, setSelectedFields] = useState<Array<string>>([]);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  React.useEffect(() => {
    return () => {
      clearNotification();
    };
  }, [clearNotification]);

  const handleCheckboxChange = (value: string, checked: boolean) => {
    const profileFields = data?.profileInfo?.fields!;

    const currentSelectedFields = Object.keys(profileFields).filter(
      (key) => profileFields[key as ProfileInfoField].enabled === true
    );
    const newFieldsSet = new Set(selectedProfileFields);

    if (checked) {
      newFieldsSet.add(value);
    } else {
      newFieldsSet.delete(value);
    }

    setSelectedFields([...newFieldsSet]);

    setHasChanges(!isEqual(newFieldsSet, new Set(currentSelectedFields)));
  };

  const handleSave = () => {
    const profileFields = data?.profileInfo?.fields;
    if (!profileFields) {
      return;
    }

    setIsSaving(true);

    const newFieldsSet = new Set(selectedProfileFields);

    Object.keys(profileFields).forEach((profileField) => {
      profileFields[profileField as ProfileInfoField] = {
        enabled: newFieldsSet.has(profileField),
      };
    });

    // TODO: refactor this update so each page only manages updating its own state,
    // and manage the merging of existing data in other place
    updateData({ ...data, profileInfo: { enabled: !!data?.profileInfo?.enabled, fields: profileFields } });

    /* TODO: save to BE */

    setTimeout(() => {
      setIsSaving(false);
      setHasChanges(false);
      addNotification({ content: _t('Profile fields update successful'), severity: 'success', isTransient: true });
    }, 2000);
  };

  useEffect(() => {
    const profileFields = data?.profileInfo?.fields;

    if (profileFields) {
      setSelectedFields(
        Object.keys(profileFields).filter((key) => profileFields[key as ProfileInfoField].enabled === true)
      );
    }
  }, [data]);

  const profileFields = data?.profileInfo?.fields;
  const showProfileFieldsSelection = profileFields && data?.profileInfo?.enabled;

  return (
    <div className="coach-settings-profileinfo" css={styles.root}>
      <SettingsPage
        onBack={onBack}
        title={_t('Profile data settings')}
        footer={
          <CoachButton
            loading={isSaving}
            aria-disabled={!hasChanges || !showProfileFieldsSelection}
            onClick={handleSave}
          >
            {_t('Save preferences')}
          </CoachButton>
        }
      >
        <SettingsSwitch
          disabled={isSaving}
          label={_t('Profile data')}
          supportText={
            <Typography2 variant="bodySecondary" component="div" color="supportText">
              {_t(
                'Coach will customize its responses based on the profile information you select below to provide you with more helpful guidance.'
              )}
              <p>
                <FormattedHTMLMessage
                  message={_t(`The selections you make will only affect how Coach responds and will not change your Coursera profile
                information. To update your profile information, {editProfileUrl}.`)}
                  editProfileUrl={
                    <Link href="/account-profile" target="_blank">
                      {_t('edit your profile')}
                    </Link>
                  }
                />
              </p>
            </Typography2>
          }
          selected={data?.profileInfo?.enabled}
          onToggle={(newSelected) => {
            updateData({ ...data, profileInfo: { ...data?.profileInfo, enabled: newSelected } });

            addNotification({
              content: _t('Profile preference update successful'),
              severity: 'success',
              isTransient: true,
            });
          }}
        />

        {showProfileFieldsSelection ? (
          <CheckboxGroup
            css={styles.profileFields}
            aria-label={_t('Profile data fields')}
            label=""
            optional
            value={selectedProfileFields}
            onChange={(e, checked) => handleCheckboxChange(e.target.value, checked)}
          >
            {Object.keys(profileFields).map((profileField) => {
              return (
                <Checkbox
                  key={profileField}
                  label={getProfileFieldName(profileField as ProfileInfoField)}
                  value={profileField}
                  disabled={isSaving}
                />
              );
            })}
          </CheckboxGroup>
        ) : null}
      </SettingsPage>
    </div>
  );
};

export default SettingsProfileInfo;
