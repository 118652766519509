import type { FetchResult, Operation } from '@apollo/client';
import { ApolloLink, Observable } from '@apollo/client';
import { print } from 'graphql';
import type { ClientOptions } from 'graphql-sse';
import { createClient } from 'graphql-sse';

class SSELink extends ApolloLink {
  client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  request(operation: Operation) {
    return new Observable<FetchResult>((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        }
      );
    });
  }
}

export default SSELink;
