const LIST_BEGIN_REGEX = /(^[-*+] )|(^[1-9]\d*\. )/i;
const LIST_END_REGEX = /^[^-*+1-9]/i;

const TABLE_BEGIN_REGEX = /^\| *.+ *\|( *.+ *\|)*$/im;
const TABLE_END_REGEX = /^[^|]+/im;

const CODE_BEGIN_REGEX = /^(```|`)([a-z]+)$/im;
const CODE_END_REGEX = /^(```|`)\n$/im;

const MATH_BEGIN_REGEX = /^\\\[/;
const MATH_END_REGEX = /^\\\]$/;

const LINK_START_REGEX = /\[(.+?)\]/;
const LINK_END_REGEX = /\[(.+?)\]\((.+?)\)/;

export class MarkdownBlock {
  public value: string;

  private isTable: boolean;

  private isCodeBlock: boolean;

  private isList: boolean;

  private isMathBlock: boolean;

  private isLink: boolean;

  constructor() {
    this.value = '';
    this.isTable = false;
    this.isCodeBlock = false;
    this.isList = false;
    this.isMathBlock = false;
    this.isLink = false;
  }

  public updateValue = (value: string) => {
    if (!value) {
      return;
    }

    this.value += value;

    if (this.isTable && this.value.match(TABLE_END_REGEX)) {
      this.isTable = false;
    } else if (!this.isTable && this.value.match(TABLE_BEGIN_REGEX)) {
      this.isTable = true;
    } else if (this.isCodeBlock && this.value.match(CODE_END_REGEX)) {
      this.isCodeBlock = false;
    } else if (!this.isCodeBlock && this.value.match(CODE_BEGIN_REGEX)) {
      this.isCodeBlock = true;
    } else if (this.isList && this.value.match(LIST_END_REGEX)) {
      this.isList = false;
    } else if (!this.isList && this.value.match(LIST_BEGIN_REGEX)) {
      this.isList = true;
    } else if (this.isMathBlock && this.value.match(MATH_END_REGEX)) {
      this.isMathBlock = false;
    } else if (!this.isMathBlock && this.value.match(MATH_BEGIN_REGEX)) {
      this.isMathBlock = true;
    } else if (this.isLink && this.value.match(LINK_END_REGEX)) {
      this.isLink = false;
    } else if (!this.isLink && this.value.match(LINK_START_REGEX)) {
      this.isLink = true;
    }
  };

  public insertSoftBreak = () => {
    if (!this.value.endsWith('\n')) {
      this.updateValue('\n');
    }
  };

  public get isMultiLine() {
    return this.isTable || this.isCodeBlock || this.isMathBlock;
  }

  public get hasMarkdown() {
    return this.isList || this.isCodeBlock || this.isTable || this.isMathBlock || this.isLink;
  }
}
