/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useContext, useState } from 'react';

import { useReadOnly, useSelected } from 'slate-react';
import type { RenderElementProps } from 'slate-react';

import ImageMenu from 'bundles/cml/editor/components/elements/image/ImageMenu';
import { AssetContext } from 'bundles/cml/editor/context/assetContext';
import { FocusContext } from 'bundles/cml/editor/context/focusContext';
import ImageRenderer from 'bundles/cml/shared/components/image/Image';
import FloatingMenu from 'bundles/cml/shared/components/menu/FloatingMenu';
import { useAsset } from 'bundles/cml/shared/hooks/useAsset';
import type { ImageElement } from 'bundles/cml/shared/types/elementTypes';

const styles = {
  hover: css`
    &:hover {
      outline: 3px solid var(--cds-color-interactive-primary);
      cursor: pointer;
    }
  `,
  selected: css`
    cursor: pointer;
    outline: 3px solid var(--cds-color-callouts-tertiary);
  `,
};

const Image: React.FC<RenderElementProps> = (props) => {
  const { element, children } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const { assetManager, pageless } = useContext(AssetContext);
  const { focused, setFocused } = useContext(FocusContext);

  const selected = useSelected();
  const readonly = useReadOnly();

  const image = element as ImageElement;
  const { id = '' } = image;

  const [ref, setRef] = useState<HTMLImageElement | null>(null);

  const handleOpen = useCallback(() => {
    if (!readonly) {
      setFocused(true);
      setMenuOpen(true);
    }
  }, [setFocused, readonly]);

  const handleClose = useCallback(() => {
    setMenuOpen(false);
    setFocused(false);
  }, [setFocused]);

  const { asset, assetData } = useAsset(id, assetManager);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (readonly) {
        return;
      }

      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        handleOpen();
      }
    },
    [readonly, handleOpen]
  );

  return (
    <ImageRenderer
      ref={setRef}
      {...props}
      assetData={assetData}
      css={readonly ? [] : [selected && focused ? styles.selected : styles.hover]}
      data-testid="image-container"
      readonly={readonly}
      onClick={handleOpen}
      onKeyDown={handleKeyDown}
    >
      {children}
      {menuOpen && asset && (
        <FloatingMenu anchorEl={ref} onClose={handleClose} pageless={pageless}>
          <ImageMenu anchorEl={ref} image={image} asset={asset} onClose={handleClose} />
        </FloatingMenu>
      )}
    </ImageRenderer>
  );
};

export default Image;
