import * as React from 'react';
import { useCallback, useState } from 'react';

import { useFocusRing } from '@react-aria/focus';
import { Editor, Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor, useSlate, useSlateStatic } from 'slate-react';

import { AIGenerateIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import AIButtonMenu from 'bundles/cml/editor/components/buttons/ai/AIButtonMenu';
import DropdownIcon from 'bundles/cml/editor/components/icons/DropdownIcon';
import { useFocusedContext } from 'bundles/cml/editor/context/focusContext';
import { useStyleContext } from 'bundles/cml/editor/context/styleContext';
import { hasAIElement, isAIButtonDisabled } from 'bundles/cml/editor/utils/writingAssistantUtils';
import { type AI_TOOLS, BLOCK_TYPES } from 'bundles/cml/shared/constants';

import _t from 'i18n!nls/cml';

const DEFAULT_TEMPERATURE = 0.5;

const AIButton: React.FC = () => {
  const editor = useSlate();
  const staticEditor = useSlateStatic();
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const { setFocused } = useFocusedContext();

  const handleOpen = useCallback(
    (menuOpen: boolean) => {
      if (menuOpen) {
        setFocused(true);
        setOpen(true);
      } else {
        setOpen(false);
        setTimeout(() => {
          setFocused(false);
          ReactEditor.focus(staticEditor);
        }, 250);
      }
    },
    [setFocused, staticEditor]
  );

  const { pageless } = useStyleContext();

  const handleChange = useCallback(
    ({ aiTool, prompt }: { aiTool: AI_TOOLS; prompt?: string }) => {
      setOpen(false);
      setFocused(false);

      HistoryEditor.withoutSaving(staticEditor, () => {
        Transforms.wrapNodes(
          staticEditor,
          {
            type: BLOCK_TYPES.AI_ELEMENT,
            aiTool,
            prompt,
            generating: true,
            temperature: DEFAULT_TEMPERATURE,
            anchorEl: pageless ? null : ref,
            children: [],
          },
          { mode: 'highest' }
        );

        Editor.addMark(staticEditor, 'selected', true);
        Transforms.collapse(staticEditor, { edge: 'end' });
        Editor.removeMark(staticEditor, 'selected');
      });
    },
    [staticEditor, pageless, ref, setFocused]
  );

  const active = hasAIElement(editor);
  const disabled = isAIButtonDisabled(editor);

  const {
    isFocusVisible,
    focusProps: { onClick, ...focusProps },
  } = useFocusRing();

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.(e);
      handleOpen(!open);
    },
    [open, onClick, handleOpen]
  );

  return (
    <div ref={setRef}>
      <Button
        onClick={handleClick}
        active={open || active}
        title={_t('AI Tools')}
        disabled={disabled || active}
        type={TOOLBAR_BUTTON_TYPES.menu}
        data-pendo="ai-writing-assistant"
        {...focusProps}
      >
        <AIGenerateIcon />
        <DropdownIcon />
      </Button>
      {open && (
        <AIButtonMenu
          anchorEl={ref || undefined}
          open={open}
          isFocusVisible={isFocusVisible}
          onClose={() => handleOpen(false)}
          onChange={handleChange}
        />
      )}
    </div>
  );
};

export default AIButton;
