import { Editor, Element, Path, Text, Transforms } from 'slate';

import { BLOCK_TYPES, type MARK_VALUES } from 'bundles/cml/shared/constants';

const CommandTypes = {
  TOKEN: 'TOKEN',
  NEWLINE: 'NEWLINE',
  NORMALIZE: 'NORMALIZE',
  ON_COMPLETE: 'ON_COMPLETE',
  CURSOR: 'CURSOR',
  CANCEL: 'CANCEL',
} as const;

export type CommandType = keyof typeof CommandTypes;

export type Command = {
  type: CommandType;
};

export interface TokenCommand extends Command {
  type: 'TOKEN';
  token: string;
}

export interface NewlineCommand extends Command {
  type: 'NEWLINE';
}

export interface NormalizeCommand extends Command {
  type: 'NORMALIZE';
  normalize: (editor: Editor) => void;
}

export interface CompleteCommand extends Command {
  type: 'ON_COMPLETE';
  callback: () => void;
}

export interface CursorCommand extends Command {
  type: 'CURSOR';
}

export interface CancelCommand extends Command {
  type: 'CANCEL';
}

const isCursorCommand = (command: Command): command is CursorCommand => command.type === 'CURSOR';
const isTokenCommand = (command: Command): command is TokenCommand => command.type === 'TOKEN';
const isNewlineCommand = (command: Command): command is NewlineCommand => command.type === 'NEWLINE';
const isNormalizeCommand = (command: Command): command is NormalizeCommand => command.type === 'NORMALIZE';
const isCompleteCommand = (command: Command): command is CompleteCommand => command.type === 'ON_COMPLETE';
const isCancelCommand = (command: Command): command is CancelCommand => command.type === 'CANCEL';

export const enableNormalization = (editor: Editor) => {
  if (!editor.isNormalizing()) {
    Editor.setNormalizing(editor, true);
    Editor.normalize(editor);
  }
};

export const disableNormalization = (editor: Editor) => {
  if (editor.isNormalizing()) {
    Editor.setNormalizing(editor, false);
  }
};

const getEndPoint = (editor: Editor) => {
  const point = Editor.end(editor, [editor.children.length - 1]);
  const path = Path.previous(Path.previous(point.path));
  return Editor.end(editor, path);
};

const removeCursor = (editor: Editor) => {
  Transforms.removeNodes(editor, {
    at: [editor.children.length - 1],
    match: (node) => Element.isElement(node) && node.type === BLOCK_TYPES.AI_CURSOR,
  });
};

const newLineCommand = (editor: Editor, command: NewlineCommand) => {
  Editor.withoutNormalizing(editor, () => {
    removeCursor(editor);
    Transforms.insertNodes(
      editor,
      {
        type: BLOCK_TYPES.TEXT,
        children: [
          {
            type: BLOCK_TYPES.AI_CURSOR,
            isVoid: true,
            isInline: true,
            children: [{ text: '' }],
          },
        ],
      },
      { at: [editor.children.length] }
    );
  });

  enableNormalization(editor);
};

const getMarks = (editor: Editor) => {
  const point = getEndPoint(editor);
  const textNodes = Array.from(Editor.nodes(editor, { at: point, match: Text.isText }));
  const lastNode = textNodes[textNodes.length - 1];

  if (!lastNode) {
    return [];
  }

  const [text] = lastNode;
  return Object.keys(text).filter((key) => key !== 'text') as MARK_VALUES[];
};

const tokenCommand = (editor: Editor, { token }: TokenCommand) => {
  const currentMarks = getMarks(editor);
  const point = getEndPoint(editor);

  Transforms.insertText(editor, token, { at: getEndPoint(editor) });

  if (currentMarks.length) {
    Transforms.unsetNodes(editor, currentMarks, {
      at: { anchor: point, focus: { path: point.path, offset: point.offset + token.length } },
      match: Text.isText,
      split: true,
      mode: 'lowest',
    });
  }
};

const normalizeCommand = (editor: Editor, { normalize }: NormalizeCommand) => {
  normalize(editor);
};

const completeCommand = (editor: Editor, { callback }: CompleteCommand) => {
  removeCursor(editor);
  enableNormalization(editor);
  callback();
};

const cursorCommand = (editor: Editor, command: CursorCommand) => {
  Transforms.insertNodes(
    editor,
    {
      type: BLOCK_TYPES.AI_CURSOR,
      isVoid: true,
      isInline: true,
      children: [{ text: '' }],
    },
    { at: Editor.end(editor, [0]), mode: 'lowest' }
  );
};

const cancelCommand = (editor: Editor, command: CancelCommand) => {
  removeCursor(editor);
  enableNormalization(editor);
};

export const executeCommand = (editor: Editor, command: Command) => {
  if (isNewlineCommand(command)) {
    newLineCommand(editor, command);
  } else if (isTokenCommand(command)) {
    tokenCommand(editor, command);
  } else if (isNormalizeCommand(command)) {
    normalizeCommand(editor, command);
  } else if (isCompleteCommand(command)) {
    completeCommand(editor, command);
  } else if (isCursorCommand(command)) {
    cursorCommand(editor, command);
  } else if (isCancelCommand(command)) {
    cancelCommand(editor, command);
  }
};
