import * as React from 'react';

import { union } from 'lodash';
import Q from 'q';
import { compose, setDisplayName } from 'recompose';

import type { InjectedRouter } from 'js/lib/connectToRouter';
import redirect from 'js/lib/coursera.redirect';
import user from 'js/lib/user';

import withApiHandler from 'bundles/coursera-ui/components/hocs/withApiHandler';
import type { PropsFromWithApiHandler } from 'bundles/coursera-ui/components/hocs/withApiHandler';
import { ApiStates } from 'bundles/coursera-ui/constants/apiNotificationConstants';
import type { ApiState as ApiStatus } from 'bundles/coursera-ui/constants/apiNotificationConstants';
import type { InjectedNaptime, NaptimeError } from 'bundles/naptimejs';
import Naptime from 'bundles/naptimejs';
import type EnterpriseProgramsV1 from 'bundles/naptimejs/resources/enterprisePrograms.v1';
import type ProgramMembershipsV2Type from 'bundles/naptimejs/resources/programMemberships.v2';
import type ThirdPartyOrganizationsV1Type from 'bundles/naptimejs/resources/thirdPartyOrganizations.v1';
import Modal from 'bundles/phoenix/components/Modal';
import { PRODUCT_TYPES } from 'bundles/program-common/constants/ProgramActionConstants';
import ProgramActionApiManager from 'bundles/program-common/models/ProgramActionApiManager';
import type { ProductCardCourseFragmentFragment as Course } from 'bundles/program-common/queries/__generated__/ProductCoursesQuery';
import storageUtils from 'bundles/program-common/utils/storageUtils';
import ProgramAdminDenyModal from 'bundles/program-home/components/modals/ProgramAdminDenyModal';
import ProgramDenyModal from 'bundles/program-home/components/modals/ProgramDenyModal';
import ProgramEmailVerifyModal from 'bundles/program-home/components/modals/ProgramEmailVerifyModal';
import ProgramJoinModal from 'bundles/program-home/components/modals/ProgramJoinModal';
import {
  AUTOENROLLED_COURSES_COOKIE_KEY,
  AUTOENROLLED_COURSES_COOKIE_PATH,
} from 'bundles/program-home/components/modals/constants';
import { API_TAGS } from 'bundles/program-home/constants/ProgramHomeConstants';
import type ProgramLearnerApiManager from 'bundles/program-home/utils/ProgramLearnerApiManager';
import { FROM_FORCED_SSO_FLAG, FROM_FORCED_SSO_KEY } from 'bundles/saml-sso/constants';
import useHasThirdPartyOrgEnforcedSSOLogin from 'bundles/saml-sso/shared/hooks/useHasThirdPartyOrgEnforcedSSOLogin';

import 'css!./__styles__/ProgramAccessModal';

type PropsFromCaller = {
  apiManager: ProgramLearnerApiManager;
  isAccessModalDismissed: boolean;
  isProgramAdmin: boolean;
  onCloseAccessModal: () => void;
  program: EnterpriseProgramsV1;
  programMembership: ProgramMembershipsV2Type;
  reloadProgramMembership: () => void;
  router: InjectedRouter;
  thirdPartyOrganization: ThirdPartyOrganizationsV1Type;
  userId: number;
  isUpswell: boolean;
  isNonBlacklistedC4cv: boolean;
  shouldUseJoinTrackingVerbiage: boolean;
  isGwGProgram?: boolean;
  programRecommendationsAvailableCoursesCopy: boolean;
  autoEnrolledCoursesWithData?: Course[];
};

type PropsFromNaptime = {
  naptime: InjectedNaptime;
};

type Props = PropsFromCaller & PropsFromWithApiHandler & PropsFromNaptime;

type PropsForApi = { apiStatus: ApiStatus; error?: NaptimeError | null } | undefined;

const CONFIG = {
  ProgramDenyModal: {
    'data-e2e': 'ProgramDenyModal',
    modalName: 'ProgramDenyModal',
    trackingName: 'program_deny_modal',
  },
  ProgramEmailVerifyModal: {
    'data-e2e': 'ProgramEmailVerifyModal',
    modalName: 'ProgramEmailVerifyModal',
    trackingName: 'program_email_verify_modal',
  },
  ProgramJoinModal: {
    'data-e2e': 'ProgramJoinModal',
    modalName: 'ProgramJoinModal',
    trackingName: 'program_join_modal',
  },
};

export function ProgramAccessModal({
  router,
  onCloseAccessModal,
  isAccessModalDismissed,
  apiTag,
  apiStatus,
  error,
  programMembership,
  shouldUseJoinTrackingVerbiage,
  userId,
  isGwGProgram,
  programRecommendationsAvailableCoursesCopy,
  autoEnrolledCoursesWithData,
  apiManager,
  handleApiPromise,
  reloadProgramMembership,
  isProgramAdmin,
  program,
  thirdPartyOrganization,
  naptime,
}: Props) {
  const shouldDenyAccess = programMembership.isNotProgramMember;
  const showProgramDenyModal = shouldDenyAccess && !isProgramAdmin;
  const showProgramAdminDenyModal = shouldDenyAccess && isProgramAdmin;

  const showEmailVerificationModal = !showProgramDenyModal && programMembership.isEmailNotVerified;
  const isAutoEnrolling = Boolean(router.location.query.deepLinkItemId);
  const [showProgramJoinModal, setShowProgramJoinModal] = React.useState<boolean>(false);
  const [autoAcceptProgramJoinModal, setAutoAcceptProgramJoinModal] = React.useState<boolean>(false);
  const { isSsoLoginForced, isLoading: isSsoFlagLoading } = useHasThirdPartyOrgEnforcedSSOLogin(
    thirdPartyOrganization.id,
    user.get().email_address
  );

  // Axa handles program join automatically when they submit the assessments
  const canJoinProgram =
    !showProgramDenyModal &&
    !showEmailVerificationModal &&
    (programMembership.isInvited || programMembership.isWhitelisted || programMembership.isSoftDeletedMember);

  const { hasModalToDisplay } = programMembership;
  const programId = program.id;
  const programSlug = program.slug;
  const programName = program.name;
  const orgName = thirdPartyOrganization?.name ?? 'company';
  const orgSlug = thirdPartyOrganization?.slug;
  const thirdPartyOrganizationId = thirdPartyOrganization.id;

  let emailVerifyProps: PropsForApi;
  let invitationJoinProps: PropsForApi = { apiStatus: ApiStates.API_BEFORE_SEND };

  if (apiTag === API_TAGS.RESEND_EMAIL) {
    emailVerifyProps = { apiStatus, error };
  } else if (apiTag === API_TAGS.JOIN_PROGRAM) {
    invitationJoinProps = { apiStatus, error };
  }
  React.useEffect(() => {
    // Since we are reading localstorage which is not available in SSR, we need to add this logic in useEffect hook
    if (!isSsoFlagLoading) {
      const isAutoJoiningProgram = storageUtils.get(userId, FROM_FORCED_SSO_FLAG, FROM_FORCED_SSO_KEY, false);
      const comingFromForceSsoLogin = isSsoLoginForced && isAutoJoiningProgram;
      const shouldAutoAcceptProgramJoinModal = canJoinProgram && comingFromForceSsoLogin && !isAutoEnrolling;
      const shouldShowProgramJoinModal = canJoinProgram && (!comingFromForceSsoLogin || isAutoEnrolling || !!error);

      setShowProgramJoinModal(shouldShowProgramJoinModal);
      setAutoAcceptProgramJoinModal(shouldAutoAcceptProgramJoinModal);
    }
  }, [isSsoFlagLoading, isSsoLoginForced, canJoinProgram, isAutoEnrolling, userId, error]);

  React.useEffect(() => {
    if (isAccessModalDismissed || !hasModalToDisplay) {
      onCloseAccessModal();
    }
  }, [isAccessModalDismissed, hasModalToDisplay, onCloseAccessModal]);

  let modalName: keyof typeof CONFIG = 'ProgramDenyModal';

  // Disable the Styleguide stuff when we're rendering a CDS modal.
  let wrapperClassName: string | undefined = 'vertical-box align-items-absolute-center text-xs-center';
  if (showEmailVerificationModal) {
    modalName = 'ProgramEmailVerifyModal';
    wrapperClassName = undefined;
  } else if (showProgramJoinModal) {
    modalName = 'ProgramJoinModal';
  }

  const onJoinProgram = React.useCallback(() => {
    if (userId) {
      if (autoEnrolledCoursesWithData && autoEnrolledCoursesWithData.length > 0) {
        const currentAutoenrolledCoursesCookieValue = storageUtils.get(
          userId,
          AUTOENROLLED_COURSES_COOKIE_PATH,
          AUTOENROLLED_COURSES_COOKIE_KEY,
          []
        );

        storageUtils.set(
          userId,
          AUTOENROLLED_COURSES_COOKIE_PATH,
          AUTOENROLLED_COURSES_COOKIE_KEY,
          union(
            autoEnrolledCoursesWithData.map((course) => course.id),
            currentAutoenrolledCoursesCookieValue
          )
        );
      }

      const joinPromise = apiManager.getJoinProgramPromise({ programId, userId });
      const { productId, productType, eoc, redirectPath } = router.location.query;

      const refreshAfterMutation = () => {
        // We cannot correctly invalidate all of the cache locations, so just reload the page as a workaround
        // until we sort out our data fetching throughput the bundle.
        window.location.reload();
      };

      const redirectAfterMutation = () => {
        // TODO: Change to react router method once Clips redesign is generally availaable
        // Right now, learners might be redirected from the old Clips version in video-logged-out-page which is not using the
        // same react router instance as the new Clips redesign in program-home
        redirect.setLocation(decodeURIComponent(redirectPath));
      };

      let onApiSuccess = () => {
        storageUtils.remove(userId, FROM_FORCED_SSO_FLAG, FROM_FORCED_SSO_KEY);
        return redirectPath ? redirectAfterMutation() : refreshAfterMutation();
      };

      const hasNavFromEOCModal = Boolean(productId && productType && eoc);
      if (hasNavFromEOCModal) {
        // If we're joining via an EOC redirect, then also follow it up with an enrollment before opening the MiniXDP.
        onApiSuccess = () => {
          const api = new ProgramActionApiManager({ programId, userId, naptime });
          const enrollPromise =
            productType === PRODUCT_TYPES.SPECIALIZATION
              ? api.getEnrollInS12nPromise({ firstCourseId: null, s12nId: productId, collectionId: null })
              : api.getEnrollInCoursePromise({ courseId: productId, collectionId: null });
          handleApiPromise({
            apiPromise: enrollPromise,
            // This is used to pick which component to pipe props to, which is still the join modal.
            apiTag: API_TAGS.JOIN_PROGRAM,
            apiSuccessCallback: refreshAfterMutation,
          });
        };
      }

      handleApiPromise({
        apiPromise: joinPromise,
        apiTag: API_TAGS.JOIN_PROGRAM,
        apiSuccessCallback: onApiSuccess,
      });
    }
  }, [userId, autoEnrolledCoursesWithData, programId, handleApiPromise, apiManager, naptime, router.location.query]);

  React.useEffect(() => {
    if (autoAcceptProgramJoinModal && apiStatus === ApiStates.API_BEFORE_SEND) {
      onJoinProgram();
    }
  }, [autoAcceptProgramJoinModal, apiStatus, onJoinProgram]);

  const onResendEmail = () => {
    if (programMembership) {
      const redirectUrl = window.location.pathname;
      const apiPromise = apiManager.getResendEmailPromise({ programMembershipId: programMembership.id, redirectUrl });
      handleApiPromise({
        apiPromise,
        apiTag: API_TAGS.RESEND_EMAIL,
      });
    }
  };
  const onCheckVerified = () => {
    const apiPromise = Q.all([reloadProgramMembership(), apiManager.getRefreshDataPromise(['programMemberships.v2'])]);
    handleApiPromise({
      apiPromise,
      apiTag: API_TAGS.RESEND_EMAIL,
    });
  };
  const onDenyModalBrowseClick = (apiSuccessCallback: $TSFixMe) => {
    if (userId) {
      const apiPromise = apiManager.setSwitcher({
        userId,
        selectionType: 'COURSERA',
      });
      handleApiPromise({ apiPromise, apiSuccessCallback, apiErrorCallback: apiSuccessCallback });
      // we still want to redirect them back even though it fails
    }
  };

  const shouldHideModals =
    (!showProgramDenyModal && !showProgramAdminDenyModal && !showEmailVerificationModal && !showProgramJoinModal) ||
    isAccessModalDismissed;

  if (shouldHideModals) {
    return null;
  }

  return (
    <div className="rc-ProgramAccessModal">
      <Modal {...CONFIG[modalName]} data={{ programId, programName }} handleClose={onCloseAccessModal}>
        <div className={wrapperClassName}>
          {showProgramDenyModal && (
            <ProgramDenyModal
              programId={programId}
              programName={programName}
              thirdPartyOrganizationId={thirdPartyOrganizationId}
              onBrowse={onDenyModalBrowseClick}
            />
          )}
          {showProgramAdminDenyModal && (
            <ProgramAdminDenyModal
              programSlug={programSlug}
              orgName={orgName}
              orgSlug={orgSlug}
              onClose={onCloseAccessModal}
            />
          )}
          {showEmailVerificationModal && (
            <ProgramEmailVerifyModal
              programName={programName}
              onClose={onCloseAccessModal}
              onResendEmail={onResendEmail}
              onCheckVerified={onCheckVerified}
              programMembership={programMembership}
              {...emailVerifyProps}
            />
          )}
          {showProgramJoinModal && (
            <ProgramJoinModal
              programId={programId}
              programName={programName}
              programMembership={programMembership}
              thirdPartyOrganizationId={thirdPartyOrganizationId}
              orgName={orgName}
              onJoinProgram={onJoinProgram}
              shouldUseJoinTrackingVerbiage={shouldUseJoinTrackingVerbiage}
              router={router}
              isGwGProgram={isGwGProgram}
              programRecommendationsAvailableCoursesCopy={programRecommendationsAvailableCoursesCopy}
              autoEnrolledCoursesWithData={autoEnrolledCoursesWithData}
              userId={userId}
              isAutoEnrolling={isAutoEnrolling}
              {...invitationJoinProps}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}

export default compose<Props, PropsFromCaller>(
  setDisplayName('ProgramAccessModalHOC'),
  withApiHandler({}),
  Naptime.createContainer(() => ({})) // Inject a naptime instance for onJoinProgram.
)(ProgramAccessModal);
