import { escape, unescape } from 'lodash';

import { getCmlAttribute } from 'bundles/cml/editor/utils/cmlUtils';
import { textToCML } from 'bundles/cml/editor/utils/textToCML';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type {
  AIElement,
  AssetElement,
  BlockElement,
  CodeElement,
  HeadingElement,
  ImageElement,
  InlineElement,
  LegacyAudioElement,
  ListElement,
  ListItemElement,
  MathBlockElement,
  TableCellElement,
  TableElement,
  TableRowElement,
  TextElement,
  WidgetElement,
} from 'bundles/cml/shared/types/elementTypes';

const textToCml = (node: BlockElement) => {
  const { text, hasMath } = textToCML(node as TextElement);
  const { variant } = node as TextElement;
  const cmlAttributes = [
    ...getCmlAttribute(['hasMath', 'true'], hasMath),
    ...getCmlAttribute(['variant', variant], !!variant),
  ].join(' ');

  return [`<text${cmlAttributes.trim().length > 0 ? ` ${cmlAttributes}` : ''}>${text}</text>`];
};

const headingToCml = (node: BlockElement) => {
  const { text, hasMath } = textToCML(node as HeadingElement);
  const { level, variant } = node as HeadingElement;

  const cmlAttributes = [
    ...getCmlAttribute(['level', level]),
    ...getCmlAttribute(['hasMath', 'true'], hasMath),
    ...getCmlAttribute(['variant', variant], !!variant),
  ].join(' ');

  return [`<heading ${cmlAttributes}>${text}</heading>`];
};

const mathBlockToCml = (node: BlockElement) => {
  const { formula } = node as MathBlockElement;
  return [`<text${formula ? ' hasMath="true"' : ''}>${escape(formula)}</text>`];
};

const listToCml = (node: BlockElement) => {
  const listItems = (node as ListElement).children.flatMap((listItem: BlockElement) => {
    if (listItem.type !== BLOCK_TYPES.LIST_ITEM) {
      return [];
    }

    const {
      'aria-level': ariaLevel,
      'data-aria-level': dataAriaLevel,
      'aria-posinset': ariaPosinset,
      'data-aria-posinset': dataAriaPosinset,
    } = listItem as ListItemElement;

    const listLevel = ariaLevel || dataAriaLevel || '';
    const listPosinset = ariaPosinset || dataAriaPosinset || '';

    const cmlAttributes = [
      ...getCmlAttribute(['aria-level', listLevel], !!listLevel),
      ...getCmlAttribute(['aria-posinset', listPosinset], !!listPosinset),
    ].join(' ');

    const children: string[] = (listItem as ListItemElement).children.flatMap(
      (childNode: TextElement | ListElement | MathBlockElement) => {
        if (childNode.type === BLOCK_TYPES.TEXT) {
          return textToCml(childNode);
        }

        if (childNode.type === BLOCK_TYPES.BULLET_LIST || childNode.type === BLOCK_TYPES.NUMBER_LIST) {
          return listToCml(childNode);
        }

        if (childNode.type === BLOCK_TYPES.MATH_BLOCK) {
          return mathBlockToCml(childNode);
        }

        return [];
      }
    );

    if (!children) {
      return [];
    }

    return [`<li${cmlAttributes.trim().length > 0 ? ` ${cmlAttributes}` : ''}>${children.join('')}</li>`];
  });

  if (!listItems) {
    return [];
  }

  const listType = node.type === BLOCK_TYPES.NUMBER_LIST ? 'numbers' : 'bullets';
  return [`<list bulletType="${listType}">${listItems.join('')}</list>`];
};

const imageToCml = (node: BlockElement) => {
  const { id, src, size } = node as ImageElement;
  const cmlAttributes = [
    ...getCmlAttribute(['assetId', id], !!id),
    ...getCmlAttribute(['size', size], !!size && size !== 'default'),
    ...getCmlAttribute(['src', src], !id && !!src), // spark images use `src` and don't have `assetId`
  ].join(' ');

  return [`<img ${cmlAttributes}/>`];
};

const legacyAudioToCml = (node: BlockElement) => {
  const { id, name, src } = node as LegacyAudioElement;
  const cmlAttributes = [
    ...getCmlAttribute(['assetId', id], !!id),
    ...getCmlAttribute(['caption', name], !!name),
    ...getCmlAttribute(['src', src], !!src),
  ].join(' ');

  return [`<audio ${cmlAttributes} />`];
};

const assetToCml = (node: BlockElement) => {
  const { id, name, extension, assetType, embedEnabled, embedStartPage, embedEndPage } = node as AssetElement;
  const escapedName = escape(unescape(name)); // unescape first so we don't doubly escape

  const embedStartAttr = embedStartPage ? ` embedStartPage="${embedStartPage}"` : '';
  const embedEndAttr = embedEndPage ? ` embedEndPage="${embedEndPage}"` : '';
  const embedEnabledAttr = embedEnabled ? ` embedEnabled="${embedEnabled}"` : '';
  return [
    `<asset id="${id}" name="${escapedName}" extension="${extension}" assetType="${assetType}"${embedEnabledAttr}${embedStartAttr}${embedEndAttr}/>`,
  ];
};

const tableCellToCml = (node: BlockElement) => {
  const tableCell = node as TableCellElement;
  const tag = tableCell.header ? 'th' : 'td';
  const text = tableCell.children
    .flatMap((childNode: TextElement | ListElement | MathBlockElement) => {
      if (childNode.type === BLOCK_TYPES.TEXT) {
        return textToCml(childNode);
      }

      if (childNode.type === BLOCK_TYPES.BULLET_LIST || childNode.type === BLOCK_TYPES.NUMBER_LIST) {
        return listToCml(childNode);
      }

      if (childNode.type === BLOCK_TYPES.MATH_BLOCK) {
        return mathBlockToCml(childNode);
      }

      return '';
    })
    .join('');

  return [`<${tag}>${text}</${tag}>`];
};

const tableRowToCml = (node: BlockElement) => {
  const tableCells = (node as TableRowElement).children
    .filter((childNode) => childNode.type === BLOCK_TYPES.TABLE_CELL)
    .map(tableCellToCml)
    .join('');
  return [`<tr>${tableCells}</tr>`];
};

const tableToCml = (node: BlockElement) => {
  const table = node as TableElement;
  const hasHeader = !table.headless;
  let numRows = 0;
  let numCols = 0;

  const tableRows = table.children
    .filter((childNode: TableRowElement) => childNode.type === BLOCK_TYPES.TABLE_ROW)
    .map((childNode, idx) => {
      const tableRowElement = childNode as TableRowElement;
      if (hasHeader && idx === 0) {
        numRows += 1;
        return `<thead>${tableRowToCml(tableRowElement)}</thead>`;
      }

      numRows += 1;
      numCols = tableRowElement.children.length;
      return tableRowToCml(tableRowElement);
    });

  // Copy-pasting tables from currently unsupported sources like Excel sheets can lead
  // to tables with invalid row/col count. e.g. https://sentry.io/organizations/coursera/issues/870202638
  if (numRows > 0 && numCols > 0) {
    const cmlAttributes = [
      ...getCmlAttribute(['rows', `${numRows}`]),
      ...getCmlAttribute(['columns', `${numCols}`]),
    ].join(' ');
    return [`<table ${cmlAttributes}>${tableRows.join('')}</table>`];
  }

  return [];
};

const codeToCml = (node: BlockElement) => {
  const { language, evaluatorId, codeText, name } = node as CodeElement;
  const escapedCodeText = escape(codeText);
  const escapedName = escape(name);

  const cmlAttributes = [
    ...getCmlAttribute(['language', language], !!language),
    ...getCmlAttribute(['evaluatorId', evaluatorId], !!evaluatorId),
    ...getCmlAttribute(['name', escapedName], !!name),
  ].join(' ');

  return [`<code ${cmlAttributes}>${escapedCodeText}</code>`];
};

const widgetToCml = (node: BlockElement) => {
  const { id } = node as WidgetElement;
  const cmlAttributes = [...getCmlAttribute(['id', id], !!id)].join(' ');

  return [`<widget ${cmlAttributes}/>`];
};

const BLOCK_TRANSFORMERS: Record<string, (node: BlockElement) => string[]> = {
  [BLOCK_TYPES.HEADING]: headingToCml,
  [BLOCK_TYPES.BULLET_LIST]: listToCml,
  [BLOCK_TYPES.NUMBER_LIST]: listToCml,
  [BLOCK_TYPES.IMAGE]: imageToCml,
  [BLOCK_TYPES.LEGACY_AUDIO]: legacyAudioToCml,
  [BLOCK_TYPES.ASSET]: assetToCml,
  [BLOCK_TYPES.TABLE]: tableToCml,
  [BLOCK_TYPES.CODE]: codeToCml,
  [BLOCK_TYPES.MATH_BLOCK]: mathBlockToCml,
  [BLOCK_TYPES.WIDGET]: widgetToCml,
  [BLOCK_TYPES.TEXT]: textToCml,
};

const aiSuggestionToCml = (node: BlockElement) => {
  return (node as AIElement).children.flatMap((childNode: BlockElement) => {
    const transformer = BLOCK_TRANSFORMERS[childNode.type] ?? BLOCK_TRANSFORMERS[BLOCK_TYPES.TEXT];
    return transformer(childNode);
  });
};

const TRANSFORMERS: Record<string, (node: BlockElement) => string[]> = {
  ...BLOCK_TRANSFORMERS,
  [BLOCK_TYPES.AI_ELEMENT]: aiSuggestionToCml,
};

// recursively convert a list of slate Nodes to cml string content (i.e. content string inside `<co-content> ... </co-content>`)
export const slateToCml = (nodes?: (BlockElement | InlineElement)[]) => {
  if (!nodes || nodes.length === 0) {
    return '';
  }

  const cml = nodes.map((node) => {
    const transformer = TRANSFORMERS[node.type] ?? TRANSFORMERS[BLOCK_TYPES.TEXT];
    return transformer(node as BlockElement);
  });

  return cml.join('');
};
