/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { useRetracked } from 'js/lib/retracked';

import { Grid } from '@coursera/cds-core';

import { CoachBrandLabel } from 'bundles/ai-coach-platform/components/branding/CoachBranding';
import { CoachIconButton } from 'bundles/ai-coach-platform/components/building-blocks';
import { CloseIcon, NewChatIcon, SettingsIcon } from 'bundles/ai-coach-platform/components/icons/mui';

import _t from 'i18n!nls/ai-coach-platform';

type Props = {
  onClose: () => void;
  showClose: boolean;
  showStartNewChat?: boolean;
  showSettings?: boolean;
  handleNewSession?: () => void;
  handleSettingsClick?: () => void;
  loading?: boolean;
};

/**
 * Default header component for Coach chat pattern
 */
const ChatHeader = (props: Props) => {
  const {
    onClose,
    handleSettingsClick,
    showSettings,
    showClose,
    showStartNewChat = true,
    handleNewSession,
    loading = false,
  } = props;
  const track = useRetracked();

  return (
    <Grid
      container
      className="ai-coach-chat-header"
      justifyContent="space-between"
      css={css`
        padding: 0 var(--cds-spacing-200);
        height: 54px;
        align-items: center;
      `}
    >
      <Grid
        item
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <CoachBrandLabel />
      </Grid>
      <Grid
        item
        css={css`
          display: flex;
          gap: 4px;
        `}
      >
        {showStartNewChat && (
          <CoachIconButton
            size="small"
            aria-label={_t('Start a new chat. This will clear your chat history so you can start fresh.')}
            tooltip={_t('Start a new chat. This will clear your chat history so you can start fresh.')}
            tooltipPlacement="bottom"
            onClick={() => {
              track({
                trackingData: {
                  timestamp: Date.now(),
                },
                trackingName: 'ai_course_coach_chat_header_new_session_button',
                action: `click`,
              });
              handleNewSession?.();
            }}
            data-testid="coach-new-chat"
            icon={<NewChatIcon />}
            disabled={loading}
          />
        )}
        {showSettings && (
          <CoachIconButton
            size="small"
            icon={<SettingsIcon />}
            aria-label={_t('Coach settings')}
            tooltip={_t('Settings')}
            tooltipPlacement="bottom"
            onClick={handleSettingsClick}
            disabled={loading}
          />
        )}
        {showClose && (
          <CoachIconButton
            size="small"
            icon={<CloseIcon />}
            aria-label={_t('Close chat')}
            tooltip={_t('Close chat')}
            tooltipPlacement="bottom"
            onClick={() => {
              track({
                trackingData: {
                  timestamp: Date.now(),
                },
                trackingName: 'ai_course_coach_chat_header_close_button',
                action: `click`,
              });
              onClose();
            }}
            data-testid="coach-close"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ChatHeader;
